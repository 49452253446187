import React,{Fragment,useEffect,useState} from 'react';
import axios from 'axios'
import { Collapse } from 'reactstrap';
import { Card,CardHeader,CardBody,Input,Media,Button,Form,FormGroup} from 'reactstrap';
import {Attendance, SearchContacts, SearchPlayer} from '../../constant';
import ScrollArea from 'react-scrollbar';

import {ContactsThumbnails,EventCaster, PlayerThumbnail, SimpleUserThumbnail, UserThumbnail} from '../general/UserThumbnail'
import { LoadingAsset } from '../common/WindowLoaders';
import { SetEventCaster } from '../../redux/common/EventActions';
import { useSelector } from 'react-redux';


export const ChatList = (props) => {
    
    const height = props.height || "auto"
    const [users , setUsers] = useState(props.usersList)
    useEffect(()=>{
        setUsers(props.usersList)
    },[props.usersList])
    
    return (
        <Fragment>
            {props.searchBar == undefined || props.searchBar != false ?
                <Fragment>
                    <Form>
                        <FormGroup className="m-0">
                            <Input className="form-control-social" type="search" placeholder={`${SearchContacts}...`}/>
                        </FormGroup>
                    </Form> 
                    <br/>
                </Fragment>: ""}    
            <ScrollArea
                speed={0.5}
                horizontal={false}
                vertical={true}
                style={{height:height}}>
                {
                    users.map(user =>{
                        return( 
                            <div onClick={console.log("ContactsThumbnails")}>
                                <ContactsThumbnails userProfile={user}/>
                            </div>
                            )
                    })
                }
            </ScrollArea>
        </Fragment>
    )

}

export const ContactsList = (props) => {
    
    const height = props.height || "auto"
    const [users , setUsers] = useState(props.usersList)
    useEffect(()=>{
        setUsers(props.usersList)
    },[props.usersList])
    
    return (
        <Fragment>
            {props.searchBar == undefined || props.searchBar != false ?
                <Fragment>
                    <Form>
                        <FormGroup className="m-0">
                            <Input className="form-control-social" type="search" placeholder={`${SearchContacts}...`}/>
                        </FormGroup>
                    </Form> 
                    <br/>
                </Fragment>: ""}    
            <ScrollArea
                speed={0.5}
                horizontal={false}
                vertical={true}
                style={{height:height}}>
                {
                    users.map(user =>{
                        return( 
                            <div onClick={console.log("ContactsThumbnails")}>
                                <SimpleUserThumbnail userProfile={user}/>
                            </div>
                            )
                    })
                }
            </ScrollArea>
        </Fragment>
    )

}



export const UsersList = (props) => {
    
    const height = props.height || "auto"
    const [users , setUsers] = useState(props.usersList)
    useEffect(()=>{
        setUsers(props.usersList)
    },[props.usersList])
    
    return (
        <Fragment>
            {props.searchBar == undefined || props.searchBar != false ?
                <Fragment>
                    <br/>
                    <Form>
                        <FormGroup className="m-0">
                            <Input className="form-control-social" type="search" placeholder={`${SearchContacts}...`}/>
                        </FormGroup>
                    </Form> 
                    <br/>
                </Fragment>: ""}    
                <ScrollArea
                    speed={0.5}
                    horizontal={false}
                    vertical={true}
                    style={{height:height}}>
                    {
                        users.map(user =>{
                            return( 
                                <div onClick={console.log("ContactsThumbnails")}>
                                    <UserThumbnail userProfile={user} checkIfIsOpen={props.checkIfIsOpen}  chatBtn={true}  videoBtn={true} phoneBtn={true}/>
                                </div>
                                )
                        })
                    }
                </ScrollArea>
        </Fragment>
    )

}








export const TeamList = (props) => {
    
    const height = props.height || "auto"
    const [users , setUsers] = useState(props.usersList)
    useEffect(()=>{
        setUsers(props.usersList)
    },[props.usersList])

    return (
        <Fragment>
            {props.searchBar == undefined || props.searchBar != false ?
                <Fragment>
                    <Form>
                        <FormGroup className="m-0">
                            <Input className="form-control-social" type="search" placeholder={`${SearchPlayer}...`}/>
                        </FormGroup>
                    </Form> 
                    <br/>
                </Fragment>: ""}    
                <ScrollArea
                    speed={0.5}
                    horizontal={false}
                    vertical={true}
                    style={{height:height}}>
                    {
                        users.map(user =>{
                            return( 
                                <Fragment>
                                    <div onClick={()=>props.showPlayerData(user.id)}>
                                        <PlayerThumbnail user={user.userData}/>
                                    </div>
                                </Fragment>
                            )
                        })
                    }
                </ScrollArea>
        </Fragment>
    )
}







export const CastersList = (props) => {
    
    const height = props.height || "auto"
    
    const eventCasters = useSelector(state => state.EventCommon.settings.eventCasters.casters)
    const [isLoading, setIsLoading] = useState(true)
    const [casters , setCastersList] = useState([])

    const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp"

    useEffect(()=>{
        if(eventCasters.length > 0){
          setIsLoading(true)
          const params = {
            eventCasters: eventCasters
          }
      
          axios.get(`${ApiURL}/api/eventCasters/`, {
            params: params
          }).then((res) => {
            SetEventCastersData(res.data).then(()=>{
                setIsLoading(false)
            })
          }).catch(error => console.error(error))
        }
    },[eventCasters])
  
    const SetEventCastersData = (casters) =>{
      return new Promise ((resolve,reject) => {
        setCastersList(casters)
        resolve(true)
      })
    }

    if(isLoading){
        return( <LoadingAsset loading="UsersList.js (CastersList)"/> )
    }
    
    return (
        <Fragment>
            {props.searchBar == undefined || props.searchBar != false ?
                <Fragment>
                    <Form>
                        <FormGroup className="m-0">
                            <Input className="form-control-social" type="search" placeholder={`${SearchContacts}...`}/>
                        </FormGroup>
                    </Form> 
                    <br/>
                </Fragment>: ""}    
                <ScrollArea
                    speed={0.5}
                    horizontal={false}
                    vertical={true}
                    style={{height:height}}>
                        {
                           casters.map(caster=>{
                               return(
                                <EventCaster profile={caster}/>
                               )
                           })
                        }
                </ScrollArea>
        </Fragment>
    )
}

