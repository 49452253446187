import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Row, Col, Media, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import LabeledInput from "../../../../../components/general/LabeledInput";
import LabeledDropDown from "../../../../../components/general/LabeledDropDown";
import { GeneralInformation, PlatformName, PlatformCreatedSuccessfully } from "../../../../../constant";
import { CreateDocument } from "../../../../../redux/Administrator/Actions";
import axios from "axios";
import { useHistory } from "react-router";
import { toast } from 'react-toastify';

const PlatformData = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const createDocument = useSelector(
    (state) => state.AdminState.createDocument
  );
  const [platformName, setPlatformName] = useState("");

  useEffect(() => {
    if (props.platform != undefined) {
      const platformData = props.platform.platformData;
      setPlatformName(platformData.name);
    }
  }, [props.platform]);

  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (createDocument) {
      dispatch(CreateDocument(false));
      axios
        .post(`${ApiURL}/api/admin/controlPanel/platform`, {
          userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
          platform:{
            platformData:{
              name:platformName
            }
          },
        })
        .then((res) => {
          toast.success(PlatformCreatedSuccessfully, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          });
          setTimeout(
            history.push(`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${res.data}/Form`), 
            5000
          )
        })
        .catch((error) => {
          console.error(error)
          const errorDetails = error.response.data.error.details
          errorDetails.map(error =>{
            toast.error(`${error.context.key} ${error.type}`, {
              position: toast.POSITION.TOP_RIGHT
              });
          })
        });
    }
  }, [createDocument]);

  return (
    <Fragment>
      <h1>{GeneralInformation}</h1>
      <hr />
      <Row>
        <Col sm="4" xs="12">
          <LabeledInput
            label={PlatformName}
            placeholder={PlatformName}
            type={"text"}
            onChange={(event) => setPlatformName(event.target.value)}
            value={platformName}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default PlatformData;
