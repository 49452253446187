import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios'

const PopUpNotifications = (props) => {
    const eventData = useSelector(state => state.EventCommon.eventData)
    const vrMode = useSelector(state => state.EventCommon.eventType)
    const basictoaster = (toastname,text) => {
        switch (toastname) {
            case 'successNotification':
                toast.info("Success Notification !", {
                    position: toast.POSITION.TOP_RIGHT
                });
            break;
            case 'adviceNotification':
                toast.info(text, {
                position: toast.POSITION.BOTTOM_RIGHT
                });
            break;
            case 'basicwarningToast':
            toast.warn("Warning Notification !", {
            position: toast.POSITION.TOP_RIGHT
            });
            break;
            case 'basicdangerToast':
                toast.error("Danger Notification !", {
                position: toast.POSITION.TOP_RIGHT
                });
            break;
            default:
            break;
        }
    }
    const [advices, setAdvices] = useState([])
    const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp"
    useEffect(()=>{
      const params = {
        platformID: eventData.platformID,
        eventID: eventData.eventID
      }
      axios.get(`${ApiURL}/api/event/popUpNotifications`, {
        params: params
      }).then((res) => {
        setAdvices(res.data)
      }).catch(error => console.error(error))
    },[])
    

    useEffect(()=>{
        var i = 0
        var alertNotifications = setInterval(function () {
            if(i<advices.length){
                basictoaster("adviceNotification",advices[i])
                i++
            }else{
                i = 0
                basictoaster("adviceNotification",advices[i])
            }
        }, 240000)
    },[advices])
    if(!vrMode){
        return (
          <Fragment>
          </Fragment>
        )
    }
}
  export default PopUpNotifications