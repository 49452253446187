import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import Sidebar from "../layout/Sidebar";
// import RightSidebar from "../layout/RightSidebar";
// import Footer from "../layout/Footer";
import Loader from "./Loader";

// import ThemeCustomize from "../components/common/ThemeCustomize";

const AppLayout = ({ children }) => {
  const platformType = useSelector((state) => state.EvntState.type);
  // switch (platformType) {
  //   default:
  //   case "Admin":
  return (
    <Fragment>
      <div className="page-wrapper compact-page">
        <div className="page-body-wrapper">
          <div className="page-body">{children}</div>
        </div>
      </div>
    </Fragment>
  );

  // case "VR360":
  //   return (
  //     <Fragment>
  //       <Loader />
  //       <div className="page-wrapper compact-page">
  //         <div className="page-body-wrapper">
  //           <VR360 />
  //         </div>
  //       </div>
  //     </Fragment>
  //   );
  // }
};

export default AppLayout;
