import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  Table,
  Row,
  Col,
  Media,
  Badge,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  PositionCordinates,
  Size,
  PerspectiveTo,
  ExternalLink,
  Color,
  Image360,
  PerspectiveName,
  Streaming,
  Addons,
  Options,
  Hotspots,
  Status,
  Email,
  Add,
  Sponsors,
  ToList,
  Sponsor,
  SettingsEdit,
  New,
  Hotspot,
  AdPlacement,
  Statistics,
  Delete,
  LocationTo,
  VRView,
  CreateHotSpot,
  PerspectiveHotSpots,
  AddHotSpot,
  Type,
  ChooseLocation,
  ChoosePerspective,
  ChooseType,
} from "../../../../../constant";
import { useSelector, useDispatch } from "react-redux";
// import {SwitchVRMode} from '../../../../../redux/common/actions'
import { useParams } from "react-router-dom";
import { LoadingAsset } from "../../../../../components/common/WindowLoaders";
import LabeledDropdown from "../../../layout/FilterDropdown";
import { toast } from "react-toastify";
const HotSpotsTable = (props) => {
  const dispatch = useDispatch();
  // const switchVRMode= useSelector(state => state.Common.switchVRMode)

  const [isLoading, setIsLoading] = useState(true);
  const { platformID, eventID, locationID, perspectiveID } = useParams();
  const [hotSpots, setHotSpots] = useState([]);
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (
      platformID != undefined &&
      eventID != undefined &&
      locationID != undefined &&
      perspectiveID != undefined
    ) {
      setIsLoading(true);
      getHotSpots();
    }
  }, [platformID, eventID, locationID, perspectiveID]);
  const getHotSpots = () => {
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
      platformID: platformID,
      eventID: eventID,
      locationID: locationID,
      perspectiveID: perspectiveID,
    };
    axios
      .get(`${ApiURL}/api/vr360/platform/event/location/perspective/hotSpots`, {
        params: params,
      })
      .then((res) => {
        setHotSpotsData(res.data).then(() => {
          setIsLoading(false);
        });
      })
      .catch((error) => console.error(error));
  };
  const setHotSpotsData = (hotSpots) => {
    return new Promise((resolve, reject) => {
      setHotSpots(hotSpots);
      resolve(true);
    });
  };

  const [filteredLocationID, setFilteredLocationID] = useState(undefined);
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    if (
      platformID != undefined &&
      eventID != undefined &&
      locationID != undefined &&
      perspectiveID != undefined
    ) {
      setIsLoading(true);
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
        locationID: locationID,
        perspectiveID: perspectiveID,
      };
      axios
        .get(`${ApiURL}/api/admin/controlPanel/platform/event/locations`, {
          params: params,
        })
        .then((res) => {
          setLocationsData(res.data);
        })
        .catch((error) => console.error(error));
    }
  }, [platformID, eventID, locationID, perspectiveID]);

  const setLocationsData = (locations) => {
    return new Promise((resolve, reject) => {
      const locationsArray = locations.reduce((locations, location) => {
        return locations.concat({
          id: location.id,
          name: location.locationData.name,
        });
      }, []);
      setLocations(locationsArray);
      resolve(true);
    });
  };

  const [filteredPerspectiveID, setFilteredPerspectiveID] = useState(undefined);
  const [perspectives, setPerspectives] = useState([]);
  useEffect(() => {
    if (
      platformID != undefined &&
      eventID != undefined &&
      filteredLocationID != undefined
    ) {
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
        locationID: filteredLocationID.value,
      };
      axios
        .get(
          `${ApiURL}/api/admin/controlPanel/platform/event/locations/perspectives`,
          {
            params: params,
          }
        )
        .then((res) => {
          setPerspectivesData(res.data);
        })
        .catch((error) => console.error(error));
    }
  }, [platformID, eventID, filteredLocationID]);

  const setPerspectivesData = (perspectives) => {
    return new Promise((resolve, reject) => {
      const perspectivesArray = perspectives.reduce(
        (perspectives, perspective) => {
          return perspectives.concat({
            id: perspective.id,
            name: perspective.perspectiveData.name,
          });
        },
        []
      );
      setPerspectives(perspectivesArray);
      resolve(true);
    });
  };

  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const createHotSpot = () => {
    if (
      platformID != undefined &&
      eventID != undefined &&
      locationID != undefined &&
      perspectiveID != undefined &&
      filteredLocationID != undefined &&
      filteredPerspectiveID != undefined
    ) {
      axios
        .post(
          `${ApiURL}/api/admin/controlPanel/platform/event/location/perspective/hotSpot`,
          {
            userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
            platformID: platformID,
            eventID: eventID,
            locationID: locationID,
            perspectiveID: perspectiveID,
            hotSpot: {
              hotSpotData: {
                position: { x: positionX, y: positionY, z: positionZ },
                toLocationId: filteredLocationID.value,
                toPerspectiveId: filteredPerspectiveID.value,
              },
            },
          }
        )
        .then((res) => {
          getHotSpots();
        })
        .catch((error) => {
          console.error(error);
          const errorDetails = error.response.data.error.details;
          errorDetails.map((error) => {
            toast.error(`${error.context.key} ${error.type}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        });
    } else {
    }
  };

  if (isLoading) {
    return <LoadingAsset loading={PerspectiveHotSpots} />;
  }

  return (
    <Fragment>
      <div className="table-responsive">
        <Table hover>
          <thead>
            <tr>
              <th scope="col">{"#"}</th>
              <th scope="col">{PositionCordinates}</th>
              <th scope="col">{LocationTo}</th>
              <th scope="col">{PerspectiveTo}</th>
              <th scope="col">{Type}</th>
              <th scope="col">{Options}</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <b>{"#"}</b>
              </td>
              <td>
                <b>
                  <Col sm="4">
                    <Input
                      label={"X"}
                      type={"text"}
                      placeholder={"X"}
                      onChange={(event) => setPositionX(event.target.value)}
                      value={positionX}
                    />
                  </Col>
                  <Col sm="4">
                    <Input
                      label={"Y"}
                      type={"text"}
                      placeholder={"Y"}
                      onChange={(event) => setPositionY(event.target.value)}
                      value={positionY}
                    />
                  </Col>
                  <Col sm="4">
                    <Input
                      label={"Z"}
                      type={"text"}
                      placeholder={"Z"}
                      onChange={(event) => setPositionZ(event.target.value)}
                      value={positionZ}
                    />
                  </Col>
                </b>
              </td>
              <td>
                <LabeledDropdown
                  array={locations}
                  disabled={locations.length == 0 ? true : false}
                  placeHolder={ChooseLocation}
                  // default={locationID}
                  onChange={(option) => setFilteredLocationID(option)}
                />
              </td>
              <td>
                <LabeledDropdown
                  array={perspectives}
                  disabled={perspectives.length == 0 ? true : false}
                  placeHolder={ChoosePerspective}
                  // default={perspectiveID}
                  onChange={(option) => setFilteredPerspectiveID(option)}
                />
              </td>
              <td>
                <LabeledDropdown
                  array={[]}
                  placeHolder={ChooseType}
                  onChange={(option) => console.log(option)}
                />
              </td>
              <td>
                <Button onClick={createHotSpot} color="success">
                  {CreateHotSpot}
                </Button>
              </td>
            </tr>

            {hotSpots.length > 0
              ? hotSpots.map((hotSpotItem, i) => (
                  <tr>
                    <td>
                      <b>{i}</b>
                    </td>
                    <td>
                      <b>
                        <Row>
                          <Col sm="4">
                            <Input
                              className="form-control payment-card-field"
                              type="text"
                              placeholder="x"
                              value={hotSpotItem.position.x}
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              className="form-control payment-card-field"
                              type="text"
                              placeholder="y"
                              value={hotSpotItem.position.y}
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              className="form-control payment-card-field"
                              type="text"
                              placeholder="z"
                              value={hotSpotItem.position.z}
                            />
                          </Col>
                        </Row>
                      </b>
                    </td>
                    <td>
                      <b>{hotSpotItem.locationName}</b>
                    </td>
                    <td>
                      <b>{hotSpotItem.perspectiveName}</b>
                    </td>
                    <td>
                      <ul>
                        <li>
                          <Button
                            color="primary"
                            size="xs"
                            onClick={() => console.log("switchVRMode")}
                          >
                            {SettingsEdit}
                          </Button>
                        </li>
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/dashboard/hospital`}
                          >
                            <Button color="primary" size="xs">
                              {Statistics}
                            </Button>
                          </Link>
                        </li>
                        <li>
                          <Button color="light" size="xs">
                            {Delete}
                          </Button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default HotSpotsTable;
