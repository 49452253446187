import React, { useEffect, useState} from 'react'
import axios from 'axios'
import { Card, CardBody } from 'reactstrap'
import { Fragment } from 'react'
import { Media } from 'reactstrap'
import CommentsList from '../../../../components/general/CommentsList'
import CloseCard from './CloseWindow'
import { UserThumbnail } from '../../../../components/general/UserThumbnail'

export const ChatTab = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [chatDetails, setChatDetails] = useState(props.windowData)
    const [chatHistory, setChatHistory] = useState([])
    const currentUserID = localStorage.getItem('UserID')

    useEffect(()=>{
        setChatDetails(props.windowData)
    },[props.windowData])

    return(
        <Fragment>
            <Card style={{margin:"15px 30px", width:"300px", height:"400px", pointerEvents:"all"}}>
            <div onClick={()=> props.checkIfIsOpen({name:props.windowName})}><CloseCard/></div>
                <CardBody>
                    <UserThumbnail userProfile={chatDetails.profile} checkIfIsOpen={props.checkIfIsOpen} chatBtn={false}  videoBtn={true} phoneBtn={true}/>
                    {/* <CommentsList type="comments" commentsData={chatHistory} height={"225px"}/> */}
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default ChatTab