import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../assets/css/evnt_cards.css";
import defaultProfilePicture from "../../assets/images/defaultProfilePicture.jpg";
import { getAuth } from "firebase/auth";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LoadingAsset } from "../common/WindowLoaders";
import { SetEventCaster } from "../../redux/common/EventActions";
import { Badge, Media } from "reactstrap";

// HEADER USERS THUMBNAILS
export const HeaderUserThumbnail = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserID, setCurrentUserID] = useState(undefined);
  const [profilePicture, setProfilePicture] = useState("");
  const [userName, setUserName] = useState("");
  const [userTokens, setUserTokens] = useState(0);
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";

  useEffect(() => {
    if (currentUserID != undefined) {
      setProfilePicture(
        localStorage.getItem("profileURL") || defaultProfilePicture
      );
      setUserName(localStorage.getItem("Name"));
      const params = {
        userID: currentUserID,
      };
      axios
        .get(`${ApiURL}/api/user/tokens`, {
          params: params,
        })
        .then((res) => {
          setUserTokensData(res.data).then(() => {
            setIsLoading(false);
          });
        })
        .catch((error) => console.error(error));
    } else if(getAuth().currentUser != null) {
      setCurrentUserID(getAuth().currentUser.uid);
    }
  }, [getAuth(), currentUserID]);

  const setUserTokensData = (tokens) => {
    return new Promise((resolve, reject) => {
      setUserTokens(tokens);
      resolve(true);
    });
  };

  if (isLoading) {
    return (
      <LoadingAsset loading={"HeaderUserThumbnail"} />
    );
  }

  return (
    <Fragment>
      <span className="step13 cursor-pointer d-flex">
        <Media
          className="mr-2 rounded-circle img-40"
          style={{ height: "40px" }}
          src={profilePicture}
          alt=""
          onClick={() =>
            props.checkIfIsOpen({
              name: "UserProfileCard",
              data: { userID: currentUserID },
            })
          }
        />
        <span className="media-body text-color">
          <span
            className="f-16 f-w-600"
            onClick={() =>
              props.checkIfIsOpen({
                name: "UserProfileCard",
                data: { userID: currentUserID },
              })
            }
          >
            {userName}
          </span>
          &nbsp;
          <span
            className="d-block f-14"
            style={{ cursor: "pointer" }}
            onClick={() =>
              props.checkIfIsOpen({
                name: "paymentCard",
                data: {
                  itemToPurchase: "tokens",
                  item: "Tokens",
                  price: { amount: "15", currency: "MXN" },
                },
              })
            }
          >
            <i className="fa fa-usd"> &nbsp; </i>
            <b className={"f-14"}>{userTokens + " Tokens"}</b>
          </span>
        </span>
      </span>
    </Fragment>
  );
};

// LIST USERS THUMBNAILS
export const ContactsThumbnails = (props) => {
  const [user, setUser] = useState(props.userProfile);
  useEffect(() => {
    setUser(props.userProfile);
  }, [props.userProfile]);

  return (
    <div id={`user_ ${user.id}`}>
      <span
        style={{ cursor: "pointer", paddingBottom: "10px" }}
        key={user.id}
        className="media user-header"
      >
        <img
          className="mr-2 rounded-circle img-35"
          src={require(`../../assets/images/${user.profilePicture}`)}
          style={{ width: "35px", height: "35px" }}
          alt=""
        />
        <span className="media-body">
          <span
            style={{ color: "white", fontSize: 14 }}
            className="f-12 f-w-600"
          >
            {user.name + " " + user.lastName}
          </span>
          &nbsp;
          <i className="fa fa-user" /> &nbsp;
          <i className="fa fa-message" />
        </span>
      </span>
    </div>
  );
};

export const SimpleUserThumbnail = (props) => {
  const [userProfile, setUserProfile] = useState(props.userProfile);
  useEffect(() => {
    setUserProfile(props.userProfile);
  }, [props.userProfile]);

  return (
    <Fragment>
      <div id={`user_ ${userProfile.id}`}>
        <span
          style={{ cursor: "pointer", paddingBottom: "10px" }}
          key={userProfile.id}
          className="media user-header"
        >
          <img
            className="mr-2 rounded-circle img-35"
            src={require(`../../assets/images/${userProfile.profilePicture}`)}
            style={{ width: "35px", height: "35px" }}
            alt=""
            onClick={() => props.thumbnailClick(userProfile.id)}
          />
          <span className="media-body">
            <span
              style={{ color: "white", fontSize: 14 }}
              className="f-12 f-w-600"
              onClick={() => props.thumbnailClick(userProfile.id)}
            >
              {userProfile.name + " " + userProfile.lastName}
            </span>
            &nbsp;
            <i className="fa fa-circle font-info" />
            &nbsp;
            <i className="fa fa-circle font-success" />
          </span>
        </span>
      </div>
    </Fragment>
  );
};

export const UserThumbnail = (props) => {
  const [user, setUser] = useState(props.userProfile);
  useEffect(() => {
    setUser(props.userProfile);
  }, [props.userProfile]);

  return (
    <div key={user.id} className="media user-header contact-thumbnail">
      <div className="avatars" id={`user_ ${user.id}`}>
        <div className="avatar">
          <Media
            body
            className="img-40 rounded-circle"
            src={require(`../../assets/images/${user.profilePicture}`)}
            alt="#"
          />
          <div className="status status-50 bg-success"></div>
        </div>
      </div>
      <span className="media-body">
        <div className="contact-info">
          <span className="f-w-600 contact-name">
            {user.name + " " + user.lastName}
          </span>
          <div className="contact-data">
            <Badge color="info" pill>
              {"OnSite"}
            </Badge>
            <ul className="pull-right contact-actions">
              {props.chatBtn ? (
                <li
                  onClick={() =>
                    props.checkIfIsOpen({
                      name: "ChatTab",
                      data: { profile: user },
                    })
                  }
                >
                  <i className="fa fa-comment fa-lg"></i>
                </li>
              ) : (
                ""
              )}
              {props.videoBtn ? (
                <li
                  onClick={() =>
                    props.checkIfIsOpen({
                      name: "ChatTab",
                      data: { profile: user },
                    })
                  }
                >
                  <i className="fa fa-phone fa-lg"></i>
                </li>
              ) : (
                ""
              )}
              {props.phoneBtn ? (
                <li
                  onClick={() =>
                    props.checkIfIsOpen({
                      name: "ChatTab",
                      data: { profile: user },
                    })
                  }
                >
                  <i className="fa fa-video-camera fa-lg"></i>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </span>
    </div>
  );
};

export const PlayerThumbnail = (props) => {
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  return (
    <div id={`user_ ${user.id}`}>
      <span
        style={{ cursor: "pointer", paddingBottom: "10px" }}
        key={user.id}
        className="media user-header"
      >
        <img
          className="mr-2 rounded-circle img-35"
          src={user.photoURL}
          style={{ width: "35px", height: "35px" }}
          alt=""
        />
        <span className="media-body">
          <span
            style={{ color: "white", fontSize: 14 }}
            className="f-12 f-w-600"
          >
            {user.firstName + " " + user.lastName}
          </span>
          &nbsp;
          <Fragment>
            {true ? <i className="fa fa-circle font-info" /> : ""} &nbsp;
            {true ? <i className="fa fa-circle font-success" /> : ""}
          </Fragment>
        </span>
      </span>
    </div>
  );
};

export const HeaderEventCaster = (props) => {
  const dispatch = useDispatch();
  const caster = useSelector(
    (state) => state.EventCommon.settings.eventCasters.default
  );

  axios.defaults.baseURL = `http://localhost:4000`;
  useEffect(() => {
    if (Object.keys(caster) == 0) {
      const params = {
        casterID: caster,
      };

      axios
        .get(`eventCaster/`, {
          params: params,
        })
        .then((res) => {
          dispatch(SetEventCaster(res.data));
        })
        .catch((error) => console.error(error));
    }
  }, [caster]);

  if (Object.keys(caster).length === 0) {
    return <LoadingAsset loading={"UserThumbnail ( HeaderEventCaster ).js"} />;
  }

  return (
    <Fragment>
      <span
        className="media user-header text-color"
        style={{ cursor: "pointer", marginTop: "0px" }}
        key={caster.id}
      >
        <img
          className="mr-2 rounded-circle img-40"
          src={caster.profilePicture}
          alt=""
        />
        <span className="media-body">
          <span className="f-16 f-w-600">
            {caster.name + " " + caster.lastName}
          </span>
          <span className="d-block f-12">
            <span className="badge badge-pill badge-success">
              <i
                style={{ color: "white" }}
                className="fa fa-microphone fa-lg"
              ></i>
            </span>
            &nbsp;{"Sports Caster"}
          </span>
        </span>
      </span>
    </Fragment>
  );
};

export const EventCaster = (props) => {
  const dispatch = useDispatch();
  const [caster, setCaster] = useState(props.profile);

  return (
    <Fragment>
      <div
        id={`user_ ${caster.id}`}
        onClick={() => dispatch(SetEventCaster(caster))}
      >
        <span
          style={{ cursor: "pointer", paddingBottom: "10px" }}
          key={caster.id}
          className="media user-header"
        >
          <img
            className="mr-2 rounded-circle img-35"
            src={caster.photoURL}
            style={{ width: "35px", height: "35px" }}
            alt=""
          />
          <span className="media-body">
            <span
              style={{ color: "white", fontSize: 14 }}
              className="f-12 f-w-600"
            >
              {caster.firstName + " " + caster.lastName}
            </span>
            &nbsp;
            <i className="fa fa-lock" />
          </span>
        </span>
      </div>
    </Fragment>
  );
};
