import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "../../../../assets/css/evnt_admin.css";

import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

import {
  Settings,
  PlatformDetailss,
  GeneralInfo,
  PlatformLogo,
  Events,
  AddEvent,
} from "../../../../constant";

import { useSelector, useDispatch } from "react-redux";
import { SettingsPlatformLogo } from "./platformDetails/Settings";
import PlatformData from "./platformDetails/PlatformData";
import { Link } from "react-router-dom";

const PlatformDetails = (props) => {
  const [VerticleTab, setVerticleTab] = useState("1");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [platform, setPlatform] = useState(props.platform);

  return (
    <Fragment>
      <h1>{PlatformDetailss}</h1>
      <hr />
      <Row>
        <Col sm="2" xs="12">
          <Nav className="nav flex-column nav-pills">
            <NavItem>
              <NavLink
                className={`cursor-pointer ${
                  VerticleTab === "1" ? "active" : ""
                }`}
                onClick={() => setVerticleTab("1")}
              >
                {GeneralInfo}
              </NavLink>
            </NavItem>
            {platform != undefined ? (
              <Dropdown
                className="nav-item"
                isOpen={dropdownOpen}
                toggle={toggle}
              >
                <NavItem>
                  <DropdownToggle
                    className={`nav-link cursor-pointer ${
                      VerticleTab != "1" ? "active" : ""
                    }`}
                    tag="a"
                    color="default"
                    caret
                  >
                    {Settings}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => setVerticleTab("2")}
                      className={`cursor-pointer ${
                        VerticleTab === "2" ? "active" : ""
                      }`}
                    >
                      {PlatformLogo}
                    </DropdownItem>
                  </DropdownMenu>
                </NavItem>
              </Dropdown>
            ) : (
              ""
            )}
          </Nav>
          {platform != undefined ? (
            <Fragment>
              <hr />
              {platform.collections.map((collection) => {
                return <h5>{collection}</h5>;
              })}
              <hr />
              <Nav className="nav flex-column nav-pills">
                <NavItem>
                  <NavLink>
                    <Button color="light">
                      <Link
                        to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platform.id}/Event/Form`}
                      >
                        <i class="fa fa-plus-circle"></i>&nbsp;{AddEvent}
                      </Link>
                    </Button>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Button color="light">
                      <Link
                        to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platform.id}/Events/List`}
                      >
                        {Events}&nbsp;<i className="fa fa-level-down"></i>
                      </Link>
                    </Button>
                  </NavLink>
                </NavItem>
              </Nav>
            </Fragment>
          ) : (
            ""
          )}
        </Col>
        <Col sm="10" xs="12">
          <TabContent activeTab={VerticleTab}>
            <TabPane className="fade show" tabId="1">
              <PlatformData platform={platform} />
            </TabPane>
            <TabPane tabId="2">
              <SettingsPlatformLogo platform={platform} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PlatformDetails;
