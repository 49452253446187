import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import {
  Media,
  Row,
  Col,
  NavLink,
  Card,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import ScrollArea from "react-scrollbar";
import {
  Feed,
  Followers,
  Following,
  LogOut,
  Merch,
  Music,
  Statistics,
} from "../../../../constant";
import "../../../../assets/css/evnt_cards.css";
import man from "../../../../assets/images/dashboard/user.png";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import { getAuth } from "firebase/auth";
import { LoadingAsset } from "../../../../components/common/WindowLoaders";
import { StatisticsTable } from './TeamStatistics'
import MerchItems from "./components/MerchItems";
import MediaList from "./components/MediaList";

export const ProfileCardData = (props) => {
  const history = useHistory();
  const { platformID, eventID } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [currentUserID, setCurrentUserID] = useState(undefined);
  const [profileID, setProfileID] = useState(props.userID);
  const [user, setUser] = useState({});
  const [pillDarkTab, setpillDarkTab] = useState("1");
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    setProfileID(props.userID);
    setCurrentUserID(getAuth().currentUser.uid);
    const params = {
      userID: profileID,
    };
    axios
      .get(`${ApiURL}/api/user`, {
        params: params,
      })
      .then((res) => {
        setUserData(res.data).then(() => {
          setIsLoading(false);
        });
      })
      .catch((error) => console.error(error));
  }, [props.userID]);

  const setUserData = (user) => {
    return new Promise((resolve, reject) => {
      setUser(user);
      resolve(true);
    });
  };

  const Logout_From_Firebase = () => {
    const auth = getAuth();
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    auth.signOut();
    history.push(
      `${process.env.PUBLIC_URL}/LogInRouter/${platformID}/${eventID}`
    );
  };

  if (isLoading) {
    return <LoadingAsset loading={"ProfileCardData.js"} />;
  }

  return (
    <Fragment>
      <div className="user-profile">
        <div className={"m-l-15 m-r-15 m-t-10 "} style={{ display: "flex" }}>
          <div className="image avatars text-center">
            <div className="avatar">
              <Media
                body
                className="img-70 rounded-circle"
                src={user.userData.photoURL}
                alt="#"
              />
              <div className="status status-70 bg-success"> </div>
            </div>
          </div>
          <div className={"m-t-5 m-l-10"}>
            <h6
              className="text-uppercase text-color"
              style={{ marginBottom: 0 }}
            >
              <b>{`${user.userData.firstName} ${user.userData.lastName}`}</b>
            </h6>
            <span className="text-color">{`@${user.userData.userName}`}</span>
            <div className="social-media">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a
                    href={`${user.userData.socialMedia.facebook}`}
                    target="_blank"
                  >
                    <i className="text-color fa fa-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={`${user.userData.socialMedia.twitter}`}
                    target="_blank"
                  >
                    <i className="text-color fa fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={`${user.userData.socialMedia.instagram}`}
                    target="_blank"
                  >
                    <i className="text-color fa fa-instagram"></i>
                  </a>
                </li>
                {profileID == currentUserID ? (
                  <li className="list-inline-item">
                    <span
                      className="cursor-pointer badge badge-primary badge-pill"
                      pill
                      color="primary"
                      size="md"
                      onClick={Logout_From_Firebase}
                    >
                      {LogOut}
                    </span>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
        {props.counters == undefined ? (
          <div className="user-content text-center m-t-10">
            <hr style={{ margin: "0px" }} />
            <div className="cursor-default text-center">
              <Row>
                <Col className="border-right">
                  <span className="text-color">{Following}</span>
                  <div style={{ color: "white", fontSize: "25px" }}>
                    <b>{user.following.length}</b>
                  </div>
                </Col>
                <Col>
                  <span className="text-color">{Followers}</span>
                  <div style={{ color: "white", fontSize: "25px" }}>
                    <b>{user.followers.length}</b>
                  </div>
                </Col>
              </Row>
            </div>
            <hr style={{ margin: "0px" }} />
          </div>
        ) : (
          ""
        )}
      </div>
      {user.userData.category == "user" ? (
        <p className="mb-0 m-t-30">{"Feed"}</p>
      ) : (
        <div>
          <Nav
            className="cursor-pointer nav-primary"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <NavItem>
              <NavLink
                className={`text-color ${pillDarkTab === "1" ? "active" : ""}`}
                onClick={() => setpillDarkTab("1")}
              >
                {user.userData.category == "singer" ? (
                  <Fragment>
                    <i className="fa fa-music"></i>
                    {Music}
                  </Fragment>
                ) : (
                  <Fragment>
                    <i className="fa fa-bar-chart-o"></i>
                    {Statistics}
                  </Fragment>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`text-color ${pillDarkTab === "2" ? "active" : ""}`}
                onClick={() => setpillDarkTab("2")}
              >
                <i className="fa fa-shopping-cart"></i>
                {Merch}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`text-color ${pillDarkTab === "3" ? "active" : ""}`}
                onClick={() => setpillDarkTab("3")}
              >
                <i className="fa fa-comments"></i>
                {Feed}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={pillDarkTab} className="m-l-10 m-r-10">
            <TabPane className="fade show" tabId="1">
              {user.userData.category == "singer" ? (
                <Fragment>
                <ScrollArea
                speed={0.5}
                horizontal={false}
                vertical={true}
                style={{ height: "220px" }}
              >
                <MediaList talentID={user.id}/>
              </ScrollArea></Fragment>
              ) : (
                <Fragment>
                  <ScrollArea
                  speed={0.5}
                  horizontal={false}
                  vertical={true}
                  style={{ height: "220px" }}
                >
                  <StatisticsTable metrics={user.userMetrics}/>
                </ScrollArea>
                </Fragment>
              )}
            </TabPane>
            <TabPane tabId="2">
              <MerchItems sellerID={profileID} />
            </TabPane>
            <TabPane tabId="3">
              <p className="mb-0 m-t-30">{"Feed"}</p>
            </TabPane>
          </TabContent>
        </div>
      )}
    </Fragment>
  );
};
