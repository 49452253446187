import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import HeaderBar from './HeaderBar'
import BottomBar from './BottomBar'
import PopUpNotifications from './cards/PopUpNotifications'
import WindowCards from './cards/WindowCards'
export const Layer_2D = (props) => {
          
    
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const vrMode = useSelector(state => state.EventCommon.eventType)
    const event = useSelector(state => state.Common.currentEvent)
    
    const [opentour,setopentour] = useState(true)

    const [openCards, setOpenCards]=useState([{name:"venueLocations"}])
    const cardInfo = (name) =>{
        var cardIndex = openCards.findIndex((card)=> card.name === name)
        var cardData = cardIndex > -1 ? openCards[cardIndex].data : undefined
        return({
            index: cardIndex,
            data:  cardData
        })
    }
    
    const checkIfIsOpen = (tab) => {
      setOpenCards([])
      if(cardInfo(tab.type).index > -1 ){
        handleCard(false,tab)
      }else{
        handleCard(true,tab)
      }
    }
    
    const handleCard = (type,card) => {
        // type == true is for adding , type == false for closing
        if(type){
          if(cardInfo(card.name).index === -1 && openCards.length < 2){
            setOpenCards(openCards => [...openCards,card])
          }else if(openCards.length == 2){
            console.log("There are more than 2 windows on screen",openCards)
          }else if(cardInfo(card.name).index > -1){
            deleteLeftSideTab(type,card)
          }
        }else{
          deleteLeftSideTab(type,card)
        }
    
        function deleteLeftSideTab (type,card){
          setOpenCards(openCards.filter(cardd => cardd.name !== card.name))
        }
    }

    useEffect(()=>{
      if(Object.keys(props.VrAd).length > 0){
        checkIfIsOpen(props.VrAd)
      }
    },[props.VrAd])

    if(!vrMode){
      return(
        <Fragment>
          <PopUpNotifications/>
          <HeaderBar
            checkIfIsOpen={(name)=>checkIfIsOpen(name)}
            windowWidth={props.windowWidth}
            windowHeight={props.windowHeight}
            currentShow={props.currentShow}
            eventTypes={props.eventTypes}/>
          {/* <IntsructionsTour opentour={opentour} setopentour={(bool)=>setopentour(bool)} windowWidth={props.windowWidth} windowHeight={props.windowHeight}/> */}
          <WindowCards 
            openCards={openCards} 
            checkIfIsOpen={(name)=>checkIfIsOpen(name)} 
            setopentour={(bool)=>setopentour(bool)} />
          <BottomBar
            checkIfIsOpen={(name)=>checkIfIsOpen(name)}
            windowWidth={props.windowWidth}
            windowHeight={props.windowHeight}/>
        </Fragment>
      )
    }
}

export default Layer_2D