import React from 'react'
import { Fragment } from 'react'

const CloseWindow = (props) => {
    return (
        <Fragment>
            <div style={{position:"absolute",top:15,right:15,color:"white",cursor:"pointer"}}>
                <i className="fa fa-times fa-lg"></i>
            </div>
        </Fragment>
    )
  }
  export default CloseWindow