import React, { useEffect, useLayoutEffect, useState } from 'react'
import { MoreHorizontal } from 'react-feather';
import { MobileRightToggle } from '../../../redux/common/actions'
import { useDispatch, useSelector } from 'react-redux';
import logo_light from '../../../assets/images/creative-logo1.png'
import { Media, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { AudioSettings, ChooseYourSeat, Casino, HalfTimeShow, NikeStore,
     SettingsMore, SocialSharing, Statistics, VenueHome, VIPSuite, 
     ZoomInPerspective, ZoomOutPerspective } from '../../../constant'
     
import {ChangePerspective, PerspectiveZoom, CheckIfIsOpen} from '../../../redux/common/EventActions'
import '../../../assets/css/evnt_cards.css'

export const BottomBar_VR = (props) => {
    const dispatch = useDispatch()
    const mobileRightTog = useSelector(state => state.Common.mobileRightToggle)
    const homePerspective = useSelector(state => state.EventCommon.settings.specialPerspectives.homePerspective)
    const suitPerspective = useSelector(state => state.EventCommon.settings.specialPerspectives.userSuite)
    const halfTimeShow = useSelector(state => state.EventCommon.settings.specialPerspectives.halfTimeShowPerspective)

    const vrMode = useSelector(state => state.EventCommon.eventType)
    const cameraZoom = useSelector(state => state.PerspectiveCommon.perspectiveZoom)
    const zoomCamera = (type) => {
        if(type){
          if(cameraZoom < 5){
            dispatch(PerspectiveZoom(cameraZoom+1))
          }else{
            dispatch( PerspectiveZoom(1))
          }
        }else{
            dispatch(PerspectiveZoom(1))
        }
    }
    
    const width = useWindowSize()
    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    
    // const [popover, setPopover] = useState(false)
    // const Toggle = () => setPopover(!popover);

    const [BTNHOME, setBTNHOME] = useState(false)
    const BTNHOMETOGGLE = () => setBTNHOME(!BTNHOME)
    const [BTNSEATS, setBTNSEATS] = useState(false)
    const BTNSEATSTOGGLE = () => setBTNSEATS(!BTNSEATS)
    const [BTNVIP, setBTNVIP] = useState(false)
    const BTNVIPTOGGLE = () => setBTNVIP(!BTNVIP)
    const [BTNZOOM, setBTNZOOM] = useState(false)
    const BTNZOOMTOGGLE = () => setBTNZOOM(!BTNZOOM)
    const [BTNAUDIO, setBTNAUDIO] = useState(false)
    const BTNAUDIOTOGGLE = () => setBTNAUDIO(!BTNAUDIO)
    const [BTNSTATISTICS, setBTNSTATISTICS] = useState(false)
    const BTNSTATISTICSTOGGLE = () => setBTNSTATISTICS(!BTNSTATISTICS)
    const [BTNNIKESTORE, setBTNNIKESTORE] = useState(false)
    const BTNNIKESTORETOGGLE = () => setBTNNIKESTORE(!BTNNIKESTORE)
    const [BTNCASINO, setBTNCASINO] = useState(false)
    const BTNCASINOTOGGLE = () => setBTNCASINO(!BTNCASINO)
    const [BTNHALFTIMESHOW, setBTNHALFTIMESHOW] = useState(false)
    const BTNHALFTIMESHOWTOGGLE = () => setBTNHALFTIMESHOW(!BTNHALFTIMESHOW)
    const [BTNSOCIALSHARING, setBTNSOCIALSHARING] = useState(false)
    const BTNSOCIALSHARINGTOGGLE = () => setBTNSOCIALSHARING(!BTNSOCIALSHARING)
    const [BTNMORE, setBTNMORE] = useState(false)
    const BTNMORETOGGLE = () => setBTNMORE(!BTNMORE)

    const btnHoverTexts = [
        {
          id:"BTN-HOME",
          placement: "bottom",
          Popoverbody:VenueHome,
          trigger:"hover",
          popover:BTNHOME,
          toggle:BTNHOMETOGGLE
        },
        {
            id:"BTN-SEATS",
            placement: "bottom",
            Popoverbody:ChooseYourSeat,
            trigger:"hover",
            popover:BTNSEATS,
            toggle:BTNSEATSTOGGLE
        },
        {
            id:"BTN-VIP",
            placement: "bottom",
            Popoverbody:VIPSuite,
            trigger:"hover",
            popover:BTNVIP,
            toggle:BTNVIPTOGGLE
        },
        {
            id:"BTN-ZOOM",
            placement: "bottom",
            Popoverbody:ZoomInPerspective,
            trigger:"hover",
            popover:BTNZOOM,
            toggle:BTNZOOMTOGGLE
        },
        {
            id:"BTN-AUDIO",
            placement: "bottom",
            Popoverbody:AudioSettings,
            trigger:"hover",
            popover:BTNAUDIO,
            toggle:BTNAUDIOTOGGLE
        },
        {
            id:"BTN-STATISTICS",
            placement: "bottom",
            Popoverbody:Statistics,
            trigger:"hover",
            popover:BTNSTATISTICS,
            toggle:BTNSTATISTICSTOGGLE
        },
        {
            id:"BTN-NIKE-STORE",
            placement: "bottom",
            Popoverbody:NikeStore,
            trigger:"hover",
            popover:BTNNIKESTORE,
            toggle:BTNNIKESTORETOGGLE
        },
        {
            id:"BTN-CASINO-CALIENTE",
            placement: "bottom",
            Popoverbody:Casino,
            trigger:"hover",
            popover:BTNCASINO,
            toggle:BTNCASINOTOGGLE
        },
        {
            id:"BTN-HALF-TIME-SHOW",
            placement: "bottom",
            Popoverbody:HalfTimeShow,
            trigger:"hover",
            popover:BTNHALFTIMESHOW,
            toggle:BTNHALFTIMESHOWTOGGLE
        },
        {
            id:"BTN-SOCIAL-SHARING",
            placement: "bottom",
            Popoverbody:SocialSharing,
            trigger:"hover",
            popover:BTNSOCIALSHARING,
            toggle:BTNSOCIALSHARINGTOGGLE
        },
        {
            id:"BTN-MORE",
            placement: "bottom",
            Popoverbody:SettingsMore,
            trigger:"hover",
            popover:BTNMORE,
            toggle:BTNMORETOGGLE
        }
    ]

    const windowType = () => {
        if(props.windowWidth <= 1000){
            return "none"
        }else{
            return "block"
        }
    }
    
    if(!vrMode){
        return(
            <div style={{bottom:0,top:"auto", marginLeft: "20%", width: "60%", marginRight: "20%"}} 
                className={`page-main-header open`}>
                <div className="main-header-right">
                    {width <= 991? 
                        <ul className={`nav-menus bottom-menu-bar`}>
                            {/* <li className="step9" style={{color:"white",cursor:"pointer",margin:"0px 15px"}} onClick={()=>props.playingVideo(props.Video360.playing)} >
                                {props.Video360.playing?<i className="fa fa-play fa-2x"></i>:<i className="fa fa-pause fa-2x"></i>}
                            </li> */}   

                            <li id="BTN-SEATS" className="step2 pointer-white" onClick={() => props.checkIfIsOpen({name:"venueLocations"})}> 
                                <Media className="b-r-10" style={{width:40}} src={require("../../../assets/images/icons/venue.png")}/>
                            </li>
                            <li id="BTN-AUDIO" className="step5 pointer-white" onClick={()=> props.checkIfIsOpen({name:"AudioSettings"})}>
                                {true?<i className="fa fa-volume-up fa-2x"></i>:<i className="fa fa-volume-off fa-2x"></i>}
                            </li>
                            <li id="BTN-ZOOM" className="step4 pointer-white" onClick={()=>zoomCamera(true)} >
                                <i className="fa fa-search-plus fa-2x"></i>
                            </li>
                            <li id="BTN-MORE" className="step5 pointer-white" onClick={()=> props.checkIfIsOpen({name:"MoreMobile"})}>
                                <i className="fa fa-th fa-2x"></i>
                            </li>   

                            {/* {props.cameraZoom > 1 ? 
                                <li id="BTN-ZOOM-OUT" style={{color:"white",cursor:"pointer"}} onClick={(type)=>zoomCamera(false)} >
                                    <i className="fa fa-search-minus fa-2x"></i>
                                </li>
                            :""} */}
                            {/* <li className="step11" style={{color:"white",cursor:"pointer",margin:"0px 15px"}} onClick={()=>zoomCamera()} >
                                <i className="fa fa-search-plus fa-2x"></i>
                            </li> */}   

                        </ul>
                    :
                    <div className="nav-right" style={{marginLeft:"auto", marginRight:"auto"}}>
                      <ul className={`nav-menus ${mobileRightTog ? 'open': ''}`}>   

                        {/* <li style={{color:"white",cursor:"pointer"}} onClick={()=>props.playingVideo(props.Video360.playing)} >
                            {props.Video360.playing?<i className="fa fa-play fa-2x"></i>:<i className="fa fa-pause fa-2x"></i>}
                        </li> */}   

                        <li id="BTN-HOME" className="step1 example-popover pointer-white" onClick={() => dispatch(ChangePerspective({toLocationId:homePerspective.toLocationId,toPerspectiveId:homePerspective.toPerspectiveId,type:undefined}))}>
                            <i className="fa fa-home fa-3x"></i>
                        </li>   

                        <li id="BTN-SEATS" className="step2 pointer-white" onClick={() => props.checkIfIsOpen({name:"venueLocations"})}>
                            <Media className="b-r-10" style={{width:40}} src={require("../../../assets/images/icons/venue.png")}/>
                        </li>   
                        {suitPerspective != undefined ? 
                        <li id="BTN-VIP" className="step3 pointer-white" onClick={()=> 
                            props.checkIfIsOpen({name:"LocationPurchase",data:{

                                name:"Palco Azteca",
                                previewType:"video",
                                src:"product/premium_perspective.jpg",
                                video:"Stadium/PalcoNFL.mp4",

                                toLocationId:suitPerspective.toLocationId,
                                toPerspectiveId:suitPerspective.toPerspectiveId
                              }})
                            }>
                            <h4><b>{"VIP"}</b></h4>
                        </li>:""}

                        <li id="BTN-ZOOM" className="step4 pointer-white" onClick={()=>zoomCamera(true)} >
                            <i className="fa fa-search-plus fa-2x"></i>
                            {cameraZoom > 1 ?
                            <span className="notification badge badge-pill badge-warning">{cameraZoom+"x"}</span>:""}
                        </li>   

                        <li id="BTN-AUDIO" className="step5 pointer-white" onClick={()=> props.checkIfIsOpen({name:"AudioSettings"})}>
                            {true?<i className="fa fa-volume-up fa-2x"></i>:<i className="fa fa-volume-off fa-2x"></i>}
                        </li>   

                        <li id="BTN-STATISTICS" className="step6 pointer-white" onClick={()=> props.checkIfIsOpen({name:"teamStatistics"})}>
                            <i className="fa fa-bar-chart-o fa-2x"></i>
                        </li>   

                        <li id="BTN-MORE" className="step5 pointer-white" style={props.windowWidth <= 1000 ? {display:"bloc"}:{display:"none"}} onClick={()=> props.checkIfIsOpen({name:"MoreMobile"})}>
                            <i className="fa fa-th fa-2x"></i>
                        </li>
                            
                        <li id="BTN-NIKE-STORE" className="step7 pointer-white" style={{display:windowType()}} onClick={()=>  props.checkIfIsOpen({name:"Iframe",data:{src:"http://sneakerstore.com.mx/"}})}>
                            <Media className="b-r-10" style={{width:35}} src={require("../../../assets/images/icons/nikeSNKRS.png")}/>
                        </li>   

                        <li id="BTN-CASINO-CALIENTE" className="step8 pointer-white" style={{display:windowType()}}  onClick={()=> props.checkIfIsOpen({name:"Iframe",data:{src:"https://www.caliente.mx/"}})}>
                            <Media className="b-r-10" style={{width:35}} src={require("../../../assets/images/icons/calienteCasino.png")}/>
                        </li>
                            
                        <li id="BTN-HALF-TIME-SHOW" className="step9 pointer-white" style={{display:windowType()}}  onClick={() => dispatch(ChangePerspective({toLocationId:halfTimeShow.toLocationId,toPerspectiveId:halfTimeShow.toPerspectiveId,type:undefined}))}>
                            <i className="fa fa-star fa-2x"></i>
                        </li>   

                        <li id="BTN-SOCIAL-SHARING" className="step10 pointer-white" style={{display:windowType()}} onClick={()=> props.checkIfIsOpen({name:"socialSharing"})}>
                            <i className="fa fa-share-square-o fa-2x"></i>
                        </li>   

                        <li id="BTN-MORE" className="step11 pointer-white" style={{display:windowType()}} onClick={()=> props.checkIfIsOpen({name:"Settings360"})}>
                            <i className="fa fa-ellipsis-h fa-2x"></i>
                        </li>   

                        {/* {props.currentShow.Streaming2D != undefined ?
                        <li style={{color:"white",cursor:"pointer"}} onClick={()=> props.checkIfIsOpen({name:"Streaming2D")} >
                            <i className="fa fa-youtube-play fa-2x"></i>
                        </li>:""} */}   

                        {/* <li style={{color:"white",cursor:"pointer"}}>
                            <i className="fa fa-cubes fa-2x"></i>
                        </li> */}   

                      </ul>
                    </div>
                    }
                </div>  


                {btnHoverTexts.map((hover,i) =>{
                    return(
                        <Popover
                            target={hover.id}
                            placement={hover.placement}
                            isOpen={hover.popover}
                            toggle={hover.toggle}
                            trigger={hover.trigger}
                            >
                             <PopoverBody>{hover.Popoverbody}</PopoverBody>
                        </Popover>
                    )
                })} 

            </div>
        )
    }
}

export default BottomBar_VR