import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
} from "react"
import UserMenu from "./header/UserMenu"
import EventHeader from './header/EventHeader'
import "../../../assets/css/evnt_cards.css"
import { SearchBarToggle } from "../../../redux/common/actions"
import { useDispatch, useSelector } from "react-redux"
import { MENUITEMS } from "./sidebar/menu"
import { Link } from "react-router-dom"
import HeaderLogo from "./header/HeaderLogo"
import TalentHeader from "./header/TalentHeader"
import CollapsedStories from '../../../components/users/collapsedStories'
import Search from './header/Search'

export const HeaderBar = (props) => {
  const configDB = useSelector((content) => content.Customizer.customizer);
  const platformLogo = useSelector(
    (state) => state.PlatformCommon.platformLogo
  );
  const sidebar_background_color = configDB.settings.sidebar_background_setting;
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [searchValue, setsearchValue] = useState("");
  // eslint-disable-next-line
  const [searchResult, setSearchResult] = useState(false);
  // eslint-disable-next-line
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);

  const dispatch = useDispatch();
  const searchTog = useSelector((state) => state.Common.searchToggle);
  const mobileRightTog = useSelector((state) => state.Common.mobileRightToggle);
  const switchToggle = useSelector((state) => state.Common.switchToggle);
  const mainType = useSelector((state) => state.Common.mainType);
  const windowWidth = props.windowWidth;

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchValue("");
    }
  }, []);

  useEffect(() => {
    if (windowWidth <= 991) {
      document.querySelector(".page-main-header").className =
        "page-main-header open";
      //document.querySelector(".page-sidebar").className = 'page-sidebar open'
      // if (localStorage.getItem("layout_version") === 'dark-only') {
      //     document.querySelector(".header-logo").className = 'header-logo light';
      // }
      // else {
      //     document.querySelector(".header-logo").className = 'header-logo normal';
      // }
    } else {
      document.querySelector(".page-main-header").className =
        "page-main-header open";
      //document.querySelector(".page-sidebar").className = 'page-sidebar ' + sidebar_background_color
    }
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction, windowWidth, sidebar_background_color]);

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    setsearchValue(keyword);
    mainmenu.filter((menuItems) => {
      if (
        menuItems.title.toLowerCase().includes(keyword) &&
        menuItems.type === "link"
      ) {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      menuItems.children.filter((subItems) => {
        if (
          subItems.title.toLowerCase().includes(keyword) &&
          subItems.type === "link"
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.children) return false;
        subItems.children.filter((suSubItems) => {
          if (suSubItems.title.toLowerCase().includes(keyword)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
          return suSubItems;
        });
        return subItems;
      });
      checkSearchResultEmpty(items);
      setsearchValue(items);
      return menuItems;
    });
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchResultEmpty(true);
      document.querySelector(".empty-menu").classList.add("is-open");
    } else {
      setSearchResultEmpty(false);
      document.querySelector(".empty-menu").classList.remove("is-open");
    }
  };

  const addFix = () => {
    setSearchResult(true);
    document.querySelector(".Typeahead-menu").classList.add("is-open");
  };

  const removeFix = () => {
    setSearchResult(false);
    setsearchValue("");
    document.querySelector(".Typeahead-menu").classList.remove("is-open");
  };


  const vrMode = useSelector((state) => state.EventCommon.eventType);
  const eventType = useSelector((state) => state.EventCommon.eventData.type);
  if (!vrMode) {
    return (
      <div className={`page-main-header ${switchToggle ? "open" : ""}`} >
        <div className="main-header-right">

          <HeaderLogo />
          <EventHeader type={eventType}/>
          
          <TalentHeader checkIfIsOpen={props.checkIfIsOpen} type={eventType}/>

          <CollapsedStories checkIfIsOpen={props.checkIfIsOpen} />
          {/* <Search/> */}
          {/* <Leftbar /> */}
          <UserMenu
            eventTypes={props.eventTypes}
            currentShow={props.currentShow}
            checkIfIsOpen={props.checkIfIsOpen}
          />
        </div>
      </div>
    );
  }
};

export default HeaderBar;
