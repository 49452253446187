import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../../../assets/css/evnt_cards.css";

export const HeaderLogo = (props) => {
  const platformLogo = useSelector(
    (state) => state.PlatformCommon.platformLogo
  );
  return (
    <a href={platformLogo.redirect} target="_blank">
      <div className="p-r-20 logo-block">
        <img
          style={{width:"120px"}}
          className="log-in-logo"
          src={platformLogo.main}
          alt="Platform Logo"
        />
        <p className="pull-right text-color" style={{fontSize: "9px" }}>
          <b>{"Powered by EVNT"}</b>
        </p>
      </div>
    </a>
  );
};
export default HeaderLogo;
