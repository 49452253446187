import React, { Fragment, useEffect, useState } from "react";
import { Table, Row, Col, Media } from "reactstrap";
import ScrollArea from "react-scrollbar";

import axios from "axios";
import { ChangePerspective } from "../../../../redux/common/EventActions";
import { apexRadarPolygonfillCharts } from "../../../../components/charts/ApexCharts/ApexData"
import { LoadingAsset } from "../WindowLoaders";
import { useParams } from "react-router";
import Chart from 'react-apexcharts'

export const LeagueTable = (props) => {
  const [isLoading, setLoading] = useState(true);
  const { platformID, eventID } = useParams();
  const [leageTeams, setLeageTeams] = useState([]);
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";

  useEffect(() => {
    setLoading(true);
    const params = {
      platformID: platformID,
      eventID: eventID,
    };
    axios
      .get(`${ApiURL}/api/platform/event/league/teams/`, {
        params: params,
      })
      .then((res) => {
        setLeageTeamsData(res.data).then(() => {
          setLoading(false);
        });
      })
      .catch((error) => console.error(error));
  }, []);

  const setLeageTeamsData = (teams) => {
    return new Promise((resolve, reject) => {
      //ORDER TEAMS BY POSITION IN ORDER TO DISPLAYTHEM BY POSITION IN THE TABLE
      setLeageTeams(teams);
      resolve(true);
    });
  };

  if (isLoading) {
    return <LoadingAsset loading="TeamStatistics.js (LeagueTable)" />;
  }

  return (
    <Fragment>
      <div className="table-responsive">
        <ScrollArea
          className="evnt-statistics-table"
          speed={0.5}
          horizontal={true}
          vertical={true}
          style={{ height: props.listHeight }}
        >
          <Table>
            <thead>
              <tr>
                <th scope="col">{"#"}</th>
                <th scope="col">{"Team"}</th>
                <th scope="col">{"P"}</th>
                <th scope="col">{"Goals"}</th>
                <th scope="col">{"GD"}</th>
                <th scope="col">{"Pts."}</th>
              </tr>
            </thead>
            <tbody>
              {leageTeams.map((team, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <div>
                        <img src={team.thumbnail} width="20px"></img>
                        &nbsp;<b>{team.name}</b>
                      </div>
                    </td>
                    <td>{team.metrics.p}</td>
                    <td>{team.metrics.goals}</td>
                    <td>{team.metrics.gd}</td>
                    <td>{team.metrics.pts}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ScrollArea>
      </div>
    </Fragment>
  );
};

export const StatisticsTable = (props) => {
  const componentWidth = props.width || "380px";
  const [metrics, setMetrics] = useState(props.metrics);
  useEffect(() => {
    setMetrics(props.metrics);
  }, [props]);

  return (
    <Fragment>
      <ScrollArea
        className="evnt-statistics-table"
        speed={0.5}
        horizontal={true}
        vertical={true}
        style={{ height: props.listHeight }}
      >
        <Row style={{ width: componentWidth }}>
          <Col lg="12">
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th scope="col">{"Distribution"}</th>
                    <th scope="col">{"Metric"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{"Total Passes"}</td>
                    <td>{metrics.distribution.totalPases}</td>
                  </tr>
                  <tr>
                    <td>{"Pass accuracy"}</td>
                    <td>{`${metrics.distribution.passAcurracy}%`}</td>
                  </tr>
                  <tr>
                    <td>{"Total crosses"}</td>
                    <td>{metrics.distribution.TotalCrosses}</td>
                  </tr>
                  <tr>
                    <td>{"Cross accuracy"}</td>
                    <td>{`${metrics.distribution.crossAcuracy}%`}</td>
                  </tr>
                  <tr>
                    <td>{"Possession"}</td>
                    <td>{`${metrics.distribution.possesion}%`}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg="12">
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th scope="col">{"Attack"}</th>
                    <th scope="col">{"Metric"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{"Goals"}</td>
                    <td>{metrics.attack.goals}</td>
                  </tr>
                  <tr>
                    <td>{"Goals - inside the box"}</td>
                    <td>{metrics.attack.goalsInsideTheBox}</td>
                  </tr>
                  <tr>
                    <td>{"Goals - outside the box"}</td>
                    <td>{metrics.attack.goalsOutsideTheBox}</td>
                  </tr>
                  <tr>
                    <td>{"Goals - set pieces"}</td>
                    <td>{metrics.attack.goalsSetPieces}</td>
                  </tr>
                  <tr>
                    <td>{"Headed goals"}</td>
                    <td>{metrics.attack.headedGoals}</td>
                  </tr>
                  <tr>
                    <td>{"Goals / match"}</td>
                    <td>{metrics.attack.goalsPerMatch}</td>
                  </tr>
                  <tr>
                    <td>{"Penalties accuracy"}</td>
                    <td>{`${metrics.attack.PenaltiesAccuracy}%`}</td>
                  </tr>
                  <tr>
                    <td>{"Total shots"}</td>
                    <td>{metrics.attack.totalShots}</td>
                  </tr>
                  <tr>
                    <td>{"Shot accuracy"}</td>
                    <td>{`${metrics.attack.shotAccuracy}%`}</td>
                  </tr>
                  <tr>
                    <td>{"Offsides"}</td>
                    <td>{metrics.attack.offSides}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg="12">
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th scope="col">{"Dicipline"}</th>
                    <th scope="col">{"Metric"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{"Yellow cards"}</td>
                    <td>{metrics.dicipline.yellowCards}</td>
                  </tr>
                  <tr>
                    <td>{"Red Cards"}</td>
                    <td>{metrics.dicipline.redCards}</td>
                  </tr>
                  <tr>
                    <td>{"Fouls concedes"}</td>
                    <td>{metrics.dicipline.fouldConcedes}</td>
                  </tr>
                  <tr>
                    <td>{"Fouls won"}</td>
                    <td>{metrics.dicipline.foulsWon}</td>
                  </tr>
                  <tr>
                    <td>{"Penalties conceded"}</td>
                    <td>{metrics.dicipline.penaltiesConceded}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg="12">
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th scope="col">{"Defence"}</th>
                    <th scope="col">{"Metric"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{"Goals conceded"}</td>
                    <td>{metrics.defence.goalsConceded}</td>
                  </tr>
                  <tr>
                    <td>{"Goals conceded/match"}</td>
                    <td>{metrics.defence.goalsConcededPerMatch}</td>
                  </tr>
                  <tr>
                    <td>{"Clean Sheets"}</td>
                    <td>{metrics.defence.cleanSheets}</td>
                  </tr>
                  <tr>
                    <td>{"Tackles"}</td>
                    <td>{metrics.defence.tackles}</td>
                  </tr>
                  <tr>
                    <td>{"Duels"}</td>
                    <td>{metrics.defence.duels}</td>
                  </tr>
                  <tr>
                    <td>{"Clearances"}</td>
                    <td>{metrics.defence.clearances}</td>
                  </tr>
                  <tr>
                    <td>{"Blocked Shots"}</td>
                    <td>{metrics.defence.blockedShots}</td>
                  </tr>
                  <tr>
                    <td>{"Interceptions"}</td>
                    <td>{metrics.defence.interceptions}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg="12">
            <div id="radarchart">
              <Chart
                options={apexRadarPolygonfillCharts.options}
                series={apexRadarPolygonfillCharts.series}
                type="radar"
                height={280}
              />
            </div>
          </Col>
        </Row>
      </ScrollArea>
    </Fragment>
  );
};
