import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Badge, CardFooter, Button } from 'reactstrap'
import { Fragment } from 'react'
import { Media } from 'reactstrap'
import CloseCard from './CloseWindow'
import { CarouselStories } from './components/CarouselComponents'
import ImageUploader from 'react-images-upload'
import { UploadStory } from '../../../../constant'

export const UserStories = (props) => {
  const [image,setimage] = useState({ pictures: [] })
  const onDrop = (pictureFiles, pictureDataURLs) => {
      setimage({...image,pictureFiles
      });
  }
    return(
        <Fragment>
            <Card style={{margin:"15px 30px", height:"500px",width:"300px", pointerEvents:"all"}}>
                <div onClick={()=> props.checkIfIsOpen({name:props.windowName})}><CloseCard/></div>
                <CardHeader>
                  <div className="avatars">
                    <div className="avatar" style={{cursor:"pointer"}} onClick={()=> props.checkIfIsOpen({name:props.windowName})} >
                      <Media body className="img-50 rounded-circle"  src={require('../../../../assets/images/user-card/8.jpg')} alt="#"/>
                      <Badge className="status" pill><i className="fa fa-plus"></i></Badge>
                      {/* <span style={{right:"0px"}} className=" status notification badge badge-pill badge-info"><i className="fa fa-plus"></i></span> */}
                    </div>
                    <div className="avatar" style={{cursor:"pointer"}}><Media body className="img-50 rounded-circle"  src={require('../../../../assets/images/user/2.jpg')} alt="#"/>
                      <div className="status status-50"> </div>
                    </div>
                    <div className="avatar" style={{cursor:"pointer"}}><Media body className="img-50 rounded-circle"  src={require('../../../../assets/images/user/3.jpg')} alt="#"/>
                      <div className="status status-50"> </div>
                    </div>
                    <div className="avatar" style={{cursor:"pointer"}}><Media body className="img-50 rounded-circle"  src={require('../../../../assets/images/user/4.jpg')} alt="#"/>
                      <div className="status status-50"> </div>
                    </div>
                  </div> 
                </CardHeader>
                <CardBody style={{color:"white"}}>
                  <div style={{marginLeft:"auto",marginRight:"auto"}} >
                    {/* <video style={{width:"180px",display:"block",margin:"0 auto"}} controls autostart autoPlay src={require(`../../../../assets/video/Stadium/Stories/stadium_story01.mp4`)} type="video/mp4" /> */}
                    <CarouselStories/>
                    <Badge size="lg" style={{cursor:"pointer",marginLeft:"65px"}} color="primary" pill><i class="fa fa-camera fa-lg"></i>&nbsp;{UploadStory}</Badge>
                  </div>
                </CardBody>
                {/* <CardFooter>
                  <ImageUploader
                      withIcon={false}
                      withPreview={true}
                      label=""
                      singleImage={true}
                      buttonText="Upload Story"
                      onChange={onDrop}
                      imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                      maxFileSize={1048576}
                      fileSizeError=" file size is too big"
                  />
                </CardFooter> */}
            </Card>
        </Fragment>
    )
}

export default UserStories