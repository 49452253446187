import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Row, Col, Media, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import LabeledInput from "../../../../../components/general/LabeledInput";
import LabeledDropDown from "../../../../../components/general/LabeledDropDown";
import {
  LocationName,
  MediaLink,
  MediaInputOptions,
  Save,
  Cancel,
  LocationDescription,
  FacebookLink,
  InstagramLink,
  TwitterLink,
  LinkedinLink,
  EventGraphic,
  GraphicSoruce,
  EventCountry,
  EventState,
  EventCity,
  FromDate,
  Today,
  ToDate,
  ShowEventCounters,
  MainLocationType,
  PlatformTypes,
  LocationPricePasswordTerms,
  LocationPrice,
  Currency,
  CurrencyList,
  LocationStatus,
  CatalogAccess,
  Administrators,
  GuestsList,
  Sponsors,
  LocationTypeOptions,
  LocationType,
  GeneralInformation,
  LocationCreatedSuccessfully,
  LocationCategory,
} from "../../../../../constant";
import { CreateDocument } from "../../../../../redux/Administrator/Actions";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
const LocationDataForm = (props) => {
  const [selectedOption, setDropdownOption] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const createDocument = useSelector(
    (state) => state.AdminState.createDocument
  );
  const { platformID, eventID } = useParams();

  const [locationPreview, setLocationPreview] = useState("");
  const [locationPrice, setLocationPrice] = useState("");
  const [locationCurrency, setLocationCurrency] = useState("");
  const [locationDescription, setLocationDescription] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationCategory, setLocationCategory] = useState("");
  const [locationType, setLocationType] = useState("");
  const [locationStatus, setLocationStatus] = useState("");
  const [locationToDate, setLocationToDate] = useState("");
  const [locationFromDate, setLocationFromDate] = useState("");

  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (createDocument) {
      dispatch(CreateDocument(false));
      axios
        .post(`${ApiURL}/api/admin/controlPanel/platform/event/location`, {
          userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
          platformID:platformID,
          eventID:eventID,
          location: {
            locationData: {
              category: locationCategory,
              description: locationDescription,
              fromDate: locationFromDate,
              name: locationName,
              preview: {
                thumbnail: locationPreview,
                video: "",
              },
              price: {
                amount: locationPrice,
                currency:locationCurrency
              },
              status: locationStatus,
              type: locationType,
              toDate: locationToDate,
            },
          },
        })
        .then((res) => {
          toast.success(LocationCreatedSuccessfully, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setTimeout(
            history.push(
              `${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Location/${res.data}/Form`
            ),
            5000
          );
        })
        .catch((error) => {
          console.error(error);
          const errorDetails = error.response.data.error.details;
          errorDetails.map((error) => {
            toast.error(`${error.context.key} ${error.type}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        });
    }
  }, [createDocument]);

  useEffect(() => {
    if (props.location != undefined) {
      const locationData = props.location.locationData;
      setLocationPreview(locationData.preview);
      setLocationPrice(locationData.price);
      setLocationDescription(locationData.description);
      setLocationName(locationData.name);
      setLocationCategory(locationData.category);
      setLocationType(locationData.type);
      setLocationStatus(locationData.status);
      setLocationToDate(locationData.toDate);
      setLocationFromDate(locationData.fromDate);
    }
  }, []);
  return (
    <Fragment>
      <h1>{GeneralInformation}</h1>
      <hr />
      <Row>
        <Col lg="6">
          <Row>
            <Col lg="8">
              <LabeledInput
                label={LocationName}
                placeholder={LocationName}
                type={"text"}
                onChange={(event)=>setLocationName(event.target.value)}
                value={locationName}
              />
            </Col>
            <Col lg="4">
              <LabeledInput
                label={LocationCategory}
                placeholder={LocationCategory}
                type={"text"}
                onChange={(event)=>setLocationCategory(event.target.value)}
                value={locationCategory}
              />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={FromDate}
                placeholder={FromDate}
                type={"datetime-local"}
                onChange={(event)=>setLocationFromDate(event.target.value)}
                value={locationFromDate} />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={ToDate}
                placeholder={ToDate}
                type={"datetime-local"}
                onChange={(event)=>setLocationToDate(event.target.value)}
                value={locationToDate} />
            </Col>
            <Col lg="12">
              <LabeledInput
                label={LocationType}
                placeholder={LocationType}
                type={"text"}
                onChange={(event)=>setLocationType(event.target.value)}
                value={locationType}
              />
            </Col>
            <Col lg="12">
              <LabeledInput
                label={LocationDescription}
                placeholder={LocationDescription}
                type={"textarea"}
                rows={4}
                onChange={(event)=>setLocationDescription(event.target.value)}
                value={locationDescription}
              />
              <br />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={LocationPrice}
                placeholder={LocationPrice}
                type={"number"}
                onChange={(event)=>setLocationPrice(event.target.value)}
                value={locationPrice}
              />
            </Col>
            <Col lg="6">
              <LabeledDropDown
                label={Currency}
                options={CurrencyList}
                setSelectedOption={(option) => setLocationCurrency(option)}
              />
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <Col lg="6">
              <Row>
                <Col lg="12">
                  <LabeledInput
                    label={LocationStatus}
                    placeholder={LocationStatus}
                    type={"text"}
                    onChange={(event)=>setLocationStatus(event.target.value)}
                    value={locationStatus}
                  />
                </Col>
              </Row>
            </Col>

            <Col lg="6">
              {selectedOption != "Image" ? (
                <LabeledInput
                  label={MediaLink}
                  placeholder={MediaLink}
                  type={"file"}
                  value={""}
                />
              ) : (
                <LabeledInput
                  label={MediaLink}
                  placeholder={MediaLink}
                  type={"text"}
                  value={""}
                  value={selectedOption}
                />
              )}
            </Col>

            <Col lg="12">
              <Media
                style={{ width: "100%" }}
                className="img-fluid m-r-20 p-2"
                src={locationPreview.thumbnail}
                alt="Event Main Grafic"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default LocationDataForm;
