// Dashboard
export const Dashboard =  "Dashboard"
export const Widgets =  "Widgets"
export const General = "General"
export const Inbox =  "Inbox"
export const SalesOverview =  "Sales Overview"
export const Hours =  "Hours"
export const Day =  "Day"
export const Week =  "Week"
export const Month =  "Month"
export const FromDate =  "From Date"
export const ToDate =  "To Date"
export const Profit =  "Profit"
export const SalesByCategory =  "Sales By Category"
export const ViewMoreReports =  "View More Reports"
export const TotalInvest =  "Total Invest"
export const Live =  "Live"
export const OurBestSeller =  "Our Best Seller"
export const WorkPlan =  "Work Plan"
export const TotalNewUser =  "Total New User"
export const BounceDate =  "Bounce Date"
export const SessionDuartion =  "Session duartion"
export const Session =  "Session"
export const GetStarted =  "Get Started"
export const OurGrowth =  "Our Growth"
export const NewPatients =  "New Patients"
export const OPDPatients =  "OPD Patients"
export const Operations =  "Operations"
export const Visitors =  "Visitors"
export const Medicine =  "Medicine"
export const PatientIn =  "patient in"
export const HospitalFinances =  "Hospital Finances"
export const Expensens =  "Expensens"
export const DoctorList =  "Doctor list"
export const Specialist =  "Specialist"
export const SpecialistList =  "Specialist List"
export const Message =  "Message"
export const HospitalActivities =  "Hospital Activities"
export const BookedAppointment =  "Booked Appointment"
export const AdmitPatientList =  "Admit patient list"
export const OverView =  "Over View"
export const TotalDeals =  "Total Deals"
export const TodayEarning =  "Today Earning"
export const UsersChart =  "Users Chart"
export const MonthlyRevenueProgress =  "Monthly Revenue Progress"
export const HighestValue =  "Highest Value"
export const TotalProfit =  "Total Profit"
export const TotalProject =  "Total Project"
export const SeptemberTotal =  "September Total"
export const ViewFullChart =  "View Full Chart"
export const TotalUsers =  "Total Users"
export const Profile =  "Profile"
export const DoneProject =  "Done Project"
export const OurClient =  "Our Client"
export const ProjectIncome =  "Project Income"
export const TotalIncome =  "Total Income"
export const NewProject =  "New Project"
export const TotalNewProject =  "Total New Project"
export const QuickMail =  "Quick Mail"
export const Send =  "Send"
export const YourAccount =  "Your Account"
export const InvoicesAndPayments =  "Invoices & Payments"
export const Setting =  "Settings"
export const YourServices =  "Your Services"
export const LogOut =  "Log Out"
export const Admin =  "Admin"
export const MyTask =  "My task"
export const Chat =  "Chat"
export const RecentActivities =  "Recent Activities"
export const YourCurrentBalance =  "Your Current Balance"
export const Withdraw =  "Withdraw"
export const CardNumber =  "Card Number"
export const Amount =  "Amount"
export const ExpireDate =  "Expire Date"
export const OurDistributed =  "Our Distributed"
export const OurContributed =  "Our Contributed"
export const BitcoinBTC =  "Bitcoin BTC"
export const LitecoinLTC =  "Litecoin LTC"
export const EthereumETH =  "Ethereum ETH"
export const BitcoinExchange =  "Bitcoin Exchange ETH/BTC"
export const Value =  "Value"
export const DatatableTableMarkets =  "Datatable Table Markets"
export const BuyBitcoin =  "Buy Bitcoin"
export const SellBitcoin =  "Sell Bitcoin"
export const StopLimit =  "Stop Limit"
export const MarketChart =  "Market Chart"
export const SellOrders =  "Sell Orders"
export const BuyOrders =  "Buy Orders"


// Widgets
export const Earnings =  "Earnings"
export const Products =  "Products"
export const Messages =  "Messages"
export const NewUser =  "New User"

export const New =  "New"
export const Pending =  "Pending"
export const Done =  "Done"
export const Doing =  "Doing"
export const Smooth =  "Smooth"
export const Running =  "Running"
export const Cancel =  "Cancel"

export const kolkata_India =  "kolkata, India"
export const India_Surat =  "India, Surat"
export const RecentActivity =  "Recent Activity"
export const Loss =  "Loss"
export const MarkJecno =  "Mark Jecno"
export const Manager =  "Manager"
export const TotalPost =  "Total Post"
export const NewOrder =  "New Order"

export const Daily =  "Daily"
export const Weekly =  "Weekly"
export const Yearly =  "Yearly"
export const Monthly =  "Monthly"
export const Hot =  "Hot"
export const Date = "Date"
export const Time =  "Time"
export const Sale =  "Sale"
export const Year =  "Year"
export const Today =  "Today"
export const JohnLoren =  "John Loren"
export const VenterLoren =  "Venter Loren"
export const Tomorrow =  "Tomorrow"
export const Yesterday =  "Yesterday"
export const RightNow =  "Right Now"

export const ProductsCart =  "Products Cart"
export const Employee_Status =  "Employee Status"
export const Post =  "Post"
export const Like =  "Like"

export const ContactUs =  "Contact Us"
export const Contact =  "Contact"
export const YourName =  "Your Name"
export const Email =  "Email"
export const SEND_IT =  "SEND IT"

export const SALE =  "SALE"
export const PROJECTS =  "PROJECTS"
export const PRODUCTS =  "PRODUCTS"

export const MarketinExpenses =  "Marketin Expenses"
export const TotalSales =  "Total sales"
export const TotalPurchase =  "Total purchase"
export const TotalEarning =  "Total Earning"
export const SkillStatus =  "Skill Status"
export const OrderStatus =  "Order Status"

export const LiveProducts =  "Live Products"
export const Turnover =  "Turnover"
export const MonthlySales =  "Monthly Sales"

export const Uses =  "Uses"
export const BrowserUses =  "Browser Uses"
export const Finance =  "Finance"

// Base 
export const  DefaultColor  = "Default Color"
export const  Color  = "Color"
export const  Primary  = "Primary"
export const  Secondary  = "Secondary"
export const  Success  = "Success"
export const  Info  = "Info"
export const  Warning  = "Warning"
export const  Information  = "Information"
export const  Danger  = "Danger"
export const  Pink  = "Pink"
export const  Grey  = "Grey"
export const  Dark  = "Dark"
export const  Light  = "Light"

export const  Headings  = "Headings"
export const  SubHeading  = "Sub Heading"
export const  Heading1  = "Heading 1"
export const  Heading2  = "Heading 2"
export const  Heading3  = "Heading 3"
export const  Heading4  = "Heading 4"
export const  Heading5  = "Heading 5"
export const  Heading6  = "Heading 6"
export const  VeryThin100  = "Very Thin (100)"
export const  Thin300  = "Thin (300)"
export const  Normal400  = "Normal (400)"
export const  LightNormal600 = "light Normal (600)"
export const  Bold700 = "Bold (700)"
export const  ExtraBold900 = "Extra Bold (900)"
export const  DisplayHeadings = "Display headings"
export const  TextColor = "Text Color"
export const  ListingTypography = "Listing Typography"
export const  UnorderList = "Unorder list"
export const  OrderList = "Order list"
export const  FontawesomeList = "Fontawesome list"
export const  ICOIconList = "ICO Icon list"
export const  ThemfyList = "Themfy list"
export const  Blockquotes = "Blockquotes"
export const  SourceTitle = "Source Title"

export const  Sizing = "Sizing"
export const  StatusIndicator = "Status Indicator"
export const  Initials = "Initials"
export const  Shape = "Shape"
export const  Ratio = "Ratio"

export const  Padding = "Padding"
export const  Margins = "Margins"
export const  PaddingLeft = "Padding Left"
export const  PaddingRight = "Padding Right"
export const  PaddingTop = "Padding Top"
export const  PaddingBottom = "Padding Bottom"
export const  MarginLeft = "Margin Left"
export const  MarginRight = "Margin Right"
export const  MarginTop = "Margin Top"
export const  MarginBottom = "Margin Bottom"
export const  VerticalAlign = "Vertical Align"
export const  ImageSizes = "Image Sizes"
export const  BadgeColor = "Badge Color"
export const  BackgroundColor = "Background Color"
export const  ButtonColor = "Button Color"
export const  BorderRadius = "Border Radius"
export const  FontWeight = "Font Weight"
export const  FontStyle = "Font Style"
export const  Float = "Float"
export const  Overflow = "Overflow"
export const  FontSize = "Font Size"
export const  AllBordersColor = "All Borders Color"
export const  BorderWidth = "Border Width"
export const  ButtonOutline = "Button Outline"

export const  GridOptions = "Grid Options"
export const  ExtraSmall = "Extra small"
export const  Large = "Large"
export const  Small =  "Small"
export const  Medium =  "Medium"
export const  ExtraLarge = "Extra large"
export const  GridBehavior = "Grid behavior"
export const  GutterWidth = "Gutter width"
export const  Nestable = "Nestable"
export const  Offsets = "Offsets"
export const  ColumnOrdering = "Column ordering"
export const  GridColumn = "Grid Column"
export const  VerticalAlignment = "Vertical alignment"
export const  HorizontalAlignment = "Horizontal alignment"
export const  NestingColumn = "Nesting Column"
export const  Order = "Order"

export const  ContextualVariations = "Contextual variations"
export const  TagsWithNumber = "Tags with number"
export const  PillsWithNumber = "Pills with number"
export const  TagsWithIcon = "Tags With icon"
export const  PillsWithIcon = "Pills with Icon"
export const  BadgesExample = "Badges Example"
export const  Notification =  "notification"

export const  BasicProgressBars = "Basic Progress Bars"
export const  SmallProgressBars = "Small Progress Bars"
export const  LargeProgressBars = "Large Progress Bars"
export const  CustomHeightProgressBars = "Custom Height Progress Bars"
export const  ProgressBarsStates = "Progress Bars states"
export const  MultipleBars = "Multiple bars"
export const  ProgressBarsStriped = "Progress Bars Striped"
export const  ProgressBarsAnimated = "Progress Bars animated"

export const  StaticExample = "Static Example"
export const  SendMessage = "Send Message"
export const  NewMessage  = "New Message"
export const  Close = "Close"
export const  BasicModal = "Basic Modal"
export const  Simple = "Simple"
export const  ModalTitle = "Modal title"
export const  SaveChanges = "Save changes"
export const  ScrollingLongContent = "Scrolling long content"
export const  VerticallyCentered = "Vertically centered"
export const  TooltipsAndPopovers = "Tooltips and popovers"
export const  UsingTheGrid = "Using the grid"
export const  VaryingModalContent = "Varying modal content"
export const  SizesModal = "Sizes modal"
export const  LargeModal = "Large modal"
export const  SmallModal = "Small modal"

export const  AlertWithIcon = "Alert With Icon"
export const  LightAlerts = "Light Alerts"
export const  LinkColor = "Link color"
export const  Dismissing = "Dismissing"
export const  OutlineAlerts = "Outline Alerts"
export const  OutlineDarkAlerts = "Outline Dark Alerts"
export const  AlertWithIconOutline = "Alert With Icon outline"
export const  AlertWithIconInverse = "Alert With Icon inverse"
export const  AdditionalContent = "Additional content"
export const  TextAsAction = "Text as action"

export const  BasicExamples = "Basic Examples"
export const  Direction = "Direction"
export const  HTMLContent = "HTML Content"
export const  OffsetToTooltip = "Offset to Tooltip"

export const  WithTitleAlert  = "With Title alert"
export const  SuccessAlert  = "Success alert"
export const  InfoAlert  = "Info alert"
export const  WarningAlert  = "Info alert"
export const  Basic  = "Basic"

export const  BasicTooltip = "Basic Tooltip"
export const  HoverMe = "Hover Me"
export const  PopoverTitle = "Popover title"
export const  HTMLElements = "HTML elements"

export const  LoaderStyle = "Loader Style"

export const  BasicDropdown = "Basic Dropdown"
export const  DropdownButton = "Dropdown Button"
export const  AnotherAction = "Another Action"
export const  AnotherLink = "Another Link"
export const  SomethingElseHere = "Something Else Here"
export const  SeparatedLink = "Separated link"
export const  BasicColorDropdown = "Basic Color Dropdown"
export const  DropdownSplitButton = "Dropdown Split Button"
export const  RoundedDropdown = "Rounded Dropdown"
export const  DropdownWithDivider = "Dropdown With Divider"
export const  DropdownWithHeader = "Dropdown With Header"
export const  DropdownWithDisable = "Dropdown With Disable"
export const  DropdownWithDropUp = "Dropdown With DropUp"
export const  Normal = "Normal"
export const  Disabled = "Disabled"
export const  Active =  "Active"
export const  DropdownHeader = "Dropdown Header"

export const  BasicAccordion = "Basic Accordion"
export const  CollapsibleGroupItem = "Collapsible Group Item #"
export const  AllCloseAccordion = "All Close Accordion"
export const  ColorAccordion = "Color Accordion"
export const  AccordionWithIconOnTitle = "Accordion With Icon on Title"
export const  AccordionOpenCloseIcon = "Accordion open close icon"

export const  BasicTabs = "Basic Tabs"
export const  TabsBottomLeftAlign = "Tabs bottom left Align"
export const  TabsWithIcon = "Tabs With Icon"
export const  TabsRightAlign = "Tabs Right Align"
export const  TabsVertical = "Tabs Vertical"
export const  PillTabs = "Pill Tabs"
export const  PillTabsWithIcon = "Pill Tabs With Icon"
export const  PillDarkColor = "Pill Dark color"
export const  PrimaryColor = "Primary Color"
export const  SecondaryColor = "Secondary Color"
export const  SuccessColor = "Success Color"
export const  InfoColor = "Info Color"
export const  WarningColor = "Warning Color"
export const  DangerColor = "Danger Color"

export const  SimpleMaterialTab = "Simple Material style tab"
export const  MaterialBottomTab = "Material style bottom tab"
export const  MaterialLeftTab = "Material style left tab"
export const  MaterialRightTab = "Material style right tab"
export const  MaterialColorTab = "Material color tab"

export const  DefaultNav = "Default Nav"
export const  Base = "Base"
export const  Forms = "Forms"
export const  DropdownExample = "Dropdown example"

export const  ItemBullets = "Item Bullets"
export const  SectionAndSeparator = "Section and Separator"
export const  Layouts = "Layout"
export const  Sidebars = "Sidebar"
export const  Maps = "Maps"
export const  Editors = "Editors"
export const  TreeView = "TreeView"
export const  Rating = "Rating"
export const  Scrollable = "Scrollable"
export const  Advance = "Advance"
export const  Timeline = "Timeline"
export const  Cards = "Cards"
export const  PageLayout = "Page Layout"
export const  Footers = "Footers"
export const  LogOuts = "Log Out"
export const  ActiveAndDisabledLinks = "Active and Disabled Links"
export const  InlineNav = "Inline Nav"
export const  SubNav = "Sub Nav"
export const  StaticSubNav = "Static Sub Nav"
export const  ToggleSubNav = "Toggle Sub Nav"
export const  Panding = "Panding"
export const  Replied = "Replied"

export const  Examples = "Examples"
export const  LargerShadow = "Larger shadow"
export const  RegularShadow = "Regular shadow"
export const  SmallShadow = "Small shadow"
export const  NoShadow = "No shadow"

export const  DefaultList = "Default List"
export const  LinksAndButtons = "Links and buttons"
export const  FlushStyle = "Flush Style"
export const  ContextualClasses = "Contextual classes"
export const  WithBadges = "With badges"
export const  CustomContent = "Custom content"
export const  JavaScriptBehavior = "JavaScript behavior"
export const  WithIcon = "With Icon"
export const  WithImages = "With Images"
export const  WithImageRounded = "With Image Rounded"

// Advance
export const  VerticalScrollbar = "Vertical Scrollbar"
export const  ClickAndDragScrollbar = "Click and Drag Scrollbar"
export const  SmoothScrollbar = "Smooth Scrollbar"
export const  NoScrollbar = "No Scrollbar"

export const  CustomDirections = "Custom  Directions"
export const  CustomAutoclose = "Custom Autoclose"

export const  StarRating = "Star Rating"
export const  HeartRating = "Heart Rating"
export const  ThumbsUpDownRating = "Thumbs Up/Down Rating"
export const  PillRating = "Pill Rating"

export const  SingleFileUpload = "Single File Upload"
export const  MultiImageUpload = "Multi only Image Upload"
export const  MultipleImageVideoAudioUpload = "Multiple Image Video Audio Upload"
export const  LimitationFileUpload = "Limitation File Upload"
export const  CustomFileUpload = "Custom File Upload"

export const  AdvancedState = "Advanced State"
export const  AlertState = "Alert State"

export const  Carousel = "Carousel"
export const  UncontrolledCarousel = "Uncontrolled Carousel"
export const  CarouselClassname = "Carousel Using a Tag And Classname"

export const  SuccessNotification = "Success Notification"
export const  InfoNotification = "Info Notification"
export const  WarningNotification = "Warning Notification"
export const  DangerNotification = "Danger Notification"
export const  TopCenter = "Top Center"
export const  BottomCenter = "Bottom Center"
export const  BottomLeft = "Bottom Left"
export const  RightBottom = "Right Bottom"
export const  AutoCloseIn15000Sec = "AutoClose in 15000 Sec"
export const  AutoCloseIn5000Sec = "AutoClose in 5000 Sec"
export const  AutoCloseIn1000Sec = "AutoClose in 1000 Sec"
export const  AutoCloseIn500Sec = "AutoClose in 500 Sec"

export const  ColoredBreadcrumb = "Colored breadcrumb"
export const  Ribbon = "Ribbon"

export const  Pagi_Nations = "Pagination"
export const  PaginationWithIcons = "Pagination with icons"
export const  PaginationAlignment = "Pagination alignment"
export const  PaginationActiveDisabled = "Pagination with active and disabled"
export const  PaginationColor = "Pagination Color"
export const  PaginationSizing = "Pagination sizing"
export const  Previous = "Previous"
export const  Next = "Next"

export const  Default_Step = "Default Step"
export const  Step_With_Icon = "Step with icon"
export const  Step_States = "Step States"
export const  Step_Sizing = "Step Sizing"
export const  Vertical_Step = "Vertical Step"
export const  Default_Pearls_Steps = "Default Pearls Steps"
export const  Pearls_Steps_Icon = "Pearls Steps with icon"
export const  Pearls_Step_Sizing = "Pearls Step Sizing"
export const  Pearls_Step_States = "Pearls Step States"

export const  DefaultBootstrapBreadcrumb = "Default bootstrap breadcrumb"
export const  NavBreadcrumb = "Nav breadcrumb"
export const  Library = "Library"
export const  Bootstrap = "Bootstrap"

export const  BasicSlider = "Basic Slider"
export const  Default = "Default"
export const  NegativeValues = "Negative Values"
export const  FormatedLabel = "Formated Label"
export const  DraggableRange = "Draggable Range"

export const  ImageCropper = "Image Cropper"
export const  StickyNote = "Sticky Note"
export const  AddNewNote = "Add New Note"

export const  ClickAndDrag = "Click And Drag"
export const  SpillDragNDrop = "Spill Drag N Drop"
export const  CopyDragNDrop = "Copy Drag N Drop"

export const  MultipleImageUpload = "Multiple Image Upload"
export const  SelectSingleImageUpload = "Select Single Image Upload"

export const  BasicCard = "Basic Card"
export const  FlatCard = "Flat Card"
export const  WithoutShadowCard = "Without shadow Card"
export const  IconInHeading = "Icon in Heading"
export const  CardSubTitle = "Card sub Title"
export const  CardWithFooter = "Card With Footer"
export const  Card_Footer = "Card Footer"
export const  PrimaryColorCard = "Primary Color Card"
export const  SecondaryColorCard = "Secondary Color Card"
export const  SuccessColorCard = "Success Color Card"
export const  PrimaryColorHeader = "Primary Color Header"
export const  PrimaryColorBody = "Primary Color Body"
export const  PrimaryColorFooter = "Primary Color Footer"

export const  BorderLeft = "Border left"
export const  BorderRight = "Border right"
export const  BorderTop = "Border top"
export const  BorderBottom = "Border bottom"
export const  BorderColorState = "Border color state"
export const  AbsoluteStyle = "Absolute Style"
export const  ColorState = "Color state"

export const  SimpleTab = "Simple Tab"
export const  ColorTab = "Color Tab"
export const  ColorOption = "Color Option"
export const  MaterialTabWithColor = "Material Tab With Color"
export const  MaterialTabWithIcon  = "Material Tab With Icon "

export const  DragBothTitleCard = "Drag Both Title and card"
export const  DragOnlyCard = "Drag only card"
export const  DragOnlyContent = " Drag only Content"

export const  TimelinePrimaryColor = "Timeline Primary color"
export const  NewSale = "New Sale"
export const  NewReport = "New Report"
export const  NewVisits = "New Visits"
export const  TimelineSecondaryColor = "Timeline secondary color"
export const  TimelineSuccessColor = "Timeline Success color"
export const  TimelineInfoColor = "Timeline Info color"
export const  TimelineWarningColor = "Timeline Warning color"
export const  TimelineDangerColor = "Timeline Danger color"

export const  FlagIcons = "Flag Icons"
export const  Icons = "Icons"
export const  WebApp = "Web App"
export const  Spinner = "Spinner"
export const  FormControl = "Form Control"
export const  TextEditor = "Text Editor"
export const  Directional = "Directional"

export const  VideoPlayer = "Video Player"
export const  BrandPlayer = "Brand Player"
export const  MedicalPlayer = "Medical Player"

export const  Abstract = "Abstract"
export const  Animal = "Animal"
export const  Construction = "Construction"
export const  Device = "Device"
export const  Education = "Education"
export const  Emotion = "Emotion"
export const  FileType = "File Type"
export const  Food = "Food"
export const  KidsToys = "Kids & Toys"
export const  Law = "Law"
export const  Mathematical = "Mathematical"
export const  Medical = "Medical"
export const  MobileUI = "Mobile UI"
export const  Business =  "Business"
export const  Multimedia = "Multimedia"
export const  Payment = "Payment"
export const  Person = "Person"
export const  Social = "Social"
export const  Sport = "Sport"
export const  Transport = "Transport"
export const  Travel = "Travel"
export const  Weather = "Weather"
export const  WebApplication = "Web Application"

export const  ArrowsDirection = "Arrows & Direction"
export const  Control = "Control"

export const  Feather = "Feather"
export const  WhetherIconsWithAnimations = "Whether Icons With Animations"

export const  SimpleLineIcons = "Simple-Line Icons"
export const  MaterialDesignIcons = "Material-Design Icons"
export const  TypiconsIcons = "typicons Icons"
export const  Pe7Icons = "pe7 Icons"
export const  IonicIcons = "ionic Icons"

export const  DefaultButtons = "Default buttons"
export const  BootstrapStateButtons = "Bootstrap state buttons"
export const  PrimaryButton = "Primary Button"
export const  SecondaryButton = "Secondary Button"
export const  SuccessButton = "Success Button"
export const  InfoButton = "Info Button"
export const  WarningButton = "Warning Button"
export const  DangerButton = "Danger Button"
export const  LightButton = "Light Button"
export const  LargeButtons = "Large buttons"
export const  SmallButtons = "Small buttons"
export const  ExtraSmallButtons = "Extra Small buttons"
export const  ActiveButtons = "Active Buttons"
export const  DisabledButtons = "Disabled buttons"
export const  CustomStateButtons = "Custom state buttons"
export const  OutlineButtons = "outline buttons"
export const  BoldBorderOutlineButtons = "bold Border outline buttons"
export const  OutlineLargeButtons = "outline Large buttons"
export const  OutlineSmallButtons = "outline small buttons"
export const  OutlineExtraSmallButtons = "Outline extra small buttons"
export const  DisabledOutlineButtons = "Disabled outline buttons"
export const  GradienButtons = "Gradien buttons"

export const  BasicButtonGroup = "Basic button group"
export const  EdgesButtonGroup = "Edges button group"
export const  FlatButtonGroup = "Flat button group"
export const  LargeButtonGroup = "large Button group"
export const  LargeEdgesButtonGroup = "large Edges Button group"
export const  OutlineCustomButtonGroup = "Outline Custom button group"
export const  OutlineButtonGroup = "Outline button group"
export const  OutlineEdgesButton = "Outline Edges button"
export const  OutlineFlatButton = "Outline flat button"
export const  RadioButtonGroup = "Radio button group"
export const  CheckBoxButtonGroup = "Check Box button group"
export const  Nesting = "Nesting"
export const  Vertical = "Vertical"
export const  Left = "Left"
export const  Middle = "Middle"
export const  Right = "Right"

export const  BasicAreaChart = "Basic Area Chart"
export const  AreaSpalineChart = "Area Spaline Chart"
export const  BarChart = "Bar chart"
export const  ColumnChart = "Column Chart"
export const  BubbleChart = "3d Bubble Chart"
export const  SteplineChart = "Stepline Chart"
export const  PieChart = "Pie Chart"
export const  DonutChart = "Donut Chart"
export const  MixedChart = "Mixed Chart"
export const  CandlestickChart = "Candlestick Chart"
export const  RadarChart = "Radar Chart"
export const  RadialBarChart = "Radial Bar Chart"

export const  RotatingPieChart = "Rotating A Pie Chart"
export const  SliceVisibilityThreshold = "Slice Visibility Threshold"
export const  StackingAreaChart = "Stacking Area Chart"
export const  AreaChart = "Area Chart"
export const  BasicBarChart = "Basic bar Chart"
export const  MaterialDesign = "Material Design"
export const  GanttChart = "Gantt Chart"
export const  ComboChart = "ComboChart"
export const  BarChart2 = "bar-chart2"
export const  WordTree = "Word tree"
export const  LineChart = "Line Chart"

export const  LineChart2 = "Line Chart-2 Chart"
export const  PolarChart = "Polar Chart"
export const  DoughnutChart = "Doughnut Chart"

export const  AdvancedSMILAnimations = "Advanced SMIL Animations"
export const  SVGPathAnimation = "SVG Path animation"
export const  AnimatingDonutWithSvg = "Animating a Donut with Svg.animate"
export const  BipolarLine = "Bi-polar Line chart with area only"
export const  LineChartWithArea = "Line chart with area"
export const  BipolarBarChart = "Bi-polar bar chart"
export const  StackedBarChart = "Stacked bar chart"
export const  HorizontalBarChart = "Horizontal bar chart"
export const  ExtremeResponsiveConfiguration = "Extreme responsive configuration"
export const  SimpleLineChart = "Simple line chart"
export const  HolesInData = "Holes in data"
export const  FilledHolesInData = "Filled holes in data"

export const  AngleOffset = "Angle Offset"
export const  DisableDisplayInput = "Disable Display Input"
export const  CursorMode = "Cursor Mode"
export const  DisplayPreviousValue = "Display Previous Value"
export const  AngleOffsetArc = "Angle Offset & Arc"

// Gallery
export const  IMAGE_GALLERY = "IMAGE GALLERY"
export const  HoverEffect = "Hover Effect"
export const  MasonryGalleryWithDescription = "Masonry Gallery With Description"
export const  MasonryGallery = "Masonry Gallery"
export const  PortfolioTitle = "Portfolio Title"

// Maps
export const  CurrentLocation = "Current Location"
export const  MarkerMap = "Marker Map"
export const  Polygons = "Polygon"
export const  Polylines = "Polyline"

export const  GeoJsonMapDemo = "GeoJsonMap Demo"
export const  AnimateMap = "Animate Map"
export const  AnimatePanning = "Animate panning"
export const  SetMarkerWithPopup = "Set Marker with Popup"


// Editors
export const  CKEditorExample = "CK Editor Example"
export const  JavascriptMode = "Javascript Mode"
export const  HtmlMode = "Html Mode"
export const  CssMode = "css Mode"
export const  PhpMode = "php Mode"
export const  MDEEditorExample = "MDE Editor Example"

// Calendar
export const BasicCalendars =  "Basic Calendar"
export const Dragging_Event =  "DRAGGING EVENT"
export const Calendar =  "Calendar"


// Ecommerce
export const Filters =  "Filters"
export const ShowingProducts =  "Showing Products 1 - 24 Of 200 Results"
export const Featured =  "Featured"
export const LowestPrices =  "Lowest Prices"
export const HighestPrices =  "Highest Prices"
export const NotFoundData =  "Sorry, We didn't find any results matching this search"
export const ProductDetails =  "Product Details"
export const Quantity =  "Quantity"
export const AddToCart =  "Add to Cart"
export const BuyNow =  "Buy Now"
export const ContinueShopping = "continue shopping"
export const ViewDetails =  "View Details"
export const Stock = "Stock"
export const OutOfStock = "Out Of Stock"
export const ColorCode = "Color Code"
export const Action = "Action"

export const ProductSizeArray = ["M","L","Xl"]

export const ProductName =  "Product Name"
export const ProductReview =  "(250 review)"
export const ProductDescription =  "Product Description"
export const ProductReviews = "Product Reviews"
export const ProductQuestions = "Product Questions"
export const ProductsYouMayLike = "Products you may like"
export const NormallyBoughtTogether = "Normally Bought Together"
export const TopRatedByClients = "Top Rated By Clients"
export const ListPrice = "List Price"
export const DiscountType = "DiscountType"
export const Discount = "Discount"
export const Percentage = "Percentage"
export const ProductBrand = "Product Brand"
export const ProductStock = "Product Stock"
export const Febric =  "Febric"
export const Availability =  "Availability"

export const ProductListTitle =  "Individual column searching (text inputs)"
export const ProductListDesc =  "The searching functionality provided by DataTables is useful for quickly search through the information in the table - however the search is global, and you may wish to present controls that search on specific columns."

export const CreditCard =  "Credit card"
export const DebitCard =  "Debit card"
export const ExpirationDate =  "Expiration Date"
export const EMI =  "EMI"
export const NetBanking =  "Net Banking"

export const SelectMonth = ["Select Month","Jan","Fab","March","April"]
export const SelectYear = ["Select Year","2015","2016","2017","2018","2021"]
export const BankName = ["Bank Name","SBI","ICICI","KOTAK","BOB"]
export const SelectCard = ["Select Card","2","3","4","5"]
export const SelectDuration = ["Select Duration","2015-2016","2016-2017","2017-2018","2018-2021"]

export const OrdersHistory =  "Orders History"
export const OrderHistoryTable =  ["Images","Size","Color","Article number","Sotck Units","Price","Discount"]
export const ShippedOrders =  "Shipped Orders"
export const CancelledOrders =  "Cancelled Orders"

export const SimplePricingCard =  "Simple Pricing Card"
export const PricingTableWithBorder =  "Pricing Table With Border"
export const ColorHighlight =  "Color Highlight"
export const PricingTableWithRibbons =  "Pricing Table With Ribbons"
export const Premium =  "Premium"
export const Extra =  "Extra"
export const Pricings =  "Pricing"
export const Popular =  "Popular"
export const Subscribe =  "Subscribe"
export const LorumIpsum =  "Lorum Ipsum"

export const StandardArray  =  [
    {content1:"50GB",content2:"Disk Space"},
    {content1:"50",content2:"Email Accounts"},
    {content1:"50GB",content2:"Bandwidth"},
    {content1:"10",content2:"Subdomains"},
    {content1:"15",content2:"Domains"},
]

export const Business_Premium_Array  =  [
    {content1:"60GB",content2:"Disk Space"},
    {content1:"60",content2:"Email Accounts"},
    {content1:"60GB",content2:"Bandwidth"},
    {content1:"15",content2:"Subdomains"},
    {content1:"20",content2:"Domains"},
]

export const CartTitle =  "Cart"
export const CartTableHeader = ["Prdouct","Prdouct Name","Price","Quantity","Action","Total"]

export const NewOrders =  "New Orders"
export const TotalPrice =  "Total Price"
export const CheckOut =  "check out"
export const CartEmpty =  "Your Cart is Empty"
export const ExploreShortlistItems =  "Explore more shortlist some items."


export const WishlistTitle =  "Wishlist"
export const WishlistTableHeader = ["Prdouct","Prdouct Name","Price","Availability","Action"]

// Check out
export const BillingDetails = "Billing Details"
export const Phone = "Phone"

export const CountryMenu = ["India","South Africa","United State","Australia"]

export const TownCity = "Town/City"
export const StateCountry = "State/Country"

export const PostalCode = "Postal Code"
export const CheckMeOut = "Check me out"
export const PlaceOrder = "Place Order"

export const Product = "Product"
export const Total = "Total"
export const Subtotal = "Subtotal"

export const Shipping = "Shipping"
export const Option1 = "Option 1"
export const Option2 = "Option 2"
export const CheckPayments = "Check Payments"
export const CashOnDelivery = "Cash On Delivery"
export const PayPal = "PayPal"

// Filter 
export const Colors = "Colors"
export const NewProducts = "New Products"
export const FancyShirt = "Fancy Shirt"
export const FancyShirtPrice = "$100.00"

// Invoice 
export const Creative =  "Creative"
export const HelloCreative =  "hello@Creative.in"

export const InvoiceHash =  "Invoice #"
export const IssuedMay =  "Issued: May"
export const PaymentDue =  "Payment Due: June"
export const JohanDeo =  "Johan Deo"
export const JohanDeoMailId =  "JohanDeo@gmail.com"
export const ProjectDescription=  "Project Description"
export const ProjectDescriptionDetails =  "Lorem Ipsum is simply dummy text of the printing and typesetting industry.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
export const Sub_total =  "Sub-total"
export const ThankBusiness =  "Thank you for your business!"
export const ThankBusinessDesc =  "Payment is expected within 31 days; please process this invoice within that time. There will be a 5% interest charge per month on late invoices."
export const Print =  "Print"

// Chats
export const CALL =  "CALL"
export const STATUS =  "STATUS"
export const PROFILE =  "PROFILE"
export const SEND =  "SEND"
export const EricaHughes =  "Erica Hughes"
export const VincentPorter =  "Vincent Porter"

// Email 
export const MARKJENCO =  "MARK JENCO"
export const MARKJENCOEMAIL =  "Markjecno@gmail.com"
export const NEWMAIL =  "NEW MAIL"
export const AllMail =  "All mail"
export const Sent =  "Sent"
export const Draft =  "Draft"
export const Trash =  "Trash"

export const IMPORTANT =  "IMPORTANT"
export const Starred =  "Starred"
export const UNREAD =  "UNREAD"
export const Spam =  "Spam"
export const OUTBOX =  "OUTBOX"
export const UPDATE =  "UPDATE"
export const ALERT =  "ALERT"
export const NOTES =  "NOTES"
export const NoMailFound = "No mail found"
export const To = "To"
export const ATTACHMENTS = "ATTACHMENTS"
export const DownloadAll = "Download All"
export const Reply = "Reply"
export const ReplyAll = "Reply All"
export const Forward = "Forward"
export const More = "More"
export const Subject = "Subject"

// Edit
export const SelectDocumentType = "Select Document Type"

// Social app
export const Timline = "Timline"
export const About = "About"
export const GeneralManager = "general manager"
export const Friends = "Friends"
export const Photos = "Photos"

export const MoreCommnets = "More Commnets"
export const ELANA = "ELANA"
export const JasonBorne = "Jason Borne"
export const AlexendraDhadio = "Alexendra Dhadio"
export const OliviaJon = "Olivia Jon"
export const IssaBell = "Issa Bell"

export const SocialNetworks = "Social Networks"
export const ProfileIntro = "Profile Intro"
export const LatestPhotos = "Latest Photos"
export const FavouriteMusicBands = "Favourite Music Bands"
export const Dribbble = "Dribbble"
export const BuckyBarnes = "Bucky Barnes"
export const SarahLoren = "Sarah Loren"
export const ComerenDiaz = "Comeren Diaz"
export const AndewJon = "Andew Jon"
export const JohnyWaston = "Johny Waston"
export const JohnyWilliam = "Johny William"
export const AnnaMull = "Anna Mull"
export const KarleneLex = "Karlene Lex"
export const VellaChism = "Vella Chism"
export const WaiSchalk = "Wai Schalk"
export const DionCast = "Dion Cast"
export const AddFriend = "Add Friend"
export const Hobbies = "Hobbies"
export const MyPage = "My Page"
export const Likes = "Likes"
export const View = "View"
export const MutualFriends = "Mutual Friends"
export const ActivityFeed = "Activity Feed"
export const ActivityLog = "Activity Log"

export const PostComment = "Post Comment"
export const LatestEventPosts = "Latest Event Posts"
// Todo 
export const  ToDo  = "To-Do"
export const  ToDoFirebase  = "To-Do Firebase"
export const  AddTask = "Add Task"
export const  AddNewTask  = "Add new task"

// Users
export const Name = "Name"

export const MarekjecnoMailId =  "Marekjecno@yahoo.com"
export const BOD =  "BOD"
export const DDMMYY =  "02 January 1988"
export const Designer =  "designer"
export const ContactUsNumber =  "India +91 123-456-7890"
export const JOHANDIO =  "JOHAN DIO"

export const MyProfile =  "My Profile"
export const Bio =  "Bio"
export const City =  "City"
export const Password =  "Password"
export const Website =  "Website"
export const Address = "Address"
export const Country = "Country"
export const Save =  "Save"
export const Company =  "Company"
export const Username =  "Username"
export const UsersCountryMenu = ["--Select--","Germany","Canada","Usa","Aus"]
export const AboutMe =  "About Me"
export const UpdateProfile =  "Update Profile"
export const UsersTableTitle =  "Add projects And Upload"
export const UsersTableHeader =  ["Project Name","Date","Status","Price",""]
export const Update =  "Update"
export const Comment =  "Comment"  

export const LatestPost = "Latest Post"

// Forms
export const  CustomStyles = "Custom styles"
export const  BrowserDefaults = "Browser defaults"
export const  SupportedElements = "Supported elements"
export const  Tooltips = "Tooltips"
export const  FirstName = "First Name"
export const  LastName = "Last Name"
export const  EmailAddress = "Email Address"
export const  Zip = "Zip"
export const  State = "State"
export const  SubmitForm = "Submit form"
export const  Submit =  "Submit"
export const  COD =  "COD"
export const  Standard =  "Standard"
export const  BasicFormControl = "Basic form control"
export const  ExampleSelect = "Example select"
export const  ExampleMultipleSelect = "Example multiple select"
export const  ExampleTextarea = "Example textarea"
export const  BasicHTMLInputControl = "Basic HTML input control"
export const  SimpleInput = "Simple Input"
export const  Placeholder = "Placeholder"
export const  Number = "Number"
export const  Telephone = "Telephone"
export const  URL = "URL"
export const  DateAndTime = "Date and time"
export const  ColorPicker = "Color picker"
export const  MaximumLength = "Maximum Length"
export const  StaticText = "Static Text"
export const  EdgesInputStyle = "Edges input style"
export const  FlatInputStyle = "Flat input style"
export const  RaiseInputStyle = "Raise input style"
export const  SolidInputStyle = "Solid input style"
export const  InputSizing = "Input sizing"
export const  SmallSelect = "Small select"
export const  LargeSelect = "Large select"
export const  DefaultSelect = "Default select"
export const  DefaultInput = "Default input"

export const  CustomControls = "Custom controls"
export const  UploadFile = "Upload File"
export const  CustomSelect = "Custom select"
export const  LargeInput = "Large Input"
export const  SmallInput = "Small Input"
export const  Textarea = "Textarea"

export const  CustomRadio = "Custom Radio"
export const  Option = "Option"
export const  Checked = "Checked"
export const  RadioStates = "Radio states"
export const  InlineCheckbox = "Inline checkbox"
export const  CustomCheckbox = "Custom checkbox"
export const  CheckboxStates = "Checkbox states"
export const  SuccessState = "Success state"
export const  BrandState = "Brand state"
export const  PrimaryState = "primary state"
export const  AnimatedRadioButtons = "Animated radio buttons"
export const  AnimatedCheckboxButtons = "Animated checkbox buttons"
export const  SquareCheckbox = "Square checkbox"
export const  SolidCheckbox = "Solid checkbox"

export const  BasicInputGroups = "Basic Input groups"
export const  LeftAddon = "Left Addon"
export const  RightAddon = "Right Addon"
export const  JointAddon = "Joint Addon"
export const  LeftRightAddon = "Left & Right Addon"
export const  SolidStyle = "Solid style"
export const  SquareStyle = "Square style"
export const  RaiseStyle = "Raise style"
export const  FlatStyle = "Flat style"

export const  DefaultStyle = "Default Style"
export const  Fast = "Fast"
export const  NoBorder = "No Border"
export const  SolidBorderStyle = "Solid Border Style"
export const  OfferStyleBorder = "Offer style Border"
export const  InlineStyle = "Inline Style"
export const  Local = "Local"
export const  VerticalStyle = "Vertical Style"
export const  DeliveryOption = "Delivery Option"
export const  BuyingOption = "Buying Option"
export const  XYZSeller = "XYZ Seller"
export const  ABCSeller = "ABC Seller"
export const  HorizontalStyle = "Horizontal Style"

export const  DatePickers = "Date Picker"
export const  SelectDateWithTime = "Select Date with Time"
export const  SelectTimeOnly = "Select Time Only"
export const  CustomDateFormat = "Custom date format"
export const  TodayButton = "Today button"
export const  DisableDaysOfWeek = "Disable days of week"
export const  SpecificDateRange = "Specific date range"
export const  MinDate = "Min Date"
export const  MaxDate = "Max Date"
export const  DateRange = "Date Range"
export const  DisableDatepicker = "Disable Datepicker"
export const  InlineVersion = "Inline version"

export const  TimePickerDarkTheme = "Time Picker with Dark Theme"
export const  SetClassicTheme = "Set Classic Theme"
export const  SetMultipleTimepicker = "Set Multiple Timepicker"

export const  BasicDemo = "Basic Demo"
export const  MultipleSelections = "Checked"
export const  CustomSelections = "Custom Selections"

export const  DefaultFormLayout = "Default Form Layout"
export const  HorizontalFormLayout = "Horizontal Form Layout"
export const  MegaForm = "Mega form"
export const  AccountInformation = "Account Information"
export const  CompanyInformation = "Company Information"
export const  BillingInformation = "Billing Information"
export const  InlineForm = "Inline Form"
export const  InlineFormWithLabel = "Inline Form with Label"
export const  InlineFormWithoutLabel = "Inline Form without Label"

export const  CompanyName = "Company Name"
export const  ContactNumber = "Contact Number"
export const  Radios = "Radios"
export const  Checkboxes = "Checkboxes"

export const  FormWizardWithIcon = "Form Wizard With Icon"
export const  DD = "DD" 
export const  MM = "MM"
export const  YY = "YY"

// Tables
export const  BasicTable = "Basic Table"
export const  InverseTable = "Inverse Table"
export const  InverseTablePrimaryBackground = "Inverse Table with Primary background"
export const  HoverableRows = "Hoverable rows"
export const  TextBackgroundUtilities = "Text or background utilities"
export const  TableHeadOptions = "Table head options"
export const  StripedRow = "Striped Row"
export const  StripedRowInverseTable = "Striped Row with Inverse Table"
export const  Caption = "Caption"
export const  ResponsiveTables = "Responsive Tables"
export const  BreckpointSpecific = "Breckpoint Specific"

export const  ExtraLargeTables = "Extra Large Tables"
export const  LargeTable = "Large Table"
export const  DefaultTable = "Default Table"
export const  SmallTable = "Small table"
export const  ExtraSmallTable = "Extra Small table"
export const  HorizontalBorders = "Horizontal Borders"
export const  VerticalBorders = "Vertical Borders"
export const  BothBordeds = "Both Bordeds"
export const  BorderlessTable = "Borderless Table"
export const  DefaultTableBorder = "Default Table Border"
export const  SolidBorder = "Solid Border"
export const  DoubleBorder = "Double Border"
export const  DottedBorder = "Dotted Border"
export const  DashedBorder = "Dashed Border"
export const  BorderBottomColor = "Border Bottom Color"
export const  DefultStyling = "Defult Styling"
export const  TableFooterStyling = "Table Footer Styling"
export const  TableHeadOptionsPrimaryBackground = "Table head options With Primary Background"
export const  CustomTableColor = "Custom Table Color"
export const  CustomTableColorHoverStripped = "Custom Table Color with Hover and Stripped"

// Blog
export const JolioMark = "Jolio Mark"
export const PostEdit = "Post Edit"
export const Text = "Text"
export const Image = "Image"
export const Audio = "Audio"
export const Video = "Video"
export const Category = "Category"
export const Content = "Content"
export const Discard = "Discard"
export const Connect = "Connect"

//Social Media

export const Facebook =  "Facebook"
export const FacebookLink = "Facebook Link"
export const Instagram = "Instagram"
export const InstagramLink = "Instagram Link"
export const Twitter =  "Twitter"
export const TwitterLink = "Twitter Link"
export const Linkedin =  "Linkedin"
export const LinkedinLink = "Linkedin Link"
export const GooglePlus =  "Google +"
export const Github = "Github"

// Catalog
export const Catalog = "Catalog"

// Shows

export const ChooseTalentsCSV = "Choose Talents CSV"
export const CreateShow = "Create Show"

export const Title = "Title"
export const GoTo = "Go to"
export const Questions = "Questions"
export const Upcoming = "Upcoming"
export const After = "After"
export const Recorded = "Recorded"
export const NotAvailable = "Not Available"
export const Description = "Description"
export const Youtube = "Youtube"
export const Vimeo = "Vimeo"
export const Other = "Other"
export const Preview = "Preview"
export const Talent = ["Artist","Speaker"]
export const Talents = ["Artists","Speakers"]
export const TalentList = "Talent List"
export const TalentsList = "Talents List"
export const TalentEmail = "Talent Email"
export const TalentBulkUpload = "Talent BulkUpload"
export const SearchTalentEmail = "Search Talent Email"
export const VRStreaming = "VR Streaming"
export const CoverImage = "Cover Image"
export const Image360 = "360 Image"
export const Streaming = "Streaming"
export const Addons = "Addons"
export const Options = "Options"

export const Hotspot = "Hotspot"
export const Hotspots = "Hotspots"
export const AdPlacement = "Ad Placement"
export const AdPlacements = "Ad Placements"
export const Assign = "Assign"

export const Beginning = "Beginning"
export const BeginningDate = "Beginning Date"
export const End = "End"
export const EndingDate = "Ending Date"

// Sponsors
export const Brand = "Brand"

export const AddSponsor = "Add Sponsor"
export const AddSponsorIndividually = "Add Sponsor vidually"

export const ChooseSponsorCSV = "Choose Sponsor CSV"

// Experiences
export const Experiences = "Experiences"
export const Experience = "Experience"

//Activities
export const Activities = "Activities"




// Job search 
export const  UIDesigner = "UI Designer"
export const  CreativeTelecom = "Creative Telecom && Technologies , NY"

export const  PersonalDetails = "Personal Details"
export const  FullName = "Full Name"
export const  RepeatPassword = "Repeat Password"
export const  PhoneNumber = "Phone Number"
export const  CollegeName = "College Name"
export const  Period = "Period"
export const  DegreeLevel = "Degree Level"
export const  Specialization = "Specialization"
export const  YourExperience = "Your Experience"
export const  YourEducation = "Your Education"
export const  UploadCoverLetter = "Upload Cover Letter"
export const  UploadYourCV = "Upload Your CV"
export const  UploadRecommendations = "Upload Recommendations"
export const  UploadYourFiles = "Upload Your Files"

export const  CreativeUnitedStates = "Creative - United States"
export const  JobDescription = "Job Description"
export const  Qualifications = "Qualifications"
export const  AgencyExperience = "Period"
export const  Perks = "Perks"
export const  SimilarJobs = "Similar jobs"
export const  SeniorUXDesigner = "Senior UX designer"
export const  Share = "Share"

export const  FrontEndWebDeveloper = "Front end web developer"
export const  GraphicDesigner = "Graphic designer"
export const  DesignerCRM = "Designer, CRM"
export const  UIDesigner_E_commerce = "UI designer E-commerce"
export const  ReactandReact_Native_Developer = "React/React Native Developer"
export const  UIandUX_IT_Frontend_Developer = "UI/UX IT Frontend Developer"

export const  FindJobs = "Find jobs"
export const  NewYork = "New York"
export const  SanFrancisco = "San Francisco"
export const  Washington = "Washington"
export const  Seattle = "Seattle"
export const  Chicago = "Chicago"
export const  AllLocations = "All Locations"
export const  AllJobTitle = "All Job Title"
export const  JobTitle = "Job Title"
export const  Industry = "Industry"
export const  AllIndustries = "All Industries"
export const  SpecificSkills = "Specific skills"
export const  AllSkills = "All Skills"

export const  FindCourse = "Find Course"
export const  Categories = "Categories"
export const  Accounting = "Accounting"
export const  Design = "Design"
export const  Development = "Development"
export const  Management = "Management"
export const  Duration = "Duration"
export const  AllCourses = "All Courses"
export const  PaidCourses = "Paid Courses"
export const  FreeCourses = "Free Courses"
export const  Registration = "Registration"
export const  Progress = "Progress"
export const  Completed = "Completed"
export const  Filter = "Filter"
export const  Price =  "Price"
export const  Status =  "Status"
export const  UIDesign = "UI Design"
export const  UXDesign = "UX Design"
export const  InterfaceDesign = "Interface Design"
export const  UserExperience = "User Experience"
export const  FrontendDevelopment = "Frontend Development"
export const  BackendDevelopment = "Backend Development"
export const  UpcomingCourses = "Upcoming Courses"
export const  UXDevelopment = "UX Development"
export const  BusinessAnalyst = "Business Analyst"
export const  WebDevelopment = "Web Development"
export const  CourseBy = "Course By"
export const  JavaLanguage = "Java Language"

// Faq anf knowledgebase
export const  Articles = "Articles"
export const  Knowledgebase = "Knowledgebase"
export const  Support = "Support"
export const  IntellectualProperty = "Intellectual Property"
export const  SellingAndBuying = "Selling And Buying"
export const  UserAccounts = "User Accounts"
export const  SearchArticles = "Search articles"
export const  Navigation = "Navigation"
export const  LatestUpdates = "Latest Updates"

export const  DavidLinner = "David Linner"
export const  UserChristopher = "User Christopher"
export const  AskQuestion = "Ask Question"
export const  Tutorials = "Tutorials"
export const  HelpCenter = "Help center"
export const  VideoTutorials = "Video Tutorials"

export const  AskOurCommunity = "Ask our community"
export const  VictoriaWilson = "Victoria Wilson"
export const  FeaturedTutorials = "Featured Tutorials"
export const  WebDesign = "Web Design"
export const  SeeAll = "See All"
export const  BrowseArticles = "Browse Articles"


// Pages
export const  LoginWithAuth0 = "Login With Auth0"
export const  LoginWithJWT = "Login With JWT"

export const  EnterPassword = "Enter your Password"
export const  Unlock = "Unlock"
export const  CREATEPASSWORD = "CREATE YOUR PASSWORD"
export const  RetypePassword = "Retype Password"
export const  NewPassword = "New Password"

export const  ResetPassword = "Reset Your Password"
export const  EnterMobileNumber = "Enter Your Mobile Number"
export const  EnterOTP = "Enter OTP"
export const  Resend = "Resend"
export const  MAINTENANCE = "MAINTENANCE"
export const  BACK_TO_HOME_PAGE = "BACK TO HOME PAGE"
export const  WE_ARE_COMING_SOON = "WE ARE COMING SOON"

export const  SampleCard = "Sample Card"
export const  Videos = "Videos"
export const  Images = "Images"
export const  All = "All"
export const  SearchSetting = "Search setting"
export const  Language = "Language"
export const  Shopping = "Shopping"
export const  Billing = "Billing"
export const  Getting = "Getting"
export const  Flights = "Flights"


// Common Layouts
export const Shopify =  "Shopify"
export const Ecommerce =  "Ecommerce"
export const Prestashop =  "Prestashop"
export const GridDashboard =  "Grid Dashboard"
export const Activitys =  "Activity"
export const Reports =  "Reports"
export const Automation =  "Automation"
export const FollowsUp =  "Follows Up"
export const Taskboard =  "Taskboard"
export const WebDesigner =  "Web Designer"
export const Visa =  "Visa"
export const Paytm =  "Paytm"
export const OngoingProjects =  "Ongoing Projects"
export const ContactsStatus =  "Contacts Status"

export const  Customizer = "Customizer"
export const  Customize = "Customize"
export const  PreviewRealTime = "Preview Real Time"
export const  Configuration = "Configuration"
export const  CopyText = "Copy text"
export const  LayoutType = "Layout Type"
export const  LTR = "LTR"
export const  RTL = "RTL"
export const  Box = "Box"
export const  SidebarType = "Sidebar Type"
export const  SidebarSettings = "Sidebar settings"
export const  Border = "Border"
export const  IconColor = "icon Color"

export const  RouterAnimation = "Router Animation"
export const  ZoomFade = "Zoom Fade"
export const  SildeFade = "Silde Fade"
export const  FadeBottom = "Fade Bottom"
export const  Fade = "Fade"
export const  ZoomOut = "Zoom Out"
export const  None = "None"
export const  LightLayout = "Light layout"
export const  DarkLayout = "Dark Layout"
export const  MixLayout = "Mix Layout"
export const  BackgroundLayout =  "BACKGROUND LAYOUT"
export const  SidebarBackgroundSetting =  "Sidebar background setting"
export const  Pattern =  "Pattern"






















//EVNT
export const AssignShows = "Assign Shows"
export const AllowAccessTo = "Allow Access To"
export const AllowAccessToOptions =  ["Everyone","Guests List","Payed"]
export const AssignLocation = "Assign Location"
export const AssignVRLocation = "Assign VR Location"
export const AvailablePerspectives = "Available Perspectives"
export const AccessPrice = "Access Price"
export const Actions = "Actions"
export const AdPlacementsListData = "Ad Placements List"
export const Add =  "Add"
export const AddEvent = "Add Event"
export const AddLocation = "Add Location"
export const AddPerspective = "Add Perspective"
export const Administrator = "Administrator"
export const AddAdministrator = "Add Administrator"
export const AddBannerIndividually =  "Add Banner Individually"
export const AddGuest = "Add Guest"
export const AddGuestIndividually = "Add Guest Individually"
export const AddTalentIndividually = "Add Talent Individually"
export const AdministratorsBulkUpload = "Administrators BulkUpload"
export const AdministratorCRUDPermissions = "Administrator CRUD Permissions"
export const AdministratorPermissionsTypes = ['Event Info','Shows Info','Experiences Info']
export const Administrators = "Administrators"
export const AdministratorsList = "Administrators List"
export const AdministratorEmail = "Administrator Email"
export const AllowSponsorToAddABanner = "Allow sponsor to add a Banner"
export const ARSettings = "AR Settings"
export const ARMode = "AR Mode"
export const Attendance = "Attendance"
export const AudioControls = "Audio Controls"
export const Ads = "Ads"
export const AddHotSpot = "Add HotSpot"
export const AdPlacementImage = "Ad Placement Image"
export const AdPlacementDetails = "Ad Placement Details"
export const AdPlacementTemplate = "Ad Placement Template"
export const AssignedSponsors = "Assigned Sponsors"
export const ArtistManager = "Artist Manager"
export const ArtistShow = "Artist Show"
export const AudioSettings = "Audio Settings"
export const AcceptTermsConditions = "Accept Terms & Conditions"

export const BirthDate = "Birth Date"
export const BannerImage = "Banner Image"
export const BannersListData = "Banners List"
export const BulkUpload = "BulkUpload"
export const BrandableAreas = "Brandable Areas"
export const BlockedUsers = "Blocked Users"
export const BlockUser = "Block User"
export const BlockUserIndividually = "Block user individually"
export const BreadCrumbRoute = "Bread crumb route"

export const Create = "Create"
export const CreateOneToContinue = "Create one to continue"
export const CreateNewPlatform = "Create new platform"
export const CreateNewEvent = "Create new event"
export const CreateNewLocation = "Create New Location"
export const CreateNewPerspective = "Create New Perspective"
export const ControlPanel = "Control Panel"
export const ControlPanell = "ControlPanel"
export const CreateEvent = "Create Event"
export const CreateLocation = "Create Location"
export const CreatePerspective = "Create Perspective"
export const Casino = "Casino"
export const ConfirmPassword = "Confirm Password"
export const CatalogAccess = "Catalog Access"
export const Contacts =  "Contacts"
export const Comments = "Comments"
export const Currency = "Currency"
export const CurrencyList = ['USD','EU','MXN']
export const ChattingWith = "Chatting With"
export const ChooseJPGorPNG = "Choose JPG or PNG"
export const ChooseAdminCSV = "Choose CSV file with Administrators List"
export const ChooseGuestCSV = "Choose CSV file with Guests List"
export const ChooseUsersCSV = "Choose CSV file with Users List"
export const ChoosePlatform = "Choose a Platform"
export const ChooseLocation = "Choose Location"
export const ChooseEvent = "Choose a Event"
export const ChoosePerspective = "Choose Perspective"
export const ChooseType = "ChooseType"
export const ChooseSponsor = "Choose Sponsor"
export const ChooseSize = "Choose Size"
export const CreateHotSpot = "Create HotSpot"
export const CreateAdPlacement = "Create Ad Placement"
export const CreateBanner = "Create Banner"
export const ChooseYourSeat = "Choose Your Seat"
export const Charts = "Charts"
export const Current = "Current"

export const Data = "Data"
export const Delete =  "Delete"
export const Details = "Details"
export const DownloadFile = "Download File"
export const DownloadableResources = "Downloadable Resources"
export const DonwloadCSVTemplate = "Donwload CSV Template"
export const DisplayTo = "Display To"
export const DisplayToOptions = ["Everyone","Guests List","Payed"]

export const EventList = "Events List"
export const EventDetails = "Event Details"
export const EventSettings = "Event Settings"
export const EditProfile =  "Edit Profile"
export const Eventt = "Event"
export const Events = "Events"
export const EventsListt = "Events List"
export const EventCasters = "Event Casters"
export const EventLogIn = "Event LogIn"
export const EditAvatar =  "Edit Avatar"
export const EventHome = "Even Home"
export const EventAdministrators = "Event Administrators"
export const EventType = "Event Type"
export const EventPrice = "Event Price"
export const EventName = "Event Name"
export const EventTermsConditions = "Event Terms & Conditions"
export const EventTimeline = "Event Timeline"
export const EventCity = "Event City"
export const EventVenue = "Event Venue"
export const EventCountry = "Event Country"
export const EventState = "Event State"
export const EventSponsors = "Event Sponsors"
export const EventSponsorBanners = "Event Sponsor Banners"
export const EventGraphic = "Event Graphic"
export const EventGeneralInfo = "Event General Info"
export const EventGuestsList = "Event Guests List"
export const EventPricePasswordTerms = "Event Price, Password & Terms"
export const EventTypeOptions = "Event Type Options"
export const Edit =  "Edit"
export const ExternalLink = "External Link"
export const ExploreYourSurroundings = "Explore Your Surroundings"
export const ExitVRStatistics = "Exit VR Statistics"
export const EvntStore = "Evnt Store"
export const EvntStudio = "Evnt Studio"
export const EventTitle = "Event Title"
export const EventCaster = "Event Caster"
export const EventDescription = "Event Description"
export const EventInfo = "Event Info"
export const EventLogInType = "EventLogInType"
export const EventCreatedSuccessfully = "Event created successfully"

export const Feed = "Feed"
export const FirstPerspective = "First Perspective"
export const Female = "Female"
export const FoodStore = "Food Store"
export const ForgotPassword = "Forgot Password"
export const Free = "Free"
export const Follower =  "Follower"
export const Followers =  "Followers"
export const Following =  "Following"

export const Groups = "Groups"
export const GraphicSoruce = "Graphic Soruce"
export const Guests = "Guests"
export const Gender = "Gender"
export const GeneralInfo = "General Info"
export const GeneralInformation = "General Information"
export const GuestsList = "Guests List"
export const GuestEmail = "Guest Email"
export const GuestBulkUpload = "Guest BulkUpload"

export const HalfTimeShow = "Half Time Show"
export const HandsUp = "Hands Up"
export const HandsDown = "Hands Down"
export const Homes = "Home" 
export const HotSpots = "Hot Spots"
export const HotSpotsListData = "HotSpots List"
export const HotSpotsProfiles = "HotSpots Profiles"
export const Hosted = "Hosted"
export const HostedBy = "Hosted by"
export const Header = "Header"
export const HomePerspective = "Home Perspective"

export const ImRegistered = "Im Registered"
export const Items = "Items"
export const Immersive360Experience = "Immersive 360 Experience"
export const Instructions = "Instructions"
export const IndividualBanners = "Individual Banners will contribute to the event resources & metrics"

export const JoinExperience = "Join Experience"

export const List = "List"
export const Listt = "List"
export const LineUp = "LineUp"
export const LinkTo = "Link To"
export const LinkType = "Link Type"
export const LeaguePositions = "League Positions"
export const League = "League"
export const LoadingPlatform = "Loading Platform"
export const LocationGeneralInfo = "Location General Info"
export const LocationDetails ="Location Details"
export const LocationDetailss ="Location Details"
export const Loading =  "Loading"
export const LoaderLogo = "Loader Logo"
export const Location =  "Location"
export const Locations = "Location"
export const Locationss = "Locations"
export const LocationsListt = "Locations List"
export const LocationData = "LocationData"
export const LocationName = "Location Name"
export const LocationType = "Location Type"
export const LocationPrice = "Location Price"
export const LocationStatus = "Location Status"
export const LocationDescription = "Location Description"
export const LocationAdministrators = "Location Administrators"
export const LocationGuestsList = "Location Guests List"
export const LocationTo = "Location To"
export const LocationThumbnail = "Location Thumbnail"
export const Login = "Login"
export const LOGIN = "LOGIN"
export const LogInPerspective = "Log In Perspectives"
export const LocationCreatedSuccessfully = "Location created successfully"
export const LocationCategory = "Location Category"

export const Metrics = "Metrics"
export const Music = "Music"
export const Merch = "Merch"
export const Male = "Male"
export const MoreOptions = "More Options"
export const Match = "Match"
export const MainBannerSlider = "Main Banner Slider"
export const MediaLink = "Media Link"
export const MediaGallery = "Media Gallery"
export const MediaInputOptions = ["YouTube","Vimeo","Image"]
export const ModeSettings = "Mode Settings"
export const MainEventType = "Main Event Type"

export const NadirLogo = "NadirLogo"
export const NadirLogoo = "NadirLogo"
export const Newest = "Newest"
export const NotBinary = "Not Binary"
export const NikeStore = "Nike Store"
export const NickName = "Nick Name"
export const Notifications = "Notifications"

export const Owner = "Owner"
export const Oldest = "Oldest"

export const PasswordConfirmationMustMatch="Password confirmation must match"
export const Platform = "Platform"
export const Platforms = "Platforms"
export const PlatformsListt = "Platforms List"
export const PlatformSettings = "Platform Settings"
export const PlatformDetails = "Platform Details"
export const PlatformDetailss = "Platform Details"
export const PlatformDataa = "Platform Data"
export const PlatformName = "Platform Name"
export const PlatformLogo = "Platform Logo"
export const PlatformRedirect = "Platform Redirect"
export const PlatformCreatedSuccessfully = "Platform Created Successfully"
export const PasswordOk = "Password Ok"
export const Polls = "Polls"
export const Position = "Position"
export const Purchase =  "Purchase"
export const PurchaseTokens =  "Purchase Tokens"
export const Purchasing = "Purchasing"
export const PurchaseEvent = "Purchase Event"
export const PurchaseLocation = "Purchase Location"
export const PreviewPerspective = "Preview Perspective"
export const PurchasePerspective = "Purchase Perspective"
export const PerspectiveLocked = "Perspective Locked"
export const PurchaseToUnlock = "Purchase to unlock"
export const PlatformTypes = ["1D","2D","3D","VR"]
export const PauseBroadcasting = "Pause Broadcasting"
export const Perspective = "Perspective"
export const Perspectivee = "Perspective"
export const Perspectives = "Perspectives"
export const PerspectiveHotSpots = "Perspective HotSpots"
export const PerspectivesListt = "Perspectives List"
export const PerspectiveDetails = "Perspective Details"
export const PerspectiveDetailss = "Perspective Details"
export const PerspectiveName = "Perspective Name"
export const PerspectiveThumbnail = "Perspective Thumbnail"
export const PerspectivesListData = "Perspectives List"
export const PerspectiveTo = "Perspective To"
export const PerspectiveImage360 = "Perspective Image 360"
export const PerspectiveCreatedSuccessfully = "Perspective created successfully"
export const PositionCordinates = "Position Cordinates"
export const PrimaryStreaming = "Primary Streaming"

export const QuestionsPolls = "Questions & Polls"

export const RTMP = "RTMP"
export const RTMPSettings = "RTMP Settings"
export const RTMPStreamingLink = "RTMP Streaming Link"
export const RaisedHands = "Raised Hands"
export const Resources = "Resources"
export const Registered = "Registered"
export const Role = "Role"
export const Remote = "Remote"
export const RegisterUser = "Register User"
export const RememberMe = "Remember me"
export const RequiredField = "Required Field"

export const Statistics = "Statistics"
export const SignIn = "Sign In"
export const SignUp = "Sign Up"
export const StartBroadcasting = "Start Broadcasting"
export const StopBroadcasting = "Stop Broadcasting"
export const Size = "Size"
export const Store = "Store"
export const ShowsLocations = "Shows Locations"
export const SimpleStatistics = "Simple Statistics"
export const SportsCaster = "Sports Caster"
export const Settings =  "Settings"
export const Search = "Search"
export const SearchPlayer = "Search Player"
export const SearchContacts = "Search Contacts"
export const SearchGroups = "Search Groups"
export const SelectGender = "Select a Gender"
export const ShowEventCounters = "Show Event Counters"
export const SendRegistrationReminder = "Send Registration Reminder"
export const SearchGuestEmail = "Search Guest Email"
export const SearchUserEmail = "Search User Email"
export const Showing =  "Showing"
export const ShowingEvents =  "Showing Events"
export const Shows = ["Shows","Conferences"]
export const Show = ["Show","Conference"]
export const ShowInfo = "Show Info"
export const ShowTalent = ["Name","Email","Type"]
export const ShowLink = "Show Link"
export const ShowType = "Show Type"
export const ShowExternalLink = "Show External Link"
export const ShowName = "Show Name"
export const ShowArtist = "Show Artist"
export const ShowSponsor = "Show Sponsors"
export const ShowsLists = "Shows Lists"
export const ShowGeneralInfo = "Show General Info"
export const SettingsEdit = "Settings / Edit"
export const SearchSponsor = "Search Sponsor"
export const SearchSponsorWithUserName = "Search Brand with @UserName"
export const SearchSponsorUserName = "Search Sponsor Username"
export const Sponsor = "Sponsor"
export const Sponsors = "Sponsors"
export const SponsordedBy = "Sponsorded by"
export const SponsorsList = "Sponsors List"
export const SponsorContact = "Sponsor Contact"
export const SponsorName =  "Sponsor Name"
export const SponsorUserName = "Sponsor @Username"
export const SponsorLogo = "Sponsor Logo"
export const SponsorDescription = "Sponsor Description"
export const SponsorAds = "Sponsor Ads"
export const SponsorsBulkUpload = "Sponsor BulkUpload"
export const SpecialPerspectives = "Special Perspectives"
export const SocialSharing = "Social Sharing"
export const SettingsMore = "Settings and More"
export const SocialMediaLogIn = "Social Media Login"
export const Streaming2D = "Streaming 2D"
export const StreamingType = 'Streaming Type'
export const StreamingStatus = "Streaming Status"
export const StreamingProvider = 'Streaming Provider'
export const StreamingLink = 'Streaming Link'

export const TalentHeader = "Talent Header"
export const Tables = "Tables"
export const Tags = "Tags"
export const TextMessage = "Text Message"
export const ToList = "to list"
export const Type = "Type"
export const Team = "Team"
export const Teams = "Teams"
export const TeamStatistics = "Team Statistics"
export const TemporarilyUnavailable = "Temporarily unavailable"
export const TheNickNameYouEnteredAlreadyExist = "The Nickname you entered already exist"
export const TheSelectedPlatformDoesNotHaveEvents = "The selected platform doesn't have events"
export const TheSelectedEventDoesNotHaveLocations = "The selected event doesn't have locations"
export const TheSelectedLocationDoesNotHavePerspectives = "The selected location doesn't have perspectives"

export const User = "User"
export const Users = "Users"
export const UsersList = "Users List"
export const UserLogIn = "User LogIn"
export const UserEmail = "User Email"
export const UserPassword = "User Password"
export const UserImage = "User Image"
export const UserName = "User Name"
export const UserFisrtName = "User Fisrt Name"
export const UserLastName = "User Last Name"
export const UserPermissions = "User Permissions"
export const UserBulkUpload = "User BulkUpload"
export const UserSuite = "User Suite"
export const UnblockUser = "Unblock user"
export const UpcomingEvents = "Upcoming Events"
export const UploadStory = "Upload Story"
export const UpgradePerspectives = "Upgrade Perspectives"

export const VenueHome = "Venue Home"
export const Venue = "Venue"
export const Venues = "Venues"
export const VenueLocations = "Venue Locations"
export const Viewers = "Viewers"
export const VideoQuality = "Video Quality"
export const VR = "VR"
export const VIPSuite = "VIP Suite"
export const VRView = "VR View"
export const VRSettings = "VR Settings"
export const VRMode = "VR Mode"
export const VolumeLevels = "Volume Levels"
export const VideoHosting = "Video Hosting"
export const VideoHostingSettings = "Video Hosting Settings"

export const Watching = "Watching"
export const Whitelabeling = "Whitelabeling"

export const ZoomInPerspective = "Zoom In Perspective"
export const ZoomOutPerspective = "Zoom Out Perspective"


