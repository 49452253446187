import {Platform_Type} from './EvntTypes'
    
const Evnt_state = {
    type:undefined
}

export const EvntState = (state=Evnt_state, action) => {
    switch (action.type) {
        case Platform_Type:
            state.type = action.data
            return state
        default:
        return state
    }
}


