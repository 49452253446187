import 'aframe'
import 'aframe-look-at-component'
import {Entity} from 'aframe-react'
import React, { Fragment} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {SetVrMode} from '../../../../redux/common/EventActions'
const NadirLogo = (props) => {
  const dispatch = useDispatch()
  const nadirLogoURL = useSelector(state => state.EventCommon.settings.nadirLogo.src)
  const setImg = (url) =>{
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      var blob = xhr.response;
    };
    xhr.open('GET', url);
    xhr.send();
    return url
  }
  return (
    <Fragment>
       <Entity id="NadirLogo" primitive="a-image" 
        src={setImg(nadirLogoURL)} crossOrigin={"anonymous"} 
        width="3" height="2" position="-0.04 -2 0.311" rotation="260 0 0" scale="0.7 1 0"
        events={{ click: () => dispatch(SetVrMode(false)) }}
        />

    </Fragment>  
  );
}
export default NadirLogo;
