import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { Fragment } from 'react'
import { Media,Nav,NavItem,NavLink,TabContent,TabPane } from 'reactstrap'
import ScrollArea from 'react-scrollbar'
import { UsersList  } from '../../../../components/users/UsersList'
import CloseCard from './CloseWindow'
import { Contacts, Groups } from '../../../../constant'
import UsersGroups from '../../../../components/users/UsersGroups'
import { LoadingAsset } from '../WindowLoaders'
import axios from 'axios'

export const UsersList360 = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [IconWithTab, setIconWithTab] = useState('1')
  const [userContacts, setUserContacts] = useState([])
  const currentUserID = localStorage.getItem('UserID')

  
  const userGroups = [
        {
            groupName:"La Monumental",
            img:"user-card/group1.jpg"
        },
        {
            groupName:"Futboleros",
            img:"user-card/group2.jpg"
        },
        {
            groupName:"Cascarita",
            img:"user-card/group3.jpg"
        }
  ]




  axios.defaults.baseURL = `http://localhost:4000`
  useEffect(()=>{
    const params = {
      userID: currentUserID
    }
    axios.get(`user/userContacts/`, {
      params: params
    }).then((res) => {
        setUsersData(res.data).then(()=>{
          setIsLoading(false)
      })
    }).catch(error => console.error(error))
  },[])

  const setUsersData = (users) =>{
      return new Promise ((resolve,reject) => {
        setUserContacts(users)
        resolve(true)
      })
  }

  if(isLoading){
    return(
      <LoadingAsset loading="UsersList360.js"/>
    )
  }

  return(
      <Fragment>
          <Card style={{margin:"15px 30px", width:"300px",height:"400px", pointerEvents:"all"}}>
          <div onClick={()=> props.checkIfIsOpen({name:props.windowName})}><CloseCard/></div>
              <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink style={{cursor:"pointer"}} className={IconWithTab === '1' ? 'active' : ''} onClick={() => setIconWithTab('1')}><i className="fa fa-user"></i>{Contacts}</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{cursor:"pointer"}} className={IconWithTab === '2' ? 'active' : ''} onClick={() => setIconWithTab('2')}><i className="fa fa-users"></i>{Groups}</NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={IconWithTab}>
                    <TabPane  className="fade show" tabId="1">
                      <UsersList  usersList={userContacts} checkIfIsOpen={props.checkIfIsOpen} height={"230px"}/>
                    </TabPane>
                    <TabPane tabId="2">
                      <br/>
                      <UsersGroups userGroups={userGroups} height={"230px"} checkIfIsOpen={props.checkIfIsOpen}/>
                    </TabPane>
                  </TabContent> 
              </CardBody>
          </Card>
      </Fragment>
  )
}
export default UsersList360