import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button } from "reactstrap";
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  CarouselControl,
  Media,
} from "reactstrap";
import {
  ChooseLocation,
  ChooseYourSeat,
  Free,
  PreviewPerspective,
  SponsordedBy,
  TemporarilyUnavailable,
} from "../../../../../constant";
import "../../../../../assets/css/evnt_cards.css";
import Rating from "react-rating";
import { ChangePerspective } from "../../../../../redux/common/EventActions";
import LabeledDropdown from "../../../../Admin/layout/FilterDropdown";
import CloseCard from "../CloseWindow";

export const CarouselStories = (props) => {
 
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const userStories = [
    {
      src: "Stadium/Stories/stadium_story01.mp4",
    },
    // {
    //   src:"Stadium/Stories/stadium_story02.mp4"
    // },
    {
      src: "Stadium/Stories/stadium_story03.mp4",
    },
    {
      src: "Stadium/Stories/stadium_story04.mp4",
    },
  ];

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === userStories.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? userStories.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
    if (animating) return;
  };

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      {/* <CarouselIndicators items={perspectives} activeIndex={activeIndex} onClickHandler={goToIndex} /> */}
      {userStories.map((story, i) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={`Stories ${i}`}
          >
            <video
              style={{ width: "180px", display: "block", margin: "0 auto" }}
              controls
              autostart
              autoPlay
              src={require(`../../../../../assets/video/${story.src}`)}
              type="video/mp4"
            />
            {/* <CarouselCaption captionText={`${i+1} - ${perspective.name}`} /> */}
          </CarouselItem>
        );
      })}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};
