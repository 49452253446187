import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { LoadingAsset } from "../../WindowLoaders";
import { getAuth } from "firebase/auth";
import { Media } from "reactstrap";
import "../../../../../assets/css/evnt_cards.css";

export const MediaList = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [media, setMedia] = useState([]);
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (props.talentID != undefined) {
      const params = {
        userID: getAuth().currentUser.uid,
        talentID: props.talentID,
      };
      axios
        .get(`${ApiURL}/api/admin/controlPanel/media`, {
          params: params,
        })
        .then((res) => {
          setMediaData(res.data).then(() => {
            setIsLoading(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [props]);

  const setMediaData = (media) => {
    return new Promise((resolve, reject) => {
      setMedia(media);
      resolve(true);
    });
  };

  if (isLoading) {
    return <LoadingAsset loading={"MediaList.js"} />;
  }

  return (
    <Fragment>
      {media.map((item, i) => {
        return (
          <div style={{ position: "relative" }}>
            <div
              className={"cursor-default text-color m-t-5 m-l-10"}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
                width: "100%",
                position: "relative",
                marginRight: "50%",
              }}
            >
              <Media
                className={"img-fluid m-l-5"}
                src={item.thumbnail}
                style={{ width: "80px" }}
              />
              <div className="m-t-5 m-l-20">
                <h4>
                  <b>{item.name}</b>
                </h4>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a href={`${item.platforms.spotify}`} target="_blank">
                      <i className="text-color fa fa-spotify fa-2x"></i>
                    </a>
                  </li>
                  <li className="list-inline-item m-l-10">
                    <a href={`${item.platforms.youtube}`} target="_blank">
                      <i className="text-color fa fa-youtube fa-2x"></i>
                    </a>
                  </li>
                  <li className="list-inline-item m-l-10">
                    <a href={`${item.platforms.appleMusic}`} target="_blank">
                      <i className="text-color fa fa-apple fa-2x"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
    </Fragment>
  );
};

export default MediaList;
