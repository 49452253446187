import "aframe";
import "aframe-look-at-component";
import { Entity, Scene } from "aframe-react";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { LoadingAsset, PerspectiveLoader } from "../../layout2D/WindowLoaders";
import { ChangePerspective } from "../../../../redux/common/EventActions";
import { Streaming2D } from "../../../../constant";
import { useParams } from "react-router-dom";
import closeIcon from "../../../../assets/images/icons/closeIcon.png"
const VR_Streaming2D = (props) => {
  var showBanner = props.showBanner == true ? true : false;
  const dispatch = useDispatch();
  const locationID = useSelector((state) => state.PerspectiveCommon.locationID);
  const perspectiveID = useSelector(
    (state) => state.PerspectiveCommon.perspectiveID
  );

  const [isLoading, setIsLoading] = useState(true);
  const [streaming2D, setStreaming2D] = useState(undefined);
  const [position, setPosition] = useState(undefined);
  const [rotation, setRotation] = useState(undefined);
  const [scale, setScale] = useState(undefined);
  const [streamingType, setStreamingType] = useState(undefined);
  const [streamingFrame, setStreamingFrame] = useState(undefined);
  const [magnifyedStreaming, magnifingStreaming] = useState(false);
  const { platformID, eventID } = useParams();
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    setIsLoading(true);
    if (
      platformID != undefined &&
      eventID != undefined &&
      locationID != undefined &&
      perspectiveID != undefined &&
      props.type == "NormalStreaming"
    ) {
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
        locationID: locationID,
        perspectiveID: perspectiveID,
      };

      axios
        .get(`${ApiURL}/api/user/platform/event/perspective/streaming2D`, {
          params: params,
        })
        .then((res) => {
          if (res.status != 204) {
            setStreaming2DData(res.data);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [platformID, eventID, locationID, perspectiveID]);

  useEffect(() => {
    if (props.type == undefined) {
      setStreaming2DData(props.streamingData);
    }
  }, []);

  const setStreaming2DData = (data) => {
    return new Promise((resolve, reject) => {
      setStreaming2D(data);
      setPosition(data.from.position);
      setRotation(data.from.rotation);
      setScale(data.from.scale);
      setStreamingFrame(data.streamingFrame);
      if (data.type != undefined) {
        setStreamingType(data.type);
      }
      createVideoAsset(data.src);

      setIsLoading(false);
      resolve(true);
    });
  };

  const createVideoAsset = (src) => {
    const streaming2D = document.createElement("video");
    streaming2D.id = "Streaming2DVideo";
    streaming2D.src = setMedia(src);
    streaming2D.muted = true;

    const vr_assets = document.querySelector("a-assets");
    vr_assets.appendChild(streaming2D);
  };

  useEffect(() => {
    if (!isLoading && streaming2D != undefined) {
      const perspectiveVideo = document.getElementById(`Streaming2DVideo`);
      perspectiveVideo.oncanplay = function () {
        perspectiveVideo.play();
      };
    }
  }, [isLoading]);

  const magnifyStreaming2D = (streaming2DFrom, streaming2DTo, type) => {
    if (type == "toSmall") {
      setPosition(streaming2DFrom.position);
      setRotation(streaming2DFrom.rotation);
      setScale(streaming2DFrom.scale);
      magnifingStreaming(false);
      console.log("Reducing Streaming 2D", streaming2DFrom);
    } else {
      setPosition(streaming2DTo.position);
      setRotation(streaming2DTo.rotation);
      setScale(streaming2DTo.scale);
      magnifingStreaming(true);
      console.log("Magnifing Streaming 2D", streaming2DTo);
    }
  };

  const setMedia = (url) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      var blob = xhr.response;
    };
    xhr.open("GET", url);
    xhr.send();
    return url;
  };

  if (isLoading) {
    return <PerspectiveLoader lastLoading={Streaming2D} />;
  }

  return (
    <Fragment>
      {streaming2D != undefined ? (
        <Fragment>
          <a-entity
          id="2D Streaming"
          // position={`${assetsPosition.x},${assetsPosition.y},${assetsPosition.z}`}
          position={`0,0,0`}
        >
          <Entity
            id="Streaming2D_VR"
            primitive="a-video"
            src={`#Streaming2DVideo`}
            width="8"
            height="4.5"
            position={`${position.x} ${position.y} ${position.z}`}
            rotation={`${rotation.x} ${rotation.y} ${rotation.z}`}
            scale={`${scale.x} ${scale.y} ${scale.z}`}
            events={{
              click: () => {
                streamingType != "HalfTime"
                  ? magnifyStreaming2D(
                      streaming2D.from,
                      streaming2D.to,
                      "toBig"
                    )
                  : dispatch(
                      ChangePerspective({
                        toLocationId: 4,
                        toPerspectiveId: 0,
                        type: undefined,
                      })
                    );
              },
            }}
          />
          {magnifyedStreaming ? (
            <Entity
              primitive="a-image"
              src={closeIcon}
              width="8"
              height="4.5"
              position={`${position.x+3.15} ${position.y+1.311} ${position.z+1}`}
              // position={`2.627 -3.32 -5.312`}
              scale={`0.1 0.15 1`}
              events={{
                click: () =>
                  magnifyStreaming2D(
                    streaming2D.from,
                    streaming2D.to,
                    "toSmall"
                  ),
              }}
            />
          ) : (
            ""
          )}

          {magnifyedStreaming || showBanner || streamingType == "HalfTime" ? (
            <Entity
              id={`Video Banner Frame`}
              primitive="a-image"
              width="8"
              height="4.5"
              src={setMedia(streamingFrame)}
              position={`${position.x} ${position.y} ${position.z}`}
              rotation={`${rotation.x} ${rotation.y} ${rotation.z}`}
              scale={`${scale.x} ${scale.y} ${scale.z}`}
              events={{ click: () => console.log("hola") }}
            />
          ) : (
            ""
          )}
          </a-entity>
        </Fragment>
      ) : (
        console.log("This perspective does not have Streaming 2D")
      )}
    </Fragment>
  );
};
export default VR_Streaming2D;
