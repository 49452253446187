import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Nav, NavLink, NavItem, TabContent, TabPane, Input} from 'reactstrap'
import CloseCard from './CloseWindow'
import { Metrics, League, Tables, Team, TeamStatistics } from '../../../../constant'
import { TeamList  } from '../../../../components/users/UsersList'
import { useDispatch, useSelector } from 'react-redux'
import { ChangePerspective } from '../../../../redux/common/EventActions'
import { LeagueTable, StatisticsTable } from './TeamStatistics'
import { ProfileCardData } from './ProfileCardData'
export const TeamStatisticsCard = (props) => {
  const dispatch = useDispatch()
  const [IconWithTab, setIconWithTab] = useState('1');

  const [VRLineUp , setVRLineUp] = useState(false)
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const windowWidth = props.windowWidth
  const windowHeight = props.windowHeight

  const [navButtonsAlign, setNavButtonsAlign] = useState(null)

  const [windowType, setWindowType] = useState(null)
  const [listHeight, setListHeight] = useState("200px")
  const [listHeight2, setListHeight2] = useState("200px")
  useEffect(()=>{
    if(windowHeight >= 648){
      setListHeight("100px")
      setListHeight2("150px")
    }else{
      setListHeight("200px")
      setListHeight2("300px")
    }

    if(windowWidth >= 425){
      setWindowType("evnt-card-lg")
      setNavButtonsAlign("nav flex-column nav-pills")
    }else{
      setWindowType("evnt-card-lg")
      setNavButtonsAlign("")
    }
  },[windowWidth])


  
  const teams = useSelector(state => state.TeamsCommon.teams)
  const [currentTeam, setCurrentTeam]=useState(teams.main)

  const chooseTeam = (team) => {
    switch(team){
      case"main":
      setCurrentTeam(teams.main)
      break;
      case"visit":
      setCurrentTeam(teams.visit)
      break;
    }
  }

  const [playerID, setPlayerID]=useState(undefined)

  const showPlayerData = (playerID) => {
    setIconWithTab('5')
    console.log("showPlayerData",playerID)
    setPlayerID(playerID)
  }

  
    return(
         <Fragment>
             <Card className={`evnt-card ${windowType}`}>
             <div onClick={()=> props.checkIfIsOpen({name:props.windowName})}><CloseCard/></div>
                <CardBody style={{color:"white"}}> 
                  <div className="team-statistics-card" style={{color:"white"}}>
                    <div>
                      { windowWidth >= 425 ? "" :
                        <h2 className="evnt-card-title"><b>{TeamStatistics}</b></h2> 
                      }
                      <Input type="select"
                        onChange={e=>chooseTeam(e.target.value)}
                       name="select" className="form-control btn-pill digits m-b-10"
                       id="exampleFormControlSelect7">
                         <option value={"main"}>{teams.main.name}</option>
                         <option value={"visit"}>{teams.visit.name}</option>
                      </Input>
                      <Nav tabs className={`evnt-statistics-menu ${navButtonsAlign}`}>
                        <NavItem>
                          <NavLink style={{cursor:"pointer"}} className={IconWithTab === '1' ? 'active' : ''} onClick={() => setIconWithTab('1')}>{Team}</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={{cursor:"pointer"}} className={IconWithTab === '2' ? 'active' : ''} onClick={() => setIconWithTab('2')}>{League}</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={{cursor:"pointer"}} className={IconWithTab === '3' ? 'active' : ''} onClick={() => setIconWithTab('3')}>{Metrics}</NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink style={{cursor:"pointer"}} className={IconWithTab === '4' ? 'active' : ''}
                            onClick={()=>{
                              props.setVRLineUp(!props.VRLineUp)
                              dispatch(ChangePerspective({toLocationId:0,toPerspectiveId:7,type:undefined}))
                              props.checkIfIsOpen({name:props.windowName})
                            }}>{"LineUp"}</NavLink>
                        </NavItem> */}
                      </Nav>
                      <br/>
                    </div>
                    <div className="evnt-statistics-data">
                      <TabContent activeTab={IconWithTab}>
                        <TabPane  className="fade show" tabId="1">
                            <TeamList 
                            showPlayerData={(playerID)=>showPlayerData(playerID)}
                            usersList={currentTeam.playersProfiles} 
                            height={listHeight}/> 
                        </TabPane>
                        <TabPane tabId="2">
                          <LeagueTable listHeight={listHeight2}/>
                        </TabPane>
                        <TabPane tabId="3">
                          <StatisticsTable metrics={currentTeam.metrics} listHeight={listHeight2}/>
                        </TabPane>
                        <TabPane tabId="5">
                          {playerID != undefined ?
                            <ProfileCardData userID={playerID} counters={false}/>:""
                          }
                        </TabPane>
                      </TabContent> 
                    </div>
                  </div>
                </CardBody>
             </Card>
         </Fragment>
    )
}

export default TeamStatisticsCard 