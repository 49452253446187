import axios from 'axios'
import React,{ useState, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingAsset } from '../WindowLoaders'
import { SetTeamsData } from '../../../../redux/common/EventActions'
import { Header } from '../../../../constant'
import { Badge } from 'reactstrap'
import '../../../../assets/css/evnt_cards.css'
import { useParams } from 'react-router'

export const Soccer = (props) => {
  const dispatch = useDispatch()
  const perspective = useSelector(state => state.PerspectiveCommon.perspectiveData)
  const [isLoading, setIsLoading] = useState(true)
  const [countDown, setCountDown] = useState("90:00")
  const secondsToCountDown = (duration) => {
    var timer = duration, minutes, seconds;
    var counterInterval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        setCountDown(minutes + ":" + seconds)

        if (--timer < 0) {
          timer = duration;
          clearInterval(counterInterval)
        }
    }, 1000);
  }

  useEffect(()=>{
    secondsToCountDown(60*90)
  },[])

  const {platformID, eventID}=useParams()
  const eventData = useSelector(state => state.EventCommon.eventData)
  const teams = useSelector(state => state.TeamsCommon.teams)
  const [LeftBar,setLeftBar] = useState(false)

  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp"
  useEffect(()=>{
    setIsLoading(true)
    
    const params = {
      platformID: platformID,
      eventID:eventID
    }

    axios.get(`${ApiURL}/api/user/platform/event/soccerTeams`, {
      params: params
    }).then((res) => {
      SetEventTeamsData(res.data).then(()=>{
        setIsLoading(false)
      })
    }).catch(error => console.error(error))
  },[platformID, eventID])


  const SetEventTeamsData = (data) =>{
    return new Promise ((resolve,reject) => {
      dispatch(SetTeamsData(data)) 
      resolve(true)
    })
  }

  if(isLoading){
    return ( 
      <LoadingAsset
      loading={Header}/>)
  }

  return(
    <Fragment>
      <div className="vertical-mobile-sidebar"><i className="fa fa-bars sidebar-bar"></i></div>
      <div className="nav-right col left-menu-header">
        <ul className={`nav-menus-left text-color ${LeftBar? 'open': ''}`}>
          <li style={{cursor:"pointer",width:"300px"}}>
            <div className={"header-match-counter"}>
              <p style={{fontSize:"18px",cursor:"pointer",marginBottom:'0.02rem'}}><Badge color="primary" pill>{teams.main.score}</Badge>&nbsp;<b>{`${teams.main.name} VS ${teams.visit.name}`}</b>&nbsp;<Badge color="primary" pill>{teams.visit.score}</Badge></p>
              <h6 style={{cursor:"pointer", textAlign:"center",width:"100%"}}><b>{eventData.venue.venueData.name}</b>&nbsp;<Badge color="primary" pill>{countDown}</Badge></h6>
            </div>
          </li>
          <li style={{cursor:"pointer",width:"300px"}}>
            <h5 style={{color:"white",marginBottom:'0.02rem'}}><b>{perspective.name}</b></h5>
            <h6 style={{color:"white"}}><b>{perspective.locationName}&nbsp; &nbsp;<i className="fa fa-eye"></i>&nbsp;{perspective.attendance+'8,7231 '}</b></h6>
          </li>
        </ul>
      </div>
  </Fragment>
  )
}


export const MusicFestival = (props) => {
  const dispatch = useDispatch()
  const [ isLoading, setIsLoading]=useState(true)
  const perspective = useSelector(state => state.PerspectiveCommon.perspectiveData)
  const eventData = useSelector(state => state.EventCommon.eventData)
  const [pespectiveName, setPerspectiveName] = useState("")
  const [LeftBar,setLeftBar] = useState(false)

  useEffect(()=>{
    if( perspective.perspectiveData != undefined){
      setPerspectiveName(perspective.perspectiveData.name)
    }
  },[ perspective])


  if(perspective == undefined && eventData == undefined){
    return ( 
      <LoadingAsset
      loading={Header}/>)
  }

  return(
    <Fragment>
      <div className="vertical-mobile-sidebar"><i className="fa fa-bars sidebar-bar"></i></div>
      <div className="nav-right col left-menu-header">
        <ul className={`nav-menus-left text-color ${LeftBar? 'open': ''}`}>
          <li style={{cursor:"pointer",width:"200px"}}>
            <div className={"header-match-counter"}>
              <h4 style={{cursor:"pointer",marginBottom:'0.02rem'}}><b>{eventData.name}</b></h4>
              <h6 style={{cursor:"pointer",width:"100%"}}><b>{eventData.venue.venueData.name}</b></h6>
            </div>
          </li>
          <li style={{cursor:"pointer",width:"300px"}}>
            <h5 style={{color:"white",marginBottom:'0.02rem'}}><b>{pespectiveName}</b></h5>
            <h6 style={{color:"white"}}><b>{perspective.locationName}&nbsp; &nbsp;<i className="fa fa-eye"></i>&nbsp;{perspective.attendance+'8,7231 '}</b></h6>
          </li>
        </ul>
      </div>
  </Fragment>
  )
}

