import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody} from 'reactstrap'
import { Fragment } from 'react'
import { Media } from 'reactstrap'
import { useDispatch } from 'react-redux';
import CloseCard from './CloseWindow'
import {ChangePerspective} from '../../../../redux/common/EventActions'

export const AdMediaSrc = (props) => {
    const dispatch = useDispatch()
    const [windowData, setWindowData]=useState(props.windowData)
    useEffect(()=>{
        setWindowData(props.windowData)
    },[props.windowData])


    const afterActivity = (actionAfterAd) => {
        switch(actionAfterAd.type){
            case"redirect":
                window.open(actionAfterAd.link,'_blank');
            break;
            case"changePerspective":
                dispatch(ChangePerspective({toLocationId:actionAfterAd.toLocationId,toPerspectiveId:actionAfterAd.toPerspectiveId,type:undefined}))
            break;
            default:
            break;
        }
        props.checkIfIsOpen({name:props.windowName})
    }

    const chooseMediaType = () => {
        console.log("chooseMediaType",windowData.media.type)
            switch(windowData.media.type){
                case"image":
                return(
                    <Media style={{width:"435px",height:"250px"}} src={windowData.media.src} /> 
                )
                case"video":
                return(
                    <Media style={{width:"435px",height:"250px"}} src={windowData.media.src} />                             
                )
                case"Youtube":
                return(
                    <iframe width="435" height="250" src={windowData.media.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>     
                )
                case"iframe":
                return(
                    <iframe width="435" height="430" src={windowData.media.src} title="3D Experience"></iframe>  
                           
                )
            }
    }

    return(
            <Fragment>
                <Card style={{margin:"50px 30px", pointerEvents:"all"}}>
                    <div onClick={()=>props.checkIfIsOpen({name:props.windowName})}><CloseCard/></div>
                    {chooseMediaType()}
                    <br/>
                    <Button onClick={()=> afterActivity(windowData.actionAfterAd)} style={{marginTop:-15,width:"100%"}} color="primary" outline>{"+50 K"}</Button> 
                </Card>
            </Fragment>
    )
    
}

export default AdMediaSrc