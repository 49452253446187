import "aframe";
import "aframe-look-at-component";
import "aframe-particle-system-component";
import "babel-polyfill";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import AFRAME from "aframe";
import { Entity, Scene } from "aframe-react";
import { PerspectiveLoader } from "./PerspectiveLoader";
import { Perspectivee } from "../../../constant/index";
import {
  ChangePerspective,
  PerspectiveZoom,
  SetPerspectiveData,
  SetLeftSideTabs,
} from "../../../redux/common/EventActions";
import VR_Streaming2D from "./perspective/VR_Streaming2D";
import VR_Ads from "./perspective/VR_Ads";
import VR_MediaGallery from "./perspective/VR_MediaGallery";
import VR_HotSpots from "./perspective/VR_HotSpots";
import NadirLogo from "./perspective/NadirLogo";
import { useParams } from "react-router-dom";
import * as THREE from "three";

const Perspective = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [perspective, setPerspective] = useState({});
  const [cameraRotation, setCamRotation] = useState("");

  const [mainHotSpotColor, setMainHotSpotColor] = useState("#EF2D5E");
  const [premiumHotSpotColor, setPremiumHotSpotColor] = useState("#f6fc30");

  const [streaming2DVideo_src, setStreaming2DVideo_src] = useState("");

  const [openLeftSideTabs, setOpenLeftSideTabs] = useState([{ type: "LogIn" }]);

  const [alphaLoader, setAlphaLoader] = useState(false);
  const [logedInUser, setLogedInUser] = useState(
    localStorage.getItem("loged_in_user")
  );

  const vrMode = useSelector((state) => state.EventCommon.vrMode);
  useEffect(() => {
    if (!isLoading) {
      const evntScene = document.getElementById("Evnt_Scene");
      var cameraVR = document.getElementById("cameraVR");
      var camera360 = document.getElementById("camera360");

      if (evntScene != null) {
        if (vrMode) {
          // dispatch(SetLeftSideTabs([]))
          evntScene.enterVR();
          camera360.setAttribute("camera", "active", false);
          cameraVR.setAttribute("camera", "active", true);
        } else {
          // dispatch(SetLeftSideTabs([]))
          evntScene.exitVR();
          camera360.setAttribute("camera", "active", true);
          cameraVR.setAttribute("camera", "active", false);
        }
        console.log("Evnt_Scene is VR ", evntScene.is("vr-mode"));
      } else {
        console.log("Evnt_Scene is ", evntScene);
      }
    }
  }, [isLoading, vrMode]);

  const locationID = useSelector((state) => state.PerspectiveCommon.locationID);
  const { platformID, eventID } = useParams();
  const perspectiveID = useSelector(
    (state) => state.PerspectiveCommon.perspectiveID
  );
  const cameraZoom = useSelector(
    (state) => state.PerspectiveCommon.perspectiveZoom
  );
  const perspectiveData = useSelector(
    (state) => state.PerspectiveCommon.perspectiveData
  );

  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    setIsLoading(true);
    setAlphaLoader(true);
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
      platformID: platformID,
      eventID: eventID,
      perspectiveID: perspectiveID,
      locationID: locationID,
    };
    axios
      .get(`${ApiURL}/api/event/perspective/`, {
        params: params,
      })
      .then((res) => {
        setPerspectiveDataa(res.data).then(() => {
          setIsLoading(false);
        });
      })
      .catch((error) => console.error(error));
  }, [platformID, eventID, perspectiveID, locationID, logedInUser]);

  const setPerspectiveDataa = (perspective) => {
    return new Promise((resolve, reject) => {
      dispatch(PerspectiveZoom(1));
      dispatch(SetPerspectiveData(perspective));
      var camRotation = perspective.camRotation;
      setCamRotation(`${camRotation.x} ${camRotation.y} ${camRotation.z}`);
      resolve(true);
    });
  };
  const [lastPerspective, setLastPerspective] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      if (perspectiveData.streaming.link != undefined) {
        const perspectiveVideo = document.getElementById("sceneVideo");
        perspectiveVideo.oncanplay = function () {
          perspectiveVideo.play();
          setLastPerspective(true);
        };
      }
    } else {
      setLastPerspective(false);
    }
  }, [isLoading]);

  if (isLoading) {
    return <PerspectiveLoader loading={Perspectivee} />;
  }

  return (
    <Fragment>
      <PerspectiveLoader
        lastLoading={lastPerspective}
        changeAlphaOfLoader={alphaLoader}
      />
      <Scene
        id="Evnt_Scene"
        vr-mode-ui={{ enabled: false }}
        loading-screen="dotsColor: purple; backgroundColor: black"
      >
        <a-assets>
          <video
            id="sceneVideo"
            src={perspectiveData.streaming.link}
            // src={
            //   "https://bitmovin-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8"
            // }
            loop={true}
            muted={true}
            crossOrigin={"anonymous"}
          ></video>
          {/* {streaming2DVideo_src != "" ?  
           <video
             id="streaming2DVideo"
             src={require(`../../../assets/video/${streaming2DVideo_src}`)}
             loop={true}
             muted={true}
             crossOrigin={"anonymous"}
           ></video>:""} */}
        </a-assets>

        {/* <a-camera id="camera360" look-controls="reverseMouseDrag: true, reverseTouchDrag: true"  zoom={cameraZoom}>
             <Entity 
               id="cursor360"
               primitive="a-cursor"
               cursor="rayOrigin: mouse"
               material="color: #ffffff; opacity: 0" 
               geometry="primitive: sphere; radius: 0"/>
         </a-camera> */}

        <a-camera
          id="camera360"
          look-controls="reverseMouseDrag: true, reverseTouchDrag: true"
          zoom={cameraZoom}
        >
          {vrMode ? (
            ""
          ) : (
            <Entity
              id="cursor360"
              primitive="a-cursor"
              cursor="rayOrigin: mouse"
              material="color: #ffffff; opacity: 0"
              geometry="primitive: sphere; radius: 0"
            />
          )}
        </a-camera>
        <a-camera
          id="cameraVR"
          look-controls="reverseMouseDrag: true, reverseTouchDrag: true"
          zoom={cameraZoom}
        >
          {vrMode ? (
            <Entity
              id="cursorVR"
              primitive="a-cursor"
              material="color: #0011ff; opacity: 1"
              animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 1 1 1; to: 3 3 3"
              animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 3 3 3; to: 1 1 1"
              animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 3 3 3"
              cursor="fuse: true;"
              position="0 0 -3"
              geometry="primitive: ring"
            />
          ) : (
            ""
          )}
        </a-camera>

        <a-videosphere
          src={"#sceneVideo"}
          rotation={cameraRotation}
        ></a-videosphere>

        <a-entity
          id="3D-assets-wrapper"
          // position={`${assetsPosition.x},${assetsPosition.y},${assetsPosition.z}`}
          position={`0,0,0`}
        >
          {perspectiveData.collections.indexOf("galleries") > 0 ? (
            console.log("This perspective have a gallery")
          ) : (
            <VR_Streaming2D
              showBanner={true}
              eventID={eventID}
              locationID={locationID}
              perspectiveID={perspectiveID}
              type={"NormalStreaming"}
            />
          )}

          <VR_Ads setVRAd={props.setVRAd} />

          <VR_HotSpots
            vrMode={vrMode}
            eventID={props.eventID}
            locationID={props.locationID}
            perspectiveID={props.perspectiveID}
            mainHotSpotColor={mainHotSpotColor}
            premiumHotSpotColor={premiumHotSpotColor}
            changePerspective={props.changePerspective}
            hotSpotHoverEnter={props.hotSpotHoverEnter}
          />
          {perspectiveData.collections.indexOf("galleries") > 0 ? (
            <VR_MediaGallery
              eventID={props.eventID}
              locationID={props.locationID}
              perspectiveID={props.perspectiveID}
              setStreaming2DVideo_src={(src) => setStreaming2DVideo_src(src)}
            />
          ) : (
            ""
          )}
        </a-entity>
        <NadirLogo />
      </Scene>
    </Fragment>
  );
};
export default Perspective;
