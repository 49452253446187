import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  Media,
} from "reactstrap";
import "../../../../assets/css/evnt_cards.css";
import "../../../../assets/css/evnt_general.css";
import { Fragment } from "react";
import {
  SelectMonth,
  SelectYear,
  Purchasing,
  Purchase,
} from "../../../../constant";
import CloseCard from "./CloseWindow";
import { useDispatch } from "react-redux";
import { ChangePerspective } from "../../../../redux/common/EventActions";
const PaymentCard = (props) => {
  const dispatch = useDispatch();
  const [paymentDetails, setPaymentDetails] = useState(props.windowData);
  useEffect(() => {
    setPaymentDetails(props.windowData);
  }, [props.windowData]);

  const purchaseItem = (type) => {
    switch (type) {
      case "perspective":
        dispatch(
          ChangePerspective({
            toLocationId: paymentDetails.toLocationId,
            toPerspectiveId: paymentDetails.toPerspectiveId,
            type: paymentDetails.type,
          })
        );
        break;
      case "tokens":
        console.log("Tokens Purchased");
        break;
      default:
        console.log("Item Purchased");
        break;
    }
  };

  const windowWidth = props.windowWidth;
  const [windowType, setWindowType] = useState(null);
  useEffect(() => {
    if (windowWidth <= 1000 && windowWidth >= 425) {
      setWindowType("evnt-card-lg");
    } else {
      setWindowType("evnt-card-md");
    }
  }, [windowWidth]);

  return (
    <Fragment>
      <Card className={`evnt-card ${windowType}`}>
        <div onClick={() => props.checkIfIsOpen({ name: props.windowName })}>
          <CloseCard />
        </div>
        <CardBody style={{ color: "white" }}>
          <div className="location-item-layout">
            <h2 className="evnt-card-title">
              <b>{Purchasing + ` ${paymentDetails.item}`}</b>
            </h2>
            <div className="">
              {/* <Media
                className="img-fluid card-thumbnail"
                src={require("../../../../assets/images/ecommerce/card.png")}
                alt=""
              /> */}
              <div>
                <Input
                  className="form-control payment-card-field"
                  type="text"
                  placeholder="Full name here"
                />
                <Input
                  className="form-control payment-card-field"
                  type="text"
                  placeholder="Card number"
                />
                <div className="card-data-row payment-card-field">
                  <select className="form-control" size="1">
                    {SelectMonth.map((months, i) => (
                      <option key={i}>{months}</option>
                    ))}
                  </select>
                  <select className="form-control" size="1">
                    {SelectYear.map((years, i) => (
                      <option key={i}>{years}</option>
                    ))}
                  </select>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="CVC"
                  />
                </div>
                <Button
                  color="primary-gradien"
                  className="btn-block payment-card-field"
                  onClick={() => {
                    props.checkIfIsOpen({ name: props.windowName });
                    purchaseItem(paymentDetails.itemToPurchase);
                  }}
                >
                  {Purchase +
                    ` - $ ${paymentDetails.price.amount} ${paymentDetails.price.currency}`}
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default PaymentCard;
