import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Media,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import {
  Email,
  RegisterUser,
  AcceptTermsConditions,
  ConfirmPassword,
  ImRegistered,
  EditAvatar,
  Password,
  SignUp,
  LastName,
  FirstName,
  BirthDate,
  Username,
  Gender,
  SelectGender,
  Male,
  Female,
  NotBinary,
  PasswordConfirmationMustMatch,
} from "../constant";
import { toast } from "react-toastify";
import "../assets/css/evnt_cards.css";
import defaultProfilePicture from "../assets/images/defaultProfilePicture.jpg";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { useSelector } from "react-redux";
import LabeledInput from "../components/general/LabeledInput";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export const SignUpCard = (props) => {
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  const history = useHistory();
  const { platformID, eventID } = useParams();
  const ValidatePassword = () => {
    var passwordValidator = require("password-validator");
    var schema = new passwordValidator();
    schema.is().min(8); // Minimum length 8
    schema.is().max(14); // Maximum length 100
    schema.has().uppercase(); // Must have uppercase letters
    schema.has().lowercase(); // Must have lowercase letters
    schema.has().digits(2); // Must have at least 2 digits
    schema.has().not().spaces(); // Should not have spaces
    schema.is().not().oneOf(["Password", "Password123"]); // Blacklist these values
    const validationMsj = schema.validate(password, { details: true });
    toast.dismiss();
    if (password != passwordConfirm) {
      toast.error(PasswordConfirmationMustMatch, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 15000,
      });
    } else if (validationMsj.length > 0) {
      validationMsj.map((item) => {
        toast.error(`Password: ${item.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 15000,
        });
      });
    } else {
      registerUser();
    }
  };
  const registerUser = () => {
    axios
      .post(`${ApiURL}/api/user/createUser`, {
        userName: nickName,
        birthDate: birthDate,
        firstName: userFirstName,
        lastName: userLastName,
        gender: gender,
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.message != undefined) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          logInUser();
        }
      })
      .catch((error) => {
        console.log(error);
        const errorDetails = error.response.data.error.details;
        errorDetails.map((error) => {
          toast.error(`${error.context.key} ${error.type}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      });
  };

  const logInUser = async () => {
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setProfilePicture(userCredential.user.proactiveRefresh.user.photoURL || defaultProfilePicture );
        setName(userCredential.user.proactiveRefresh.user.displayName);
        setLogedInUser(true);
        history.push(
          `${process.env.PUBLIC_URL}/VR360/${platformID}/${eventID}`
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  const [nickName, setNickName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const platformLogo = useSelector(
    (state) => state.PlatformCommon.platformLogo
  );
  const [profilePicture, setProfilePicture] = useState(
    localStorage.getItem("profileURL" || defaultProfilePicture)
  );

  const [name, setName] = useState(localStorage.getItem("Name"));
  const [logedInUser, setLogedInUser] = useState(false);

  useEffect(() => {
    localStorage.setItem("profileURL", profilePicture);
    localStorage.setItem("Name", name);
    localStorage.setItem("loged_in_user", logedInUser);
  }, [profilePicture, name, logedInUser]);

  return (
    <Fragment>
      <Card className={`evnt-card sign-in-card evnt-card-lg`}>
        <CardBody style={{ color: "white" }}>
          <div className="log-in-form">
            <div>
              <a href={platformLogo.redirect} target="_blanck">
                <img
                  className={`lightlogo sign-in-logo`}
                  src={platformLogo.main}
                  alt=""
                />
              </a>
              <p className="pull-right">
                <b>{"Powered by EVNT"}</b>
                <br />
                <h2 className="evnt-card-title pull-right m-t-5">
                  <b>{SignUp}</b>
                </h2>
              </p>
            </div>
            <Form className="needs-validation m-t-10" noValidate="">
              <Row>
                <Col md="3">
                  <div className="sign-in-avatar">
                    <Media
                      body
                      className="img-100 rounded-circle"
                      src={require("../assets/images/defaultProfilePicture.jpg")}
                      data-intro="This is Profile image"
                    />
                    <Button
                      className={"pull-left btn-xs m-t-10"}
                      color="primary"
                      onClick={(event) => console.log("EditAvar")}
                    >
                      {EditAvatar}&nbsp;
                      <i className="icofont icofont-pencil-alt-5"></i>
                    </Button>
                  </div>
                </Col>
                <Col md="9">
                  <div className="form-row">
                    <Col md="6 mb-6">
                      <LabeledInput
                        label={FirstName}
                        placeholder={FirstName}
                        value={userFirstName}
                        type="text"
                        onChange={(event) =>
                          setUserFirstName(event.target.value)
                        }
                      />
                    </Col>
                    <Col md="6 mb-6">
                      <LabeledInput
                        label={LastName}
                        placeholder={LastName}
                        value={userLastName}
                        type="text"
                        onChange={(event) =>
                          setUserLastName(event.target.value)
                        }
                      />
                    </Col>
                    <Col md="4 mb-4">
                      <LabeledInput
                        label={Username}
                        placeholder={Username}
                        value={nickName}
                        type="text"
                        onChange={(event) => setNickName(event.target.value)}
                      />
                    </Col>
                    <Col md="4 mb-4">
                      <LabeledInput
                        label={BirthDate}
                        placeholder={BirthDate}
                        value={birthDate}
                        type="date"
                        onChange={(event) => setBirthDate(event.target.value)}
                      />
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationGender">{Gender}</Label>
                      <Input
                        className="custom-select"
                        id="validationGender"
                        name="Gender"
                        type="select"
                        placeholder={Gender}
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="">{SelectGender}</option>
                        <option value="Male">{Male}</option>
                        <option value="Female">{Female}</option>
                        <option value="NotBinary">{NotBinary}</option>
                      </Input>
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="form-row">
                    <Col md="12 mb-12">
                      <LabeledInput
                        label={Email}
                        placeholder={Email}
                        value={email}
                        type="email"
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </Col>
                    <Col md="6 mb-6">
                      <LabeledInput
                        label={Password}
                        placeholder={Password}
                        value={password}
                        type="password"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </Col>
                    <Col md="6 mb-6">
                      <LabeledInput
                        label={ConfirmPassword}
                        placeholder={ConfirmPassword}
                        value={passwordConfirm}
                        type="password"
                        onChange={(event) =>
                          setPasswordConfirm(event.target.value)
                        }
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
              <FormGroup>
                <Button
                  className={"pull-left m-t-10"}
                  color="primary"
                  onClick={ValidatePassword}
                >
                  {RegisterUser}
                </Button>
                <div className={"pull-right"}>
                  <Row>
                    <Col md="12 mb-6">
                      <div className="form-check pull-right">
                        <div className="checkbox p-0">
                          <Input
                            className="form-check-input"
                            id="termsCons"
                            name="termsCons"
                            type="checkbox"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="termsCons"
                          >
                            {AcceptTermsConditions}
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col md="12 mb-6">
                      <a
                        className={"pull-right"}
                        style={{ marginTop: "-10px" }}
                        href="#"
                        onClick={() => {
                          props.setSignUp(false);
                        }}
                      >
                        <b>{ImRegistered}</b>
                      </a>
                    </Col>
                  </Row>
                </div>
              </FormGroup>
            </Form>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default SignUpCard;
