import React from 'react'
import { Card, CardBody, Media, Badge } from 'reactstrap'
import { Fragment } from 'react'
import { Notifications } from '../../../../constant'
import CloseCard from './CloseWindow'
import NotificationsList from '../../../../components/general/NotificationsList'
import '../../../../assets/css/evnt_cards.css'
import { LoadingAsset } from '../WindowLoaders'

export const Notifications360 = (props) => {
    return(
        <Fragment> 
            <Card style={{margin:"0px 30px", width:"300px", height:"400px", pointerEvents:"all"}}>
            <div onClick={()=> props.checkIfIsOpen({name:props.windowName})}><CloseCard/></div>
                <CardBody>
                  <p className="evnt-card-title"><b>{Notifications}</b></p>
                  <NotificationsList type="comments" height={"280px"}/>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default Notifications360