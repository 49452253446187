export const Event_Settings = "Event_Settings"
export const Event_Loader_Logo = "Event_Loader_Logo"
export const Event_Type = "Event_Type"


export const Add_Left_Tab = "Add_Left_Tab"

export const Change_Perspective = "Change_Perspective"

export const Remove_Left_Tab = "Remove_Left_Tab"

export const Event_Attendance = "Event_Attendance"

export const Set_EventCaster = "Set_EventCaster"

export const Set_Teams_Data = "Set_Teams_Data"
export const Set_Platform_Settings = "Set_Platform_Settings"
export const Set_Vr_Mode = "Set_Vr_Mode"
export const Set_Event_Comments = "Set_Event_Comments"
export const Set_Perspective_Data = "Set_Perspective_Data"
export const Perspective_Zoom = 'Perspective_Zoom'
