import React, { Fragment, useCallback, useEffect, useLayoutEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from "axios"
import { LogInCard } from '../../auth/LogInCard'
import { LogInCatalog_Card } from '../../components/VR/360Video/Layer_2D_Components/LogIn_page'
import {LoadingAsset} from '../../components/common/WindowLoaders'
import Perspective from '../../components/VR/common/Perspective'
 
import { ChangePerspective } from '../../redux/common/EventActions'
import SignUpCard from '../../auth/SignUpCard'

const LogIn360 = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [signUp, setSignUp] = useState(false)

  const windowWidth = useWindowWidthSize()
  function useWindowWidthSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
  }
  const windowHeight = useWindowHeightSize()
  function useWindowHeightSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerHeight);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
  }

  const [eventID , setEventID] = useState(1)
  const locationID = useSelector(state => state.PerspectiveCommon.locationID)
  const perspectiveID = useSelector(state => state.PerspectiveCommon.perspectiveID)

  return (
    <Fragment>
      <div className={"layer-2d-log-in"}>
          {signUp ? 
          <SignUpCard
           windowWidth={windowWidth}
           windowHeight={windowHeight}
           setSignUp={(type)=>setSignUp(type)}/>:
          <LogInCard
           windowWidth={windowWidth}
           windowHeight={windowHeight}
           setSignUp={(type)=>setSignUp(type)}/>
           }

          {/* <LogInCatalog_Card /> */}
      </div>
      <div className="page-wrapper compact-page">
        <div className="page-body-wrapper">
            <Perspective eventID={eventID} locationID={locationID} perspectiveID={perspectiveID}/>
        </div>
      </div>
    </Fragment>
  );
}
export default LogIn360;
