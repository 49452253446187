import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Input, Label, Row, Col } from "reactstrap";
import { LoadingAsset } from "../../../components/common/WindowLoaders";
import LabeledDropdown from "../../Admin/layout/FilterDropdown";
import {
  ChoosePlatform,
  Platform,
  PlatformsListt,
  Eventt,
  ChooseEvent,
  EventsListt,
  LocationsListt,
  ChooseLocation,
  PerspectivesListt,
  ChoosePerspective,
  ControlPanel,
  Locationss,
  Location,
  Platforms,
  Events,
  Perspectives,
  Perspective,
  TheSelectedPlatformDoesNotHaveEvents,
  CreateOneToContinue,
  TheSelectedEventDoesNotHaveLocations,
  TheSelectedLocationDoesNotHavePerspectives,
} from "../../../constant";
import { toast } from "react-toastify";

const FilterGenerator = (props) => {
  const history = useHistory();
  const { platformID, eventID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [platforms, setPlatforms] = useState();
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";



  const [filteredPlatform, setFilteredPlatform] = useState("");
  useEffect(() => {
    if (filteredPlatform.value == undefined) {
      getPlatforms();
    } else {
      props.createDocument({
        btnLabel: Platform,
        path: `Admin/ControlPanel/Platform/Form`,
      });
    }
  }, [filteredPlatform]);
  const getPlatforms = () => {
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
    };
    axios
      .get(`${ApiURL}/api/admin/controlPanel/platforms`, {
        params: params,
      })
      .then((res) => {
        SetPlatformList(res.data).then(() => {
          setIsLoading(false);
        });
      })
      .catch((error) => console.error(error));
  };
  const SetPlatformList = (platforms) => {
    return new Promise((resolve, reject) => {
      const reducePlatforms = platforms.reduce((platforms, platform) => {
        return platforms.concat({
          ...platform.platformData,
          id: platform.id,
          editPath: `Admin/ControlPanel/Platform/${platform.id}/Form`,
        });
      }, []);
      setPlatforms(reducePlatforms);
      props.filteredItems(reducePlatforms);
      props.breadCrumbTitle(`${ControlPanel} / ${Platforms}`);
      props.createDocument({
        btnLabel: Platform,
        path: `Admin/ControlPanel/Platform/Form`,
      });
      resolve(true);
    });
  };



  const [filteredEvent, setFilteredEvent] = useState("");
  const [events, setEvents] = useState([]);
  useEffect(() => {
    if (filteredPlatform.value != "" && filteredPlatform.value != undefined) {
      getEventsList();
    } else {
      setEvents([]);
      setLocations([]);
      setPerspectives([]);
    }
  }, [filteredPlatform]);
  const getEventsList = () => {
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
      platformID: filteredPlatform.value,
    };
    axios
      .get(`${ApiURL}/api/admin/controlPanel/platform/events`, {
        params: params,
      })
      .then((res) => {
        SetEventsList(res.data);
      })
      .catch((error) => console.error(error));
  };
  const SetEventsList = (events) => {
    return new Promise((resolve, reject) => {
      const createEvent = `Admin/ControlPanel/Platform/${filteredPlatform.value}/Event/Form`;
      if (events.length > 0) {
        const reducedEvents = events.reduce((events, event) => {
          return events.concat({
            ...event.eventData,
            id: event.id,
            editPath: `Admin/ControlPanel/Platform/${filteredPlatform.value}/Event/${event.id}/Form`,
          });
        }, []);
        setEvents(reducedEvents);
        props.filteredItems(reducedEvents);
        props.breadCrumbTitle(
          `${ControlPanel} / ${filteredPlatform.label} / ${Events}`
        );
        props.createDocument({ btnLabel: Eventt, path: createEvent });
      } else {
        toast.warning(
          `${TheSelectedPlatformDoesNotHaveEvents}... ${CreateOneToContinue}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 15000,
          }
        );
        history.push(`${process.env.PUBLIC_URL}/${createEvent}`);
      }
      resolve(true);
    });
  };




  const [filteredLocation, setFilteredLocationID] = useState("");
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    if (filteredEvent.value != "" && filteredEvent.value != undefined) {
      getLocationsList();
    } else {
      if (!isLoading) {
        getEventsList();
      }
      setLocations([]);
      setPerspectives([]);
    }
  }, [filteredEvent]);
  const getLocationsList = () => {
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
      platformID: filteredPlatform.value,
      eventID: filteredEvent.value,
    };
    axios
      .get(`${ApiURL}/api/admin/controlPanel/platform/event/locations`, {
        params: params,
      })
      .then((res) => {
        setLocationsList(res.data);
      })
      .catch((error) => console.error(error));
  };
  const setLocationsList = (locations) => {
    return new Promise((resolve, reject) => {
      const createLocation = `Admin/ControlPanel/Platform/${filteredPlatform.value}/Event/${filteredEvent.value}/Location/Form`;
      if (locations.length > 0) {
        const reduceLocations = locations.reduce((locations, location) => {
          return locations.concat({
            ...location.locationData,
            id: location.id,
            editPath: `Admin/ControlPanel/Platform/${filteredPlatform.value}/Event/${filteredEvent.value}/Location/${location.id}/Form`,
          });
        }, []);
        setLocations(reduceLocations);
        props.filteredItems(reduceLocations);
        props.breadCrumbTitle(
          `${ControlPanel} / ${filteredPlatform.label} / ${filteredEvent.label} / ${Locationss}`
        );
        props.createDocument({ btnLabel: Location, path: createLocation });
      } else {
        toast.warning(
          `${TheSelectedEventDoesNotHaveLocations}... ${CreateOneToContinue}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 15000,
          }
        );
        history.push(`${process.env.PUBLIC_URL}/${createLocation}`);
      }
      resolve(true);
    });
  };




  const [filteredPerspectvie, setFilteredPerspectvie] = useState("");
  const [perspectives, setPerspectives] = useState([]);
  useEffect(() => {
    if (filteredLocation.value != "" && filteredLocation.value != undefined) {
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: filteredPlatform.value,
        eventID: filteredEvent.value,
        locationID: filteredLocation.value,
      };
      axios
        .get(
          `${ApiURL}/api/admin/controlPanel/platform/event/locations/perspectives`,
          {
            params: params,
          }
        )
        .then((res) => {
          setPerspectivesList(res.data);
        })
        .catch((error) => console.error(error));
    } else {
      if (!isLoading) {
        getLocationsList();
      }
      setPerspectives([]);
    }
  }, [filteredLocation]);
  const setPerspectivesList = (perspectives) => {
    return new Promise((resolve, reject) => {
      const createPerspective = `Admin/ControlPanel/Platform/${filteredPlatform.value}/Event/${filteredEvent.value}/Location/${filteredLocation.value}/Perspective/Form`
      if(perspectives.length > 0){
        const reducePerspectives = perspectives.reduce(
          (perspectives, perspective) => {
            return perspectives.concat({
              ...perspective.perspectiveData,
              id: perspective.id,
              itemData:perspective.perspectivePada,
              editPath: `Admin/ControlPanel/Platform/${filteredPlatform.value}/Event/${filteredEvent.value}/Location/${filteredLocation.value}/Perspective/${perspective.id}/Form`,
            });
          },
          []
        );
        setPerspectives(reducePerspectives);
        props.filteredItems(reducePerspectives);
        props.breadCrumbTitle(
          `${ControlPanel} / ${filteredPlatform.label} / ${filteredEvent.label} / ${filteredLocation.label} / ${Perspectives}`
        );
        props.createDocument({
          btnLabel: Perspective,
          path:createPerspective,
        });

      } else {
        toast.warning(
          `${TheSelectedLocationDoesNotHavePerspectives}... ${CreateOneToContinue}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 15000,
          }
        );
        history.push(`${process.env.PUBLIC_URL}/${createPerspective}`);
      }
      resolve(true);
    });
  };




  useEffect(() => {
    if (
      filteredPerspectvie.value != "" &&
      filteredPerspectvie.value != undefined
    ) {
      history.push(
        `${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${filteredPlatform.value}/Event/${filteredEvent.value}/Location/${filteredLocation.value}/Perspective/${filteredPerspectvie.value}/Form`
      );
    }
  }, [filteredPerspectvie]);

  if (isLoading) {
    return <LoadingAsset loading={PlatformsListt} />;
  }

  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <LabeledDropdown
            array={platforms}
            label={PlatformsListt}
            placeHolder={ChoosePlatform}
            onChange={(option) => setFilteredPlatform(option)}
          />
        </Col>
        <Col lg="12">
          <LabeledDropdown
            array={events}
            label={EventsListt}
            disabled={events.length == 0 ? true : false}
            placeHolder={ChooseEvent}
            onChange={(option) => setFilteredEvent(option)}
          />
        </Col>
        <Col lg="12">
          <LabeledDropdown
            array={locations}
            label={LocationsListt}
            disabled={locations.length == 0 ? true : false}
            placeHolder={ChooseLocation}
            onChange={(option) => setFilteredLocationID(option)}
          />
        </Col>
        <Col lg="12">
          <LabeledDropdown
            array={perspectives}
            label={PerspectivesListt}
            disabled={perspectives.length == 0 ? true : false}
            placeHolder={ChoosePerspective}
            onChange={(option) => setFilteredPerspectvie(option)}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default FilterGenerator;
