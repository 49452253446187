import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardBody,Row, Input, Label, Col} from 'reactstrap'
import { Fragment } from 'react'
import { EvntStudio, HalfTimeShow, Instructions, ModeSettings, Settings, VideoQuality } from '../../../../constant'
import {ChangePlatformType} from '../../../../redux/common/actions'
import {ChangePerspective, SetVrMode} from '../../../../redux/common/EventActions'
import CloseCard from './CloseWindow'
import '../../../../assets/css/evnt_cards.css'

export const Settings360 = (props) => {
  const dispatch = useDispatch()
  const windowWidth = props.windowWidth
  const [windowType, setWindowType]=useState(null)
  
  useEffect(()=>{
      if(windowWidth<= 1000 && windowWidth >= 425){
          setWindowType("evnt-card-lg")
      }else{
          setWindowType("evnt-card-md")
      }
  },[windowWidth])

  return(
    <Fragment>
    <Card className={`evnt-card ${windowType}`}>
        <div onClick={()=> props.checkIfIsOpen({name:props.windowName})}><CloseCard/></div>
        <CardBody style={{color:"white"}}>
        <h2 className="evnt-card-title"><b>{Settings}</b></h2>
        <div className="location-item-layout">
          <div>
            <Row>
                <label className="col-form-label col-sm-4 pt-0"><b>{ModeSettings}</b></label>
                <div className="col-sm-8">
                  <div className="form-group m-checkbox-inline mb-0 ml-3">
                    <div className="radio radio-primary" onClick={() => {
                        dispatch(SetVrMode(true)) 
                      }}>
                      <Input  type="radio" name="ModeSettings" id="radio2" value="VR"/>
                      <Label for="radio2">{"VR"}</Label>
                    </div>
                    <div className="radio radio-primary">
                        <Input  type="radio" name="ModeSettings" id="radio2" value="AR" />
                        <Label for="radio2">{"AR"}</Label>
                    </div>
                    <div className="radio radio-primary ml-4">
                      <Input  type="radio" name="ModeSettings" id="radio3" value="2D"/>
                      <Label for="radio3">{"2D"}</Label>
                    </div>
                  </div>
                </div>
            </Row>
            <hr/>
            <Row>
                <label className="col-form-label col-sm-4 pt-0"><b>{VideoQuality}</b></label>
                <div className="col-sm-8">
                  <div className="form-group m-checkbox-inline mb-0 ml-3">
                    <div className="radio radio-primary">
                      <Input  type="radio" name="VideoQuality" id="radio1" value="option3" />
                      <Label for="radio1">{"720"}</Label>
                    </div>
                    <div className="radio radio-primary">
                        <Input  type="radio" name="VideoQuality" id="radio2" value="option3" />
                        <Label for="radio2">{"HD"}</Label>
                    </div>
                    <div className="radio radio-primary ml-4">
                      <Input  type="radio" name="VideoQuality"  id="radio3" value="option3" />
                      <Label for="radio3">{"4K"}</Label>
                    </div>
                  </div>
                </div>
            </Row>
          </div>
          <div> 
            <Row>
              <Col lg="6">
                <ul>
                    <li style={{margin:"10px 5px",cursor:"pointer"}} onClick={() => props.checkIfIsOpen({name:"RemoteControl"})} ><i className="mdi mdi-remote"></i>{" Remote"}</li>
                    <li style={{margin:"10px 5px",cursor:"pointer"}}><a style={{color:"white"}} href="https://xtend.live/xtend_studio/xtend_studio.php" target="_blank"><i className="fa fa-video-camera fa-sm"></i>&nbsp; {EvntStudio}</a></li>
                    <li style={{margin:"10px 5px",cursor:"pointer"}}><i className="fa fa-star fa-sm"></i>{" Upgrade Perspectives"}</li>
                </ul>
              </Col>
              <Col lg="6">
                <ul>
                    <li style={{margin:"10px 5px",cursor:"pointer"}}><i className= "mdi mdi-cast-connected"></i>{" Cast Experience"}</li>
                    <li style={{margin:"10px 5px",cursor:"pointer"}} onClick={() => props.setTourInstructions(true)} ><i className="fa fa-info-circle"></i>&nbsp;{Instructions}</li>
                    <li style={{margin:"10px 5px",cursor:"pointer"}} onClick={() => dispatch(ChangePerspective({toLocationId:0,toPerspectiveId:1,type:undefined}))} ><i className="mdi mdi-remote"></i>{HalfTimeShow}</li>
                </ul>
              </Col>
            </Row>
          </div>
        </div> 
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default Settings360