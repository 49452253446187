import React, { useEffect, useLayoutEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import man from '../../../../assets/images/dashboard/user.png'
import {HeaderUserThumbnail} from '../../../../components/general/UserThumbnail';
import '../../../../assets/css/evnt_cards.css'
import PlatformSelector from '../../../PlatformSelector'
const UserMenu = (props) => {
  
  const dispatch = useDispatch()
  const platformType = useSelector(state => state.EvntState.type)

  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return(
    <div className="pull-right d-flex">
      <HeaderUserThumbnail  checkIfIsOpen={props.checkIfIsOpen}/>
      <div className="step14 m-10" onClick={()=> props.checkIfIsOpen({name:"Notifications360"})}>
        <i className="fa fa-bell fa-2x text-color"></i><span style={{right:"10px"}} className="notification badge badge-pill badge-danger">{"2"}</span>
      </div>
      <div className="step15 m-10" onClick={()=> props.checkIfIsOpen({name:"UsersList360"})}>
        <i className="fa fa-users fa-2x text-color"></i>
      </div>
      {/* <div>
       <PlatformSelector/>
      </div> */}
    </div> 
  )
}


export default UserMenu