import React, { useState,useCallback,useEffect } from 'react'
import {useParams} from 'react-router-dom'
import {Row, Col, Container,Breadcrumb,BreadcrumbItem,Button,Media} from 'reactstrap'
import {Save,Cancel,Add,Edit,Statistics,List,Settings, ControlPanel, BreadCrumbRoute} from "../constant"
import {MENUITEMS} from './sidebar/menu'
import {Link, Switch, useHistory} from 'react-router-dom'
import { Tooltip } from 'reactstrap'
import {useSelector} from 'react-redux'
import {CarouselTopBanners} from '../components/advance/carousel/CarouselComponent'
import axios from 'axios'
import { LoadingAsset } from '../components/common/WindowLoaders'

const Breadcrumbs = (props) => {
    
    //USER ADMIN
    const userAdmin = true;
    const event = useSelector(state => state.Common.currentEvent)
    // const banners = useSelector(state => state.Common.currentEvent.banners)  
    // const bannersSettings = useSelector(state => state.Common.currentEvent.settings.banners)   
    const mainType = useSelector(state => state.Common.mainType)


    // eslint-disable-next-line
    const [breadcrumb, setBreadcrumb] = useState(props);
    // eslint-disable-next-line
    const [mainmenu, setMainMenu] = useState(MENUITEMS);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState(false);
    const [bookmarkSearch, SetBookmarkSearch] = useState(false)
    const [bookmarkItems, setBookmarkItems] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    // eslint-disable-next-line
    const [targetName, setTargetName] = useState('');

    const docType = useSelector(state => state.Common.docType)

    const toggle = targetName => {
    setTooltipOpen()
    if (!targetName) {
        setTargetName({
            ...targetName,
            targetName: {
                tooltipOpen: true
            }
        });
    } else {
        setTargetName({
            ...targetName,
            targetName: {
                tooltipOpen: !targetName.tooltipOpen
            }
        });
    }
    };

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setSearchValue('')
            setSearchResult([])
            SetBookmarkSearch(false)
            document.querySelector(".filled-bookmark").classList.remove('is-open');
            document.querySelector(".page-wrapper").classList.remove("offcanvas-bookmark");
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        mainmenu.filter(menuItems => {
        if (menuItems.bookmark) {
            setBookmarkItems(bookmarkItems => [...bookmarkItems, menuItems])
        }
        return menuItems
        
        });
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [mainmenu,escFunction]);

    const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix()
    const items = [];
    setSearchValue(keyword)
    mainmenu.filter(menuItems => {
        if (menuItems.title.toLowerCase().includes(keyword) && menuItems.type === 'link') {
            items.push(menuItems);
        }
        if (!menuItems.children) return false
        menuItems.children.filter(subItems => {
            if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
                subItems.icon = menuItems.icon
                items.push(subItems);
            }
            if (!subItems.children) return false
            subItems.children.filter(suSubItems => {
                if (suSubItems.title.toLowerCase().includes(keyword)) {
                    suSubItems.icon = menuItems.icon
                    items.push(suSubItems);
                }
                return suSubItems
            })
            return subItems
        })
        checkSearchResultEmpty(items)
        setSearchResult(items);
        return menuItems
    });
    }

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            document.querySelector(".empty-bookmark").classList.add('is-open');
        } else {
            document.querySelector(".empty-bookmark").classList.remove('is-open');
        }
    }

    const addFix = () => {
        document.querySelector(".filled-bookmark").classList.add('is-open');
        document.querySelector(".page-wrapper").classList.add("offcanvas-bookmark");
    }

    const removeFix = () => {
        setSearchValue('')
        setSearchResult([])
        document.querySelector(".filled-bookmark").classList.remove('is-open');
        document.querySelector(".page-wrapper").classList.remove("offcanvas-bookmark");
    }

    const addToBookmark = (event, items) => {
    const index = bookmarkItems.indexOf(items);
    if (index === -1 && !items.bookmark) {
        items.bookmark = true;
        event.currentTarget.classList.add('starred');
        setBookmarkItems([...bookmarkItems, items])
    } else {
        event.currentTarget.classList.remove('starred');
        bookmarkItems.splice(index, 1);
        setBookmarkItems(bookmarkItems)
        items.bookmark = false;
    }
    }

    const removeOffcanvas = () => {
        if (bookmarkSearch) {
            setSearchValue('')
            setSearchResult([])
            document.querySelector(".filled-bookmark").classList.remove('is-open');
            document.querySelector(".page-wrapper").classList.remove("offcanvas-bookmark");
        }
        SetBookmarkSearch(!bookmarkSearch)
    }

    const history = useHistory();
    const CreateNewDoc = () => {
        switch(docType){
            case'EventHome':
                return history.push(`${process.env.PUBLIC_URL}/Pages/Shows/ShowViews/ShowView`);
            break;
            case'Location':
                return history.push(`${process.env.PUBLIC_URL}/Pages/Venue/LocationForm`);
            break;
            case'Show':
                return history.push(`${process.env.PUBLIC_URL}/Pages/Shows/ShowViews/ShowView`);
            break;
            case'Experience':
                return history.push(`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`);
            break;
            case'Sponsor':
                return history.push(`${process.env.PUBLIC_URL}/Pages/Sponsors/Sponsor2D`);
            break;
            case'Product':
                return history.push(`${process.env.PUBLIC_URL}/ecommerce/product-detail/2`);
            break;
        }
        console.log(docType)
    }




    const [isLoading, setIsLoading] = useState(true);
    const {platformID, eventID, locationID, perspectiveID}=useParams()
    const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";

    const [platformName, setPlatformName]=useState("")
    const [eventName, setEventName]=useState("")
    const [locationName, setLocationName]=useState("")
    const [perspectiveName, setPerspectiveName]=useState("")
    
  useEffect(() => {
    const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID, 
        locationID: locationID, 
        perspectiveID:perspectiveID
      };
      axios
        .get(`${ApiURL}/api/admin/controlPanel/breadcrumb`, {
          params: params,
        })
        .then((res) => {
            SetRoute(res.data).then(()=>{
                setIsLoading(false)
            })
        })
        .catch((error) => console.error(error))

  }, [platformID, eventID, locationID, perspectiveID])

  const SetRoute = (route) => {
    return new Promise((resolve, reject) => {
        setPlatformName(route.platform)
        setEventName(route.event)
        setLocationName(route.location)
        setPerspectiveName(route.perspective)
      resolve(true);
    });
  }

  if (isLoading) {
    return <LoadingAsset loading={BreadCrumbRoute} />;
  }

    return (
        <Container fluid={true}>
        <div className="page-header">
            <Row>
                <Col lg='6'>
                    <h3>{breadcrumb.title}</h3>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={`${process.env.PUBLIC_URL}/Admin/ControlPanel`}>{ControlPanel}</Link>
                        </BreadcrumbItem>
                        {platformID != undefined ?
                            <BreadcrumbItem>
                                <Link to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Form`}>{platformName}</Link>
                            </BreadcrumbItem>: ""} 
                        {eventID != undefined ?
                        <BreadcrumbItem>
                            <Link to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Form`}>{eventName}</Link>
                        </BreadcrumbItem>: ""} 
                        {locationID != undefined ?
                        <BreadcrumbItem>
                            <Link to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Location/${locationID}/Form`}>{locationName}</Link>
                        </BreadcrumbItem>: ""} 
                        {perspectiveID != undefined ?
                        <BreadcrumbItem>
                            <Link to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Location/${locationID}/Perspective/${perspectiveID}/Form`}>{perspectiveName}</Link>
                        </BreadcrumbItem>: ""} 
                    </Breadcrumb>
                </Col>
                {userAdmin? 
                <Col lg='6'>
                    <div className="bookmark pull-right">
                        <ul>
                            {breadcrumb.addBtn? 
                                <li> 
                                    <Button color="primary" size="sm" onClick={() => CreateNewDoc()} ><i className="fa fa-plus-circle fa-lg"></i>&nbsp; &nbsp;{Add}</Button> &nbsp; 
                                </li>
                            :''}

                            {breadcrumb.statisticsBTN ? 
                                <li> 
                                    <Link to={`${process.env.PUBLIC_URL}/dashboard/hospital`}> 
                                    &nbsp; <Button color="primary" size="sm"><i className="fa fa-bar-chart fa-lg"></i>&nbsp; &nbsp;{Statistics}</Button> &nbsp;
                                    </Link>
                                </li>
                            :''} 
                        </ul>
                    </div>
                </Col>:''}
                {/* <Col lg="12">
                    <br/>
                    {bannersSettings.display && banners.length > 0 && mainType == "1D" || mainType == "2D" ? <CarouselTopBanners eventBanners={banners}/> : <hr/>}
                </Col> */}
            </Row>
        </div>
        </Container>
    )
}

export default Breadcrumbs
