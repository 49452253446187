import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "../../../../assets/css/evnt_admin.css";

import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

import {
  LocationDetailss,
  GeneralInformation,
  Settings,
  EventCasters,
  EventLogIn,
  HotSpotsProfiles,
  LoaderLogo,
  NadirLogo,
  SpecialPerspectives,
  Perspectives,
  AddPerspective,
} from "../../../../constant";

import { useSelector, useDispatch } from "react-redux";

import LocationData from "./locationDetails/LocationData";
import {
  SettingsEventCasters,
  SettingsHotSpotsProfiles,
  SettingsLoaderLogo,
  SettingsLogIn,
  SettingsNadirLogo,
  SettingsSpecialPerspectives,
} from "./locationDetails/Settings";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const LocationDetails = (props) => {
  const [VerticleTab, setVerticleTab] = useState("1");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { platformID, eventID, locationID } = useParams();
  const [location, setLocation] = useState(props.location);
  return (
    <Fragment>
      <h1>{LocationDetailss}</h1>
      <hr />
      <Row>
        <Col sm="2" xs="12">
          <Nav className="nav flex-column nav-pills">
            <NavItem>
              <NavLink
                className={`cursor-pointer ${
                  VerticleTab === "1" ? "active" : ""
                }`}
                onClick={() => setVerticleTab("1")}
              >
                {GeneralInformation}
              </NavLink>
            </NavItem>
          </Nav>
          {location != undefined ? (
            <Fragment>
            <hr />
            {location.collections.map((collection) => {
              return <h5>{collection}</h5>;
            })}
            <hr />
            <Nav className="nav flex-column nav-pills">
              <NavItem>
                <NavLink>
                  <Button color="light">
                    <Link
                      to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Events/${eventID}/Locations/${locationID}/Perspective/Form`}
                    >
                      <i class="fa fa-plus-circle"></i>&nbsp;
                      {AddPerspective}
                    </Link>
                  </Button>
                </NavLink>
              </NavItem>
              <NavItem>
            <NavLink>
              <Button color="light">
                <Link
                  to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Location/${location.id}/Perspectives/List`}
                >
                  {Perspectives}&nbsp;<i className="fa fa-level-down"></i>
                </Link>
              </Button>
            </NavLink>
          </NavItem>
            </Nav>
            </Fragment>
          ) : (
            ""
          )}
        </Col>
        <Col sm="10" xs="12">
          <TabContent activeTab={VerticleTab}>
            <TabPane className="fade show" tabId="1">
              <LocationData location={location} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Fragment>
  );
};

export default LocationDetails;
