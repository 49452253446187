import React,{Fragment, useEffect, useState} from 'react';
import { Badge,Media } from 'reactstrap'
import {SimpleUserThumbnail} from './UserThumbnail'
import ScrollArea from 'react-scrollbar'
import { LoadingAsset } from '../common/WindowLoaders';
import axios from 'axios';

const NotificationsList = (props) => {
  
  const height = props.height || "auto"
  const currentUserID = localStorage.getItem('UserID')
  const [isLoading, setIsLoading]=useState(true)
  const [userNotifications, setUserNotifications]=useState([])
  
  axios.defaults.baseURL = `http://localhost:4000`
  useEffect(()=>{
    const params = {
      userID: currentUserID
    }
    axios.get(`user/notifications/`, {
      params: params
    }).then((res) => {
      setNotificationsData(res.data).then(()=>{
        setIsLoading(false)
      })
    }).catch(error => console.error(error))
  },[])

  const setNotificationsData = (notifications) =>{
    return new Promise ((resolve,reject) => {
      setUserNotifications(notifications)
      resolve(true)
    })
  }

  if(isLoading){
    return(<LoadingAsset loading="Notifications360.js"/>)
  }

  return (
    <Fragment>
      <ScrollArea
        speed={0.5}
        horizontal={false}
        vertical={true}
        style={{height:height}}>
        <div className="chat">
          {userNotifications.map((data,i)=>{
            return(
              <div>
                <div className="media p-b-5">
                  <SimpleUserThumbnail userProfile={data.userfrom}/>
                </div>
                <div style={{backgroundColor:"#cdcdcd4d",borderRadius:"15px"}}>
                    <div className="message-main p-10">
                      <p className="mb-0 f-12"><b>{data.dateFrom}</b>
                      <br/>{data.text}</p>
                    </div>
                </div>
                <hr/>
              </div>
            )
          })}
        </div>
      </ScrollArea>
    </Fragment>
  );
};
export default NotificationsList;