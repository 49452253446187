import "aframe";
import "aframe-look-at-component";
import { Entity, Scene } from "aframe-react";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { PerspectiveLoader } from "../../../../components/common/WindowLoaders";
import { ChangePerspective } from "../../../../redux/common/EventActions";
import { HotSpots } from "../../../../constant/index";
import { useParams } from "react-router-dom";

const VR_HotSpots = (props) => {
  const dispatch = useDispatch();
  const hotSpotProfiles = useSelector(
    (state) => state.EventCommon.settings.hotSpotsProfiles
  );
  const eventData = useSelector((state) => state.EventCommon.eventData);
  const locationID = useSelector((state) => state.PerspectiveCommon.locationID);
  const perspectiveID = useSelector(
    (state) => state.PerspectiveCommon.perspectiveID
  );

  const [isLoading, setIsLoading] = useState(true);
  const [hotSpots, setHotSpots] = useState([]);
  const { platformID, eventID } = useParams();
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    setIsLoading(true);
    if (
      platformID != undefined &&
      eventID != undefined &&
      locationID != undefined &&
      perspectiveID != undefined
    ) {
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
        locationID: locationID,
        perspectiveID: perspectiveID,
      };
      axios
        .get(
          `${ApiURL}/api/vr360/platform/event/location/perspective/hotSpots`,
          {
            params: params,
          }
        )
        .then((res) => {
          setHotSpotsData(res.data).then(() => {
            setIsLoading(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [platformID, eventID, locationID, perspectiveID]);

  const setHotSpotsData = (hotSpots) => {
    return new Promise((resolve, reject) => {
      setHotSpots(hotSpots);
      resolve(true);
    });
  };

  const hotSpotHover = (enter, hotspot) => {
    const hotSpotName = hotspot.id + "-" + hotspot.perspectiveData.name;
    if (enter) {
      document
        .getElementById(`${hotSpotName}`)
        .setAttribute("material", { color: "#0703fc", opacity: 1 });
    } else {
      var hotSpotColor =
        hotspot.perspectiveData.name.type != undefined &&
        hotspot.perspectiveData.name.type == "premium"
          ? props.premiumHotSpotColor
          : props.mainHotSpotColor;
      document
        .getElementById(`${hotSpotName}`)
        .setAttribute("material", { color: `${hotSpotColor}`, opacity: 0.8 });
    }
  };

  // const [animationIndex , setAnimationIndex] = useState(1)
  // useEffect(()=>{
  //   setInterval(()=>{
  //     if(animationIndex > 1){
  //       setAnimationIndex(1)
  //     }else{
  //       setAnimationIndex(1.2)
  //     }
  //   },1000)
  // })

  // const hotSpotHover = (enter,hotspot) => {
  //   const hotSpotName = hotspot.id+"-"+event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].name
  //   if(enter){
  //     console.log("enter",enter,hotspot)
  //     document.getElementById(`${hotSpotName}`).src = require("../../../assets/images/banner/hotSpot_hover.png")
  //   }else{
  //     console.log("leave",enter,hotspot)
  //     document.getElementById(`${hotSpotName}`).src = require("../../../assets/images/banner/hotSpot_premium.png")
  //   }
  //   console.log(document.getElementById(`${hotSpotName}`).src)
  // }

  const hotSpotSRC = (type) => {
    const setImg = (url) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = (event) => {
        var blob = xhr.response;
      };
      xhr.open("GET", url);
      xhr.send();
      return url;
    };

    if (!props.VRMode) {
      type = false;
    }
    switch (type) {
      case "premium":
        return setImg(hotSpotProfiles.premium);
      default:
        return setImg(hotSpotProfiles.main);
    }
  };

  if (isLoading) {
    return <PerspectiveLoader loading={HotSpots} />;
  }

  return (
    <Fragment>
      {
        hotSpots.length > 0
          ? hotSpots.map((hotspot, i) => {
              return (
                <Fragment>
                  <Entity
                    look-at="#camera360"
                    id={hotspot.perspectiveData.name + "-" + hotspot.id}
                    primitive="a-image"
                    src={hotSpotSRC(hotspot.perspectiveData.type)}
                    position={{
                      x: hotspot.position.x,
                      y: hotspot.position.y,
                      z: hotspot.position.z,
                    }}
                    scale={`3 3 3`}
                    events={{
                      click: () => {
                        {
                          hotspot.perspectiveData.type != undefined &&
                          hotspot.perspectiveData.type == "premium" &&
                          props.VRMode
                            ? props.checkIfIsOpen({
                                name: "PerspectivePurchase",
                                data: {
                                  name: hotspot.perspectiveData.name,
                                  previewType: hotspot.perspectiveData.type,
                                  src: hotspot.perspectiveData.preview.src,
                                  video: hotspot.perspectiveData.preview.video,
                                  toLocationId: hotspot.toLocationId,
                                  toPerspectiveId: hotspot.toPerspectiveId,
                                },
                              })
                            : dispatch(
                                ChangePerspective({
                                  toLocationId: hotspot.toLocationId,
                                  toPerspectiveId: hotspot.toPerspectiveId,
                                  type: hotspot.perspectiveData.type,
                                })
                              );
                        }
                      },
                    }}
                  />
                  {/* <Entity 
                  id={hotspot.id+"-"+event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].name}
                  geometry={{primitive: 'sphere'}} 
                  position={{x: hotspot.position.x, y: hotspot.position.y, z:hotspot.position.z}}
                  // scale={{x: animationIndex, y: animationIndex, z:animationIndex}}
                  material={event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type != undefined && event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type == "premium" ? 
                  {color: props.premiumHotSpotColor,opacity: 0.8}:{color: props.mainHotSpotColor,opacity: 0.8}}
                  events={
                    { click: () => 
                        {event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type != undefined && event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type == "premium" ? 
                          props.checkIfIsOpen({name:"PerspectivePurchase",data:{
                              name:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].name,
                              previewType:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].preview.type,
                              src:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].preview.src,
                              toLocationId:hotspot.toLocationId,
                              toPerspectiveId:hotspot.toPerspectiveId
                            }
                          }):
                          props.changePerspective({
                            toLocationId:hotspot.toLocationId,
                            toPerspectiveId:hotspot.toPerspectiveId,
                            type:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type
                          })
                        }, 
                      mouseenter: () => hotSpotHover(true,hotspot),
                      mouseleave: () => hotSpotHover(false,hotspot)
                    }
                  }/> */}
                </Fragment>
              );
            })
          : console.log("This perspective does not have hotSpots")
        // props.hotSpotHoverEnter({id:hotspot.id,toLocationId:hotspot.toLocationId,toPerspectiveId:hotspot.toPerspectiveId})
      }
    </Fragment>
  );
};
export default VR_HotSpots;
