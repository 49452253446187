import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Input, Label, Row, Col } from "reactstrap";

const LabeledDropdown = (props) => {

  const selectOption = (value) =>{
    let label = undefined
    if(value != ""){
      let item = props.array.find(item => item.id = value)
      label = item.name
    }else{
      label = undefined
    }
    props.onChange({value:value,label:label})
  }

  
  useEffect(()=>{
    // if(Object.keys(props.array[0]).length > 0){
    //   const reducedItems = props.array.reduce((items,item)=>{
    //     return items.concat({id:item,name:item})
    //   },[])
    //   console.log("reducedItems",reducedItems)
    //   setItems(reducedItems)
    // }else{
    //   setItems(props.array)
    //   console.log("reducedItems ssss",props.array)

    // }
  },[props])

  return (
    <Fragment>
      <Label className={"m-t-10"}>
        <b>{props.label}</b>
      </Label>
      <Input
        type="select"
        name="select"
        disabled={props.disabled}
        onChange={(event) => selectOption(event.target.value)}
        multiple={false}
      >
        {props.default != undefined ? (
          props.array
            .filter((item) => item.id == props.default)
            .map((item, i) => {
              return (
                <Fragment>
                  <option key={i} value={item.id == undefined ? item:item.id}>
                    {item.name == undefined ? item:item.name}
                  </option>
                </Fragment>
              );
            })
        ) : (
          <option key={"00"} value={""}>{`${props.placeHolder}...`}</option>
        )}

        {props.default != undefined
          ? props.array
              .filter((item) => item.id != props.default)
              .map((item, i) => {
                <option key={i} value={item.id == undefined ? item:item.id}>
                  {item.name == undefined ? item:item.name}
                </option>;
              })
          : props.array.map((item, i) => (
              <option key={i} value={item.id == undefined ? item:item.id}>
                {item.name == undefined ? item:item.name}
              </option>
            ))}
      </Input>
    </Fragment>
  );
};

export default LabeledDropdown;
