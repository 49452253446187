import "aframe";
import "aframe-look-at-component";
import "aframe-particle-system-component";
import "babel-polyfill";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Entity, Scene } from "aframe-react";
import { PerspectiveLoader, LoadingAsset } from "../../common/WindowLoaders";
import { Perspectivee } from "../../../constant/index";
import {
  PerspectiveZoom,
  SetPerspectiveData,
  SetLeftSideTabs,
} from "../../../redux/common/EventActions";
// import VR_Streaming2D from "../../../components/VR/common/VR_Streaming2D";
// import VR_Ads from "../../../components/VR/common/VR_Ads";
// import VR_MediaGallery from "../../../components/VR/common/VR_MediaGallery";
// import VR_HotSpots from "../../../components/VR/common/VR_HotSpots";
// import NadirLogo from "../../../components/VR/common/NadirLogo";

const Perspective = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [perspective, setPerspective] = useState({});
  const [cameraRotation, setCamRotation] = useState("");

  const [mainHotSpotColor, setMainHotSpotColor] = useState("#EF2D5E");
  const [premiumHotSpotColor, setPremiumHotSpotColor] = useState("#f6fc30");

  const [streaming2DVideo_src, setStreaming2DVideo_src] = useState("");

  const [openLeftSideTabs, setOpenLeftSideTabs] = useState([{ type: "LogIn" }]);

  const [alphaLoader, setAlphaLoader] = useState(false);
  const [logedInUser, setLogedInUser] = useState(
    localStorage.getItem("loged_in_user")
  )

  const vrMode = useSelector((state) => state.EventCommon.eventType);
  useEffect(() => {
    if (!isLoading) {
      const evntScene = document.getElementById("Evnt_Scene");
      var cameraVR = document.getElementById("cameraVR");
      var camera360 = document.getElementById("camera360");

      if (evntScene != null) {
        if (vrMode) {
          // dispatch(SetLeftSideTabs([]))
          evntScene.enterVR();
          camera360.setAttribute("camera", "active", false);
          cameraVR.setAttribute("camera", "active", true);
        } else {
          // dispatch(SetLeftSideTabs([]))
          evntScene.exitVR();
          camera360.setAttribute("camera", "active", true);
          cameraVR.setAttribute("camera", "active", false);
        }
        console.log("Evnt_Scene is VR ", evntScene.is("vr-mode"));
      } else {
        console.log("Evnt_Scene is ", evntScene);
      }
    }
  }, [isLoading, vrMode])

  const eventData = useSelector((state) => state.EventCommon.eventData);
  const locationID = useSelector((state) => state.PerspectiveCommon.locationID);
  const perspectiveID = useSelector(
    (state) => state.PerspectiveCommon.perspectiveID
  )
  const cameraZoom = useSelector(
    (state) => state.PerspectiveCommon.perspectiveZoom
  )
  const perspectiveData = useSelector(
    (state) => state.PerspectiveCommon.perspectiveData
  )
  
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    setIsLoading(true);
    setAlphaLoader(true);
    const params = {
      platformID: eventData.platformID,
      eventID: eventData.eventID,
      perspectiveID: perspectiveID,
      locationID: locationID,
    };

    axios
      .get(`${ApiURL}/api/event/perspective/`, {
        params: params,
      })
      .then((res) => {
        setPerspectiveDataa(res.data).then(() => {
          setIsLoading(false)
        })
      })
      .catch((error) => console.error(error))
  }, [eventData, perspectiveID, locationID, logedInUser])

  const setPerspectiveDataa = (perspective) => {
    return new Promise((resolve, reject) => {
      dispatch(PerspectiveZoom(1))
      dispatch(SetPerspectiveData(perspective))
      var camRotation = perspective.camRotation
      setCamRotation(`${camRotation.x} ${camRotation.y} ${camRotation.z}`)
      resolve(true)
    })
  }
  useEffect(() => {
    if (!isLoading) {
      if (perspectiveData.streaming.link != undefined) {
        const perspectiveVideo = document.getElementById("sceneVideo");
        perspectiveVideo.oncanplay = function () {
          perspectiveVideo.play();
        };
      }
    }
  }, [isLoading])
 
  if (isLoading) {
    return <PerspectiveLoader loading={Perspectivee} />;
  }

   return (
     <Fragment>
       <PerspectiveLoader lastLoading={true} changeAlphaOfLoader={alphaLoader} />

       <Scene
         id="Evnt_Scene"
         vr-mode-ui={{ enabled: false }}
         loading-screen="dotsColor: purple; backgroundColor: black"
       >
         <a-assets>
           <video
             id="sceneVideo"
             src={perspectiveData.streaming.link}
             loop={true}
             muted={true}
             crossOrigin={"anonymous"}
           ></video>
         </a-assets>

         {/* <a-camera id="camera360" look-controls="reverseMouseDrag: true, reverseTouchDrag: true"  zoom={cameraZoom}>
             <Entity 
               id="cursor360"
               primitive="a-cursor"
               cursor="rayOrigin: mouse"
               material="color: #ffffff; opacity: 0" 
               geometry="primitive: sphere; radius: 0"/>
         </a-camera> */}

         <a-camera
           id="camera360"
           look-controls="reverseMouseDrag: true, reverseTouchDrag: true"
           zoom={cameraZoom}
         >
           {vrMode ? (
             ""
           ) : (
             <Entity
               id="cursor360"
               primitive="a-cursor"
               cursor="rayOrigin: mouse"
               material="color: #ffffff; opacity: 0"
               geometry="primitive: sphere; radius: 0"
             />
           )}
         </a-camera>
         <a-camera
           id="cameraVR"
           look-controls="reverseMouseDrag: true, reverseTouchDrag: true"
           zoom={cameraZoom}
         >
           {vrMode ? (
             <Entity
               id="cursorVR"
               primitive="a-cursor"
               material="color: #0011ff; opacity: 1"
               animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 1 1 1; to: 3 3 3"
               animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 3 3 3; to: 1 1 1"
               animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 3 3 3"
               cursor="fuse: true;"
               position="0 0 -3"
               geometry="primitive: ring"
             />
           ) : (
             ""
           )}
         </a-camera>

         <a-videosphere
           src={"#sceneVideo"}
           rotation={cameraRotation}
         ></a-videosphere>

         {/* <VR_Streaming2D 
           showBanner={true}
             eventID={eventData.eventID}
             locationID={locationID}
             perspectiveID={perspectiveID}
             video={{
               type:"NormalStreaming"
             }} /> */}

         {/* <VR_Ads setVRAd={props.setVRAd}/> */}

         {/* <VR_HotSpots 
           vrMode={vrMode} 
           eventID={props.eventID}
           locationID={props.locationID}
           perspectiveID={props.perspectiveID}

           mainHotSpotColor={mainHotSpotColor}
           premiumHotSpotColor={premiumHotSpotColor} 
           changePerspective={props.changePerspective} 
           hotSpotHoverEnter={props.hotSpotHoverEnter}/> */}

                 
         {/* <VR_MediaGallery 
           eventID={props.eventID} 
           locationID={props.locationID} 
           perspectiveID={props.perspectiveID}
           setStreaming2DVideo_src={(src)=>setStreaming2DVideo_src(src)}/> */}

        {/* <NadirLogo/> */}
      </Scene>
    </Fragment>
  );
};
export default Perspective;
