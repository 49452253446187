import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Rating from "react-rating";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Button,
  Carousel,
  CarouselControl,
  CarouselItem,
  Media,
} from "reactstrap";
import "../../../../assets/css/evnt_cards.css";
import {
  ChooseLocation,
  Free,
  SponsordedBy,
  TemporarilyUnavailable,
} from "../../../../constant";
import LabeledDropdown from "../../../Admin/layout/FilterDropdown";
import CloseCard from "./CloseWindow";

const VenueLocations = (props) => {
  const { platformID, eventID } = useParams();
  const perspective = useSelector(
    (state) => state.PerspectiveCommon.perspectiveData
  );
  const [locationID, setLocationID] = useState();
  useEffect(() => {
    if (locationID == undefined && perspective != undefined) {
      setLocationID(perspective.locationID);
    }
  }, [perspective]);

  const [isLoadingPerspectives, setIsLoadingPerspectives] = useState(true);
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [availableLocations, setAvailableLocations] = useState([]);
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";

  useEffect(() => {
    if (platformID != undefined && eventID != undefined) {
      setAvailableLocations(true);
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
      };
      axios
        .get(`${ApiURL}/api/user/platform/event/locations/available`, {
          params: params,
        })
        .then((res) => {
          setAvailableLocationsData(res.data).then(() => {
            setIsLoadingLocations(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [platformID, eventID]);

  const setAvailableLocationsData = (data) => {
    return new Promise((resolve, reject) => {
      setAvailableLocations(data);
      resolve(true);
    });
  };

  useEffect(() => {
    if (
      platformID != undefined &&
      eventID != undefined &&
      locationID != undefined
    ) {
      setIsLoadingPerspectives(true);
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
        locationID: locationID,
      };
      axios
        .get(
          `${ApiURL}/api/user/platform/event/locations/perspectives/available`,
          {
            params: params,
          }
        )
        .then((res) => {
          setAvailablePerspectivesData(res.data).then(() => {
            setIsLoadingPerspectives(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [platformID, eventID, locationID]);

  const setAvailablePerspectivesData = (data) => {
    return new Promise((resolve, reject) => {
      setActivePerspectives(data);
      resolve(true);
    });
  };

  const dispatch = useDispatch();
  const event = useSelector((state) => state.Common.currentEvent);
  const [activePerspectives, setActivePerspectives] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === activePerspectives.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? activePerspectives.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
    if (animating) return;
  };

  const [blurIndex, setBlurIndex] = useState(2);
  useEffect(() => {
    setTimeout(() => {
      setBlurIndex(2);
    }, 4000);
  }, [blurIndex]);

  const windowWidth = props.windowWidth;
  const [rating, setRating] = useState(4);

  if (isLoadingLocations) {
    return <Fragment></Fragment>;
  }

  if (isLoadingPerspectives) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div style={{ pointerEvents: "all", cursor: "pointer" }}>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          {activePerspectives.map((perspective, i) => {
            return (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`Location ${perspective.perspectiveData.name}`}
              >
                <div
                  className="location-item-layout"
                  style={
                    activeIndex == i
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div
                    onClick={() =>
                      props.checkIfIsOpen({ name: props.windowName })
                    }
                  >
                    <CloseCard />
                  </div>
                  <div
                    className="m-l-15"
                    style={{ position: "absolute", width: "50%" }}
                  >
                    <LabeledDropdown
                      array={availableLocations}
                      placeHolder={ChooseLocation}
                      onChange={(option) => setLocationID(option.value)}
                    />
                  </div>
                  <div className="location-data">
                    <div className="m-l-20 m-b-20 m-t-20">
                      <h6 className="evnt-card-item-title ">
                        <b>{perspective.perspectiveData.name}</b>
                      </h6>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "100%" }}>
                          <ul className="location-data-list">
                            <li>
                              <i className="fa fa-eye"></i>&nbsp;
                              {perspective.attendance + "K"}
                            </li>
                            <li>
                              <Rating
                                initialRating={perspective.rating.stars}
                                emptySymbol="fa fa-star-o"
                                fullSymbol="fa fa-star"
                                onChange={(rate) => setRating(rate)}
                              ></Rating>
                            </li>
                          </ul>
                          <div className="p-r-15">
                            {perspective.price == undefined ? (
                              <Button
                                className="location-button"
                                color="secondary"
                                outline
                              >
                                {TemporarilyUnavailable}
                              </Button>
                            ) : (
                              ""
                            )}
                            {perspective.price != undefined &&
                            perspective.perspectiveData.price.amount === 0 ? (
                              <Button
                                color="success"
                                className="location-button"
                                onClick={() =>
                                  props.changePerspective({
                                    toLocationId: perspective.locationID,
                                    toPerspectiveId: perspective.perspectiveID,
                                    type: undefined,
                                  })
                                }
                                color="success"
                              >
                                {Free}
                              </Button>
                            ) : (
                              ""
                            )}

                            {perspective.price != undefined &&
                            perspective.perspectiveData.price.amount > 0 ? (
                              <Button
                                className="location-button "
                                onClick={() =>
                                  props.checkIfIsOpen({
                                    name: "paymentCard",
                                    data: {
                                      itemToPurchase: "perspective",
                                      item: perspective.perspectiveData.name,
                                      price: perspective.perspectiveData.price,
                                      toLocationId: perspective.locationID,
                                      toPerspectiveId:
                                        perspective.perspectiveID,
                                      type: undefined,
                                    },
                                  })
                                }
                                color="primary"
                              >{` $ ${perspective.perspectiveData.price.amount} ${perspective.perspectiveData.price.currency}`}</Button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {perspective.cardBanner != undefined && perspective.cardBanner != "" ? (
                          <div className="m-r-20" style={{ width: "100%" }}>
                            <span className="pull-right">{`${SponsordedBy}:`}</span>
                            <br/>
                            <Media
                              src={perspective.cardBanner}
                              alt={"Card Banner"}
                              className="img-fluid pull-right"
                              style={{
                                width: "auto",
                                cursor: "pointer",
                                maxWidth: "120px",
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <Media
                    className="cursor-pointer location-thumbnail"
                    onClick={() => {
                      props.checkIfIsOpen({
                        name: "paymentCard",
                        data: {
                          itemToPurchase: "perspective",
                          toLocationId: locationID,
                          toPerspectiveId: perspective.id,
                          type: undefined,
                        },
                      });
                      props.checkIfIsOpen({ name: props.windowName });
                    }}
                    src={perspective.perspectiveData.preview.thumbnail}
                    alt={perspective.perspectiveData.name}
                  />
                </div>
              </CarouselItem>
            );
          })}
          {/* <CarouselIndicators items={activePerspectives} activeIndex={activeIndex} onClickHandler={goToIndex} />                 */}
          <a
            className="carousel-control-prev cursor-pointer"
            role="button"
            tabindex="0"
            style={{ height: "30%", marginTop: "25%" }}
            onClick={previous}
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next cursor-pointer"
            role="button"
            tabindex="0"
            style={{ height: "30%", marginTop: "25%" }}
            onClick={next}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </Carousel>
      </div>
    </Fragment>
  );
};
export default VenueLocations;
