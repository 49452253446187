import "aframe";
import "aframe-look-at-component";
import React, { Fragment, useEffect, useState } from "react";
import { Entity } from "aframe-react";
import axios from "axios";
import { LoadingAsset, PerspectiveLoader } from "../../../../components/common/WindowLoaders";
import VR_Streaming2D from "../../../../components/VR/common/VR_Streaming2D";
import { useSelector } from "react-redux";
import { MediaGallery } from "../../../../constant";
import { useParams } from "react-router-dom";
const VR_MediaGallery = (props) => {
  const locationID = useSelector((state) => state.PerspectiveCommon.locationID);
  const perspectiveID = useSelector(
    (state) => state.PerspectiveCommon.perspectiveID
  );

  const [isLoading, setIsLoading] = useState(true);
  const [mediaGallery, setMediaGallery] = useState(undefined);
  const [galleryPosition, setGalleryPosition] = useState(undefined);
  const [galleryRotation, setGalleryRotation] = useState(undefined);
  const [galleryScale, setGalleryScale] = useState(undefined);
  const { platformID, eventID } = useParams();
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (
      platformID != undefined &&
      eventID != undefined &&
      locationID != undefined &&
      perspectiveID != undefined
    ) {
      setIsLoading(true);
      if (document.getElementById("MediaGalleryVideo")) {
        document.getElementById("MediaGalleryVideo").remove();
      }
      const params = {
        platformID: platformID,
        eventID: eventID,
        perspectiveID: perspectiveID,
        locationID: locationID,
      };
      axios
        .get(`${ApiURL}/api/user/platform/event/perspective/mediaGallery`, {
          params: params,
        })
        .then((res) => {
          if (res.status == 200) {
            setMediaGalleryData(res.data).then(() => {
              setIsLoading(false);
            });
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [platformID, eventID, locationID, perspectiveID]);
  const setMediaGalleryData = (data) => {
    return new Promise((resolve, reject) => {
      setMediaGallery(data);
      setGalleryPosition(data.position);
      setGalleryRotation(data.rotation);
      setGalleryScale(data.scale);
      resolve(true);
    });
  };

  const setImg = (url) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      var blob = xhr.response;
    };
    xhr.open("GET", url);
    xhr.send();
    return url;
  };
  const thumbnailsPositions = [
    "-0.33 -0.2 -0.016",
    "0 -0.2 0",
    "0.33 -0.2 0",
    "-0.33 -0.43 -0.016",
    "0 -0.43 0",
    "0.33 -0.43 0",
  ];

  if (isLoading) {
    return <PerspectiveLoader loading={MediaGallery} />;
  }

  return (
    <Fragment>
      {mediaGallery != undefined ? (
        <a-entity
          id="media-wrapper"
          position={`${galleryPosition.x} ${galleryPosition.y} ${galleryPosition.z}`}
          rotation={`${galleryRotation.x} ${galleryRotation.y} ${galleryRotation.z}`}
          scale={`${galleryScale.x} ${galleryScale.y} ${galleryScale.z}`}
        >
          <a-entity
            id="media-title"
            position={`-4.40 5 -10.60`}
            rotation={`0 0 0`}
            scale="15 15 15"
            text="value: Resumenes; align:center;"
          ></a-entity>
          <a-entity
            id="media-wrapper"
            position={`-4.45 5 -10.75`}
            rotation={`0 0 0`}
            scale="10 10 10"
          >
            <a-entity id="media-block-0">
              {mediaGallery.galleryThumbnails.map((media, i) => {
                return (
                  <Fragment>
                    <Entity
                      id={`${media.name} Thumbnail`}
                      primitive="a-image"
                      src={setImg(media.thumbnail)}
                      crossOrigin={"anonymous"}
                      width="3"
                      height="2"
                      position={thumbnailsPositions[i]}
                      rotation="0 0 0"
                      scale="0.1 0.1 0.1"
                      events={{ click: () => console.log(media) }}
                    />
                    {/* <a-entity id="menu-title" text={`value: ${media.team1} vs ${media.team2}; align:center;`} position={thumbnailsPositions[i]} scale="0.8 0.8 0.8"></a-entity> */}
                  </Fragment>
                );
              })}
            </a-entity>
          </a-entity>
          <a-entity
            id="Streaming-Title"
            text={`value: Previo al partido; align:center;`}
            position={`5.22 5 -10.60`}
            rotation={`0 0 0`}
            scale="15 15 15"
          ></a-entity>
          <VR_Streaming2D 
            showBanner={true}
            streamingData={mediaGallery.streaming2D} 
            checkIfIsOpen={props.checkIfIsOpen} changePerspective={props.changePerspective}/>
        </a-entity>
      ) : (
        console.log("This perspective does not have media gallery")
      )}
    </Fragment>
  );
};
export default VR_MediaGallery;
