import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Media,
} from "reactstrap";
import {
  Save,
  Cancel,
  Add,
  Edit,
  Statistics,
  List,
  Settings,
  ControlPanel,
  BreadCrumbRoute,
  Perspectivee,
  Locations,
  Eventt,
  Platform,
  Create,
} from "../../../constant";
import { MENUITEMS } from "./sidebar/menu";
import { Link, Switch, useHistory } from "react-router-dom";
import { Tooltip } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { LoadingAsset } from "../../vr360/layout2D/WindowLoaders";
import { CreateDocument } from "../../../redux/Administrator/Actions";

const Breadcrumbs = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true);
  const [breadcrumb, setBreadcrumb] = useState(props);
  useEffect(() => {
    setBreadcrumb(props);
  }, [props]);

  const { platformID, eventID, locationID, perspectiveID } = useParams();
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  const [platformName, setPlatformName] = useState("");
  const [eventName, setEventName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [perspectiveName, setPerspectiveName] = useState("");

  useEffect(() => {
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
      platformID: platformID,
      eventID: eventID,
      locationID: locationID,
      perspectiveID: perspectiveID,
    };
    axios
      .get(`${ApiURL}/api/admin/controlPanel/breadcrumb`, {
        params: params,
      })
      .then((res) => {
        SetRoute(res.data).then(() => {
          setIsLoading(false);
        });
      })
      .catch((error) => console.error(error));
  }, [platformID, eventID, locationID, perspectiveID]);

  const SetRoute = (route) => {
    return new Promise((resolve, reject) => {
      setPlatformName(route.platform);
      setEventName(route.event);
      setLocationName(route.location);
      setPerspectiveName(route.perspective);
      resolve(true);
    });
  };


  if (isLoading) {
    return <LoadingAsset loading={BreadCrumbRoute} />;
  }

  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="6">
            <h3>{breadcrumb.title}</h3>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={`${process.env.PUBLIC_URL}/Admin/ControlPanel`}>
                  {ControlPanel}
                </Link>
              </BreadcrumbItem>
              {platformID != undefined ? (
                <BreadcrumbItem>
                  <Link
                    to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Form`}
                  >
                    {platformName}
                  </Link>
                </BreadcrumbItem>
              ) : (
                ""
              )}
              {eventID != undefined ? (
                <BreadcrumbItem>
                  <Link
                    to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Form`}
                  >
                    {eventName}
                  </Link>
                </BreadcrumbItem>
              ) : (
                ""
              )}
              {locationID != undefined ? (
                <BreadcrumbItem>
                  <Link
                    to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Location/${locationID}/Form`}
                  >
                    {locationName}
                  </Link>
                </BreadcrumbItem>
              ) : (
                ""
              )}
              {perspectiveID != undefined ? (
                <BreadcrumbItem>
                  <Link
                    to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Location/${locationID}/Perspective/${perspectiveID}/Form`}
                  >
                    {perspectiveName}
                  </Link>
                </BreadcrumbItem>
              ) : (
                ""
              )}
            </Breadcrumb>
          </Col>
            <Col lg="6">
              <div className="bookmark pull-right">
                <ul>
                  {breadcrumb.addBTN ? (
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/${breadcrumb.addBTN.path}`}
                      >
                        &nbsp;
                        <Button color="primary" size="sm">
                          <i className="fa fa-plus-circle fa-lg"></i>&nbsp;
                          &nbsp;{`${Add} ${breadcrumb.addBTN.btnLabel}`}
                        </Button>
                        &nbsp;
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {breadcrumb.statisticsBTN ? (
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/dashboard/hospital`}>
                        &nbsp;
                        <Button color="primary" size="sm">
                          <i className="fa fa-bar-chart fa-lg"></i>&nbsp; &nbsp;
                          {Statistics}
                        </Button>
                        &nbsp;
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {breadcrumb.createBTN ? (
                    <li style={{backgroundColor:"#ffffff"}}>
                      &nbsp;
                      <Button color="success" size="sm" onClick={()=>dispatch(CreateDocument(true))}>
                      <i className="fa fa-plus-circle fa-lg"></i>&nbsp;
                        &nbsp;{`${Create} ${breadcrumb.createBTN}`}
                      </Button>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Breadcrumbs;
