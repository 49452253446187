import "aframe";
import "aframe-look-at-component";
import { Entity, Scene } from "aframe-react";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { LoadingAsset, PerspectiveLoader } from "../../layout2D/WindowLoaders";
import { ChangePerspective } from "../../../../redux/common/EventActions";
import { Ads } from "../../../../constant";

const VR_Ads = (props) => {
  const AdClick = (ad) => {
    switch (ad.type) {
      case "ad_hotSpot":
        dispatch(
          ChangePerspective({
            toLocationId: ad.toLocationId,
            toPerspectiveId: ad.toPerspectiveId,
          })
        );
        break;
      default:
      case "AdMediaSrc":
        props.setVRAd({ name: ad.type, data: ad });
        break;
    }
  };

  const dispatch = useDispatch();
  const eventData = useSelector((state) => state.EventCommon.eventData);
  const locationID = useSelector((state) => state.PerspectiveCommon.locationID);
  const perspectiveID = useSelector(
    (state) => state.PerspectiveCommon.perspectiveID
  );

  const [isLoading, setIsLoading] = useState(true);
  const [adPlacements, setAdPlacements] = useState([]);
  const { platformID, eventID } = useParams();
  const [sponsors, setSponsors] = useState([]);
  const [ads, setAds] = useState([]);
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (
      platformID != undefined &&
      eventID != undefined &&
      locationID != undefined &&
      perspectiveID != undefined
    ) {
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
        perspectiveID: perspectiveID,
        locationID: locationID,
      };
      axios
        .get(`${ApiURL}/api/user/platform/event/perspective/ads`, {
          params: params,
        })
        .then((res) => {
          setPerspectiveAdPlacements(res.data).then(() => {
            setIsLoading(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [platformID, eventID, locationID, perspectiveID]);

  const setPerspectiveAdPlacements = (ads) => {
    return new Promise((resolve, reject) => {
      setAdPlacements(ads);
      resolve(true);
    });
  };

  const setImg = (url) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      var blob = xhr.response;
    };
    xhr.open("GET", url);
    xhr.send();
    return url;
  };

  if (isLoading) {
    return <PerspectiveLoader loading={Ads} />;
  }

  return (
    <Fragment>
      {adPlacements.length > 0
        ? adPlacements.map((adPlacement, i) => {
            return (
              <Fragment>
                {adPlacement.ad.src != undefined ? (
                  <Entity
                    key={adPlacement.id}
                    id={`${adPlacement.name} Thumbnail`}
                    primitive="a-image"
                    crossOrigin={"anonymous"}
                    src={setImg(adPlacement.ad.src)}
                    width="3"
                    height="2"
                    position={{
                      x: adPlacement.position.x,
                      y: adPlacement.position.y,
                      z: adPlacement.position.z,
                    }}
                    rotation={{
                      x: adPlacement.rotation.x,
                      y: adPlacement.rotation.y,
                      z: adPlacement.rotation.z,
                    }}
                    scale={{
                      x: adPlacement.scale.x,
                      y: adPlacement.scale.y,
                      z: adPlacement.scale.z,
                    }}
                    events={{ click: () => AdClick(adPlacement.ad) }}
                  />
                ) : (
                  <Entity
                    id={adPlacement.name}
                    geometry={{ primitive: "sphere" }}
                    material={{ color: "#ffff", opacity:  0}}
                    position={{
                      x: adPlacement.position.x,
                      y: adPlacement.position.y,
                      z: adPlacement.position.z,
                    }}
                    rotation={{
                      x: adPlacement.rotation.x,
                      y: adPlacement.rotation.y,
                      z: adPlacement.rotation.z,
                    }}
                    scale={{
                      x: adPlacement.scale.x,
                      y: adPlacement.scale.y,
                      z: adPlacement.scale.z,
                    }}
                    events={{ click: () => AdClick(adPlacement.ad) }}
                  />
                )}
              </Fragment>
            );
          })
        : console.log("This perspective does not have ads")}
    </Fragment>
  );
};
export default VR_Ads;
