import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Media, Button } from "reactstrap";
import { Fragment } from "react";
import {
  UpgradePerspectives,
  PurchasePerspective,
  PreviewPerspective,
  Loading,
  PurchaseLocation,
  Location,
  LocationData,
  SponsordedBy,
  Free,
  TemporarilyUnavailable,
} from "../../../../constant";
import CloseCard from "./CloseWindow";
import "../../../../assets/css/evnt_cards.css";
import Rating from "react-rating";
import { LoadingAsset, LoadingCard } from "../WindowLoaders";
import axios from "axios";

export const LocationPurchase = (props) => {
  const windowWidth = props.windowWidth;
  const [bluredImg, setBluredImg] = useState(true);
  const [rating, setRating] = useState(4);

  const [windowData, setWindowData] = useState(props.windowData);
  useEffect(() => {
    setWindowData(props.windowData);
  }, [props.windowData]);

  const [windowType, setWindowType] = useState(null);
  useEffect(() => {
    if (windowWidth <= 1000 && windowWidth >= 425) {
      setWindowType("evnt-card-lg");
    } else {
      setWindowType("evnt-card-md");
    }
  }, [windowWidth]);

  const eventData = useSelector((state) => state.EventCommon.eventData);
  const [isLoading, setIsLoading] = useState(true);
  const [location, setLocation] = useState(undefined);
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    const params = {
      platformID: eventData.platformID,
      eventID: eventData.eventID,
      locationID: windowData.toLocationId,
    };
    axios
      .get(`${ApiURL}/api/event/location/purchase`, {
        params: params,
      })
      .then((res) => {
        setLocationData(res.data).then(() => {
          setIsLoading(false);
        });
      })
      .catch((error) => console.error(error));
  }, []);

  const setLocationData = (data) => {
    return new Promise((resolve, reject) => {
      setLocation(data);
      resolve(true);
    });
  };

  if (isLoading) {
    return <LoadingAsset loading={LocationData} />;
  }

  return (
    <Fragment>
      <div className="location-item-layout evnt-card">
        <div style={{ position: "absolute", width:"100%" }}>
          <div onClick={() => props.checkIfIsOpen({ name: props.windowName })}>
            <CloseCard />
          </div>
          <h2 className="evnt-card-title m-t-10 m-l-20">
            <b>{PurchaseLocation}</b>
          </h2>
        </div>
        <div className="location-data">
          <div className="m-l-20 m-b-20 m-t-20">
            <h6 className="evnt-card-item-title ">
              <b>{location.locationData.name}</b>
            </h6>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "100%" }}>
                <ul className="location-data-list">
                  <li>
                    <i className="fa fa-eye"></i>&nbsp;
                    {location.attendanceLength + "K"}
                  </li>
                  <li>
                    <Rating
                      initialRating={rating}
                      emptySymbol="fa fa-star-o"
                      fullSymbol="fa fa-star"
                      onChange={(rate) => setRating(rate)}
                    ></Rating>
                  </li>
                </ul>
                <div className="p-r-15">
                  <Button
                    className="location-button"
                    onClick={() =>
                      props.checkIfIsOpen({
                        name: "paymentCard",
                        data: {
                          itemToPurchase: "perspective",
                          item: location.locationData.name,
                          price: { amount: 15, currency: "MXN" },
                          toLocationId: windowData.toLocationId,
                          toPerspectiveId: windowData.toPerspectiveId,
                          type: undefined,
                        },
                      })
                    }
                    color="primary"
                  >
                    {PurchaseLocation}
                  </Button>
                </div>
              </div>
              {location.cardBanner != undefined && location.cardBanner != "" ? (
                <div className="m-r-20" style={{ width: "100%" }}>
                  <span className="pull-right">{`${SponsordedBy}:`}</span>
                  <br />
                  <Media
                    src={location.cardBanner}
                    alt={"Card Banner"}
                    className="img-fluid pull-right"
                    style={{
                      width: "auto",
                      cursor: "pointer",
                      maxWidth: "120px",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Media
          className="cursor-pointer location-thumbnail"
          src={location.locationData.preview.thumbnail}
          alt={location.locationData.name}
        />
      </div>
    </Fragment>
  );
};

export const PerspectivePurchase = (props) => {
  const windowWidth = props.windowWidth;
  const [bluredImg, setBluredImg] = useState(true);
  const [rating, setRating] = useState(4);

  const [windowData, setWindowData] = useState(props.windowData);
  useEffect(() => {
    setWindowData(props.windowData);
  }, [props.windowData]);

  const [windowType, setWindowType] = useState(null);
  useEffect(() => {
    if (windowWidth <= 1000 && windowWidth >= 425) {
      setWindowType("evnt-card-lg");
    } else {
      setWindowType("evnt-card-md");
    }
  }, [windowWidth]);

  const eventData = useSelector((state) => state.EventCommon.eventData);
  const [isLoading, setIsLoading] = useState(true);
  const [perspective, setPerspective] = useState(undefined);
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    const params = {
      platformID: eventData.platformID,
      eventID: eventData.eventID,
      locationID: windowData.toLocationId,
      perspectiveID: windowData.toPerspectiveId,
    };
    axios
      .get(`${ApiURL}/api/event/location/upgrade`, {
        params: params,
      })
      .then((res) => {
        console.log("setPerspectiveUpgrade", res.data);
        setPerspectiveUpgrade(res.data).then(() => {
          setIsLoading(false);
        });
      })
      .catch((error) => console.error(error));
  }, []);

  const setPerspectiveUpgrade = (data) => {
    return new Promise((resolve, reject) => {
      setPerspective(data);
      resolve(true);
    });
  };

  if (isLoading) {
    return <LoadingCard loading={"Perspective Upgrade"} />;
  }

  return (
    <Fragment>
      <Card className={`evnt-card ${windowType}`}>
        <div onClick={() => props.checkIfIsOpen({ name: props.windowName })}>
          <CloseCard />
        </div>
        <CardBody style={{ color: "white" }}>
          <h2 className="evnt-card-title">
            <b>{UpgradePerspectives}</b>
          </h2>
          <div className="location-item-layout">
            <div>
              <Fragment>
                {windowWidth <= 1000 && windowWidth >= 425 ? (
                  ""
                ) : (
                  <Media
                    src={require(`../../../../assets/images/banner/cardBanner.jpg`)}
                    alt={"Card Banner"}
                    className="img-fluid b-r-10"
                    style={{ width: "100%", cursor: "pointer" }}
                  />
                )}
                {bluredImg ? (
                  <div
                    style={{
                      position: "relative",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Media
                      className="img-fluid b-r-10 location-thumbnail"
                      onClick={() => {
                        props.checkIfIsOpen({
                          name: "paymentCard",
                          data: {
                            itemToPurchase: "perspective",
                            toLocationId: windowData.toLocationId,
                            toPerspectiveId: windowData.toPerspectiveId,
                            type: undefined,
                          },
                        });
                        props.checkIfIsOpen({ name: props.windowName });
                      }}
                      src={require(`../../../../assets/images/${windowData.src}`)}
                      alt={windowData.name}
                      style={{
                        maxWidth: "200px",
                        cursor: "none",
                        marginTop: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        filter: "blur(2px)",
                        WebkitFilter: "blur(2px)",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <i className="fa fa-lock fa-5x"></i>
                      <Button
                        style={{ width: "100%" }}
                        color="secondary"
                        size="xs"
                        onClick={() => setBluredImg(false)}
                      >
                        {PreviewPerspective}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    {windowData.previewType == "img" ? (
                      <Media
                        className="img-fluid b-r-10 location-thumbnail"
                        style={{
                          width: "400px",
                          height: "auto",
                          float: "none",
                        }}
                        src={require(`../../../../assets/images/${windowData.src}`)}
                      />
                    ) : (
                      <video
                        id="UpgradePerspective_Video"
                        className="vip-suite-video location-thumbnail"
                        src={require(`../../../../assets/video/${windowData.video}`)}
                        loop="true"
                        autostart
                        controls
                        crossOrigin={"anonymous"}
                      ></video>
                    )}
                  </Fragment>
                )}
              </Fragment>
            </div>
            <div className="location-data">
              {windowWidth <= 1000 && windowWidth >= 425 ? (
                <Media
                  src={require(`../../../../assets/images/banner/cardBanner.jpg`)}
                  alt={"Card Banner"}
                  className="img-fluid b-r-10"
                  style={{ maxWidth: "300px", cursor: "pointer" }}
                />
              ) : (
                ""
              )}

              <h6 className="evnt-card-item-title">
                <b>{windowData.name}</b>
              </h6>
              <div>
                <ul className="location-data-list">
                  <li>
                    <i className="fa fa-eye"></i>&nbsp;{57 + "K"}
                  </li>
                  <li>
                    <Rating
                      initialRating={rating}
                      emptySymbol="fa fa-star-o"
                      fullSymbol="fa fa-star"
                      onChange={(rate) => setRating(rate)}
                    ></Rating>
                  </li>
                </ul>
              </div>
              <Button
                className="location-button"
                onClick={() =>
                  props.checkIfIsOpen({
                    name: "paymentCard",
                    data: {
                      itemToPurchase: "perspective",
                      item: windowData.name,
                      price: { amount: 15, currency: "MXN" },
                      toLocationId: windowData.toLocationId,
                      toPerspectiveId: windowData.toPerspectiveId,
                      type: undefined,
                    },
                  })
                }
                color="primary"
              >
                {PurchasePerspective}
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
