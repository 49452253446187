import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../../assets/css/evnt_cards.css";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingAsset } from "../WindowLoaders";
import { TalentHeader } from "../../../../constant";
import { useParams } from "react-router";

export const HeaderEventCaster = (props) => {
  const dispatch = useDispatch();
  const caster = useSelector(
    (state) => state.EventCommon.settings.eventCasters.default
  );

  if (Object.keys(caster).length === 0) {
    return <LoadingAsset loading={"UserThumbnail ( HeaderEventCaster ).js"} />;
  }

  return (
    <Fragment>
      <span
        className="media user-header text-color"
        style={{ cursor: "pointer", marginTop: "0px" }}
        key={caster.id}
        onClick={() => props.checkIfIsOpen({ name: "AudioSettings" })}
      >
        <img
          className="mr-2 rounded-circle img-40"
          src={caster.photoURL}
          alt=""
        />
        <span className="media-body">
          <span className="f-16 f-w-600">
            {caster.firstName + " " + caster.lastName}
          </span>
          <span className="d-block f-12">
            <span className="badge badge-pill badge-success">
              <i
                style={{ color: "white" }}
                className="fa fa-microphone fa-lg"
              ></i>
            </span>
            &nbsp;{"Sports Caster"}
          </span>
        </span>
      </span>
    </Fragment>
  );
};

export const HeaderTalent = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading]=useState(true)
  const [talent, setTalent] = useState({})
  const {platformID, eventID}=useParams()
  const locationID = useSelector((state) => state.PerspectiveCommon.locationID);
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  
  useEffect(() => {
    if (platformID != undefined && eventID != undefined && locationID != undefined) {
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
        locationID:locationID
      };
      axios
        .get(`${ApiURL}/api/platform/user/event/location/talent`, {
          params: params,
        })
        .then((res) => {
          setTalentData(res.data).then(()=>{
            setIsLoading(false)
          })
        })
        .catch((error) => console.error(error));
    } else {
      setIsLoading(false);
    }
  }, [platformID, eventID, locationID]);

  const setTalentData = (data) => {
    return new Promise((resolve, reject) => {
      setTalent(data);
      resolve(true);
    });
  };

  if (isLoading) {
    return <LoadingAsset loading={TalentHeader} />;
  }
  if(Object.keys(talent).length == 0){
    return(<Fragment></Fragment>)
  }
  return (
    <Fragment>
      <span
        className="media user-header text-color"
        style={{ cursor: "pointer", marginTop: "0px" }}
        key={talent.id}
        onClick={()=> props.checkIfIsOpen({name:"UserProfileCard",data:{userID:talent.id}})}
      >
        <img
          className="mr-2 rounded-circle img-40"
          src={talent.photoURL}
          alt=""
        />
        <span className="media-body">
          <span className="f-16 f-w-600">
            {talent.firstName + " " + talent.lastName}
          </span>
          <span className="d-block f-12">
            <span className="badge badge-pill badge-primary">
              <i style={{ color: "white" }} className="fa fa-music fa-lg"></i>
            </span>
            &nbsp;{"Song Name..."}
          </span>
        </span>
      </span>
    </Fragment>
  );
};
