import React, { Fragment } from 'react';
import BreadCrumb from '../../layout/Breadcrumb'
import { Calendar, momentLocalizer,Views} from 'react-big-calendar'
import moment from 'moment'
import myEventsList from "./Events";
import {Container,Row,Col,Card,CardHeader,CardBody,Media} from 'reactstrap'
import { BasicCalendars} from "../../constant";
import EventTimeLineCard from './EventTimeLineCard'
const localizer = momentLocalizer(moment)
let allViews = Object.keys(Views).map(k => Views[k])

const BasicCalender = () => {

    return (
        <Fragment>
            <BreadCrumb  subparent="Calendar" title="Basic Calender"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="4">
                        <Row className="timeline-custom">
                            <Col xl="12 xl-50 box-col-6">
                              <EventTimeLineCard/>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="8">
                        <Card>
                            <CardHeader>
                                <h5>{BasicCalendars}</h5>
                            </CardHeader>
                            <CardBody>
                                <Calendar
                                    localizer={localizer}
                                    scrollToTime={new Date(1970, 1, 1, 6)}
                                    defaultDate={new Date(2019, 3, 12)}
                                    onSelectEvent={event => alert(event.title)}
                                    views={allViews}
                                    events={myEventsList}
                                    eventOverlap
                                    dragRevertDuration={500}
                                    dragScroll
                                    droppable={true}
                                    showMultiDayTimes
                                    step={60}
                                    startAccessor="start"
                                    endAccessor="end"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default BasicCalender;