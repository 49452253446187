import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Row, Col, Media, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import LabeledInput from "../../../../../components/general/LabeledInput";
import LabeledDropDown from "../../../../../components/general/LabeledDropDown";
import {
  LocationName,
  MediaLink,
  MediaInputOptions,
  Save,
  Cancel,
  LocationDescription,
  FacebookLink,
  InstagramLink,
  TwitterLink,
  LinkedinLink,
  EventGraphic,
  GraphicSoruce,
  EventCountry,
  EventState,
  EventCity,
  FromDate,
  Today,
  ToDate,
  ShowEventCounters,
  MainLocationType,
  PlatformTypes,
  LocationPricePasswordTerms,
  LocationPrice,
  Currency,
  CurrencyList,
  LocationStatus,
  CatalogAccess,
  Administrators,
  GuestsList,
  Sponsors,
  LocationTypeOptions,
  LocationType,
  GeneralInformation,
  PerspectiveCreatedSuccessfully,
} from "../../../../../constant";
import axios from "axios";
import { CreateDocument } from "../../../../../redux/Administrator/Actions";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

const PerspectiveData = (props) => {
  const [selectedOption, setDropdownOption] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const createDocument = useSelector(
    (state) => state.AdminState.createDocument
  );
  const { platformID, eventID, locationID } = useParams();
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (createDocument) {
      dispatch(CreateDocument(false));
      axios
        .post(
          `${ApiURL}/api/admin/controlPanel/platform/event/location/perspective`,
          {
            userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
            platformID: platformID,
            eventID: eventID,
            locationID: locationID,
            perspective: {
              perspectiveData: {
                name: perspectiveName,
                preview: {
                  thumbnail: perspectivePreview,
                  video: "",
                },
                price: {
                  amount: perspectivePrice,
                  currency: perspectiveCurrency,
                },
                status: perspectiveStatus,
                type: perspectiveType,
              },
            },
          }
        )
        .then((res) => {
          toast.success(PerspectiveCreatedSuccessfully, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setTimeout(
            history.push(
              `${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Location/${locationID}/Perspective/Form`
            ),
            5000
          );
        })
        .catch((error) => {
          console.error(error);
          const errorDetails = error.response.data.error.details;
          errorDetails.map((error) => {
            toast.error(`${error.context.key} ${error.type}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        });
    }
  }, [createDocument]);

  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());

  const [perspectiveRating, setPerspectiveRating] = useState("");
  const [perspectiveDescription, setPerspectiveDescription] = useState("");
  const [perspectiveCategory, setPerspectiveCategory] = useState("");
  const [perspectiveType, setPerspectiveType] = useState("");
  const [perspectiveToDate, setPerspectiveToDate] = useState("");
  const [perspectiveFromDate, setPerspectiveFromDate] = useState("");
  const [perspectiveName, setPerspectiveName] = useState("");
  const [perspectiveStatus, setPerspectiveStatus] = useState("");
  const [perspectivePrice, setPerspectivePrice] = useState("");
  const [perspectiveCurrency, setPerspectiveCurrency] = useState("");
  const [perspectivePreview, setPerspectivePreview] = useState("thumbnail.jpg");

  useEffect(() => {
    if (props.perspective != undefined) {
      const perspective = props.perspective.perspectiveData;
      setPerspectiveRating(perspective.rating);
      setPerspectiveCategory(perspective.category);
      setPerspectiveType(perspective.type);
      setPerspectiveToDate(perspective.toDate);
      setPerspectiveFromDate(perspective.fromDate);
      setPerspectiveName(perspective.name);
      setPerspectiveStatus(perspective.status);
      setPerspectivePrice(perspective.price.amount);
      setPerspectiveCurrency(perspective.price.currency);
      setPerspectivePreview(perspective.preview);
    }
  }, [props.perspective]);

  return (
    <Fragment>
      <h1>{GeneralInformation}</h1>
      <hr />
      <Row>
        <Col lg="6">
          <Row>
            <Col lg="8">
              <LabeledInput
                label={LocationName}
                placeholder={LocationName}
                type={"text"}
                onChange={(event) => setPerspectiveName(event.target.value)}
                value={perspectiveName}
              />
            </Col>
            <Col lg="4">
              <LabeledInput
                label={LocationType}
                placeholder={LocationType}
                type={"text"}
                onChange={(event) => setPerspectiveStatus(event.target.value)}
                value={perspectiveStatus}
              />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={LocationPrice}
                placeholder={LocationPrice}
                type={"number"}
                onChange={(event) => setPerspectivePrice(event.target.value)}
                value={perspectivePrice}
              />
            </Col>
            <Col lg="6">
              <LabeledDropDown
                label={Currency}
                options={CurrencyList}
                setSelectedOption={(option) => setPerspectiveCurrency(option)}
              />
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <Col lg="6">
              {selectedOption != "Image" ? (
                <LabeledInput
                  label={MediaLink}
                  placeholder={MediaLink}
                  type={"file"}
                  value={""}
                />
              ) : (
                <LabeledInput
                  label={MediaLink}
                  placeholder={MediaLink}
                  type={"text"}
                  value={""}
                  value={selectedOption}
                />
              )}
            </Col>
            <Col lg="12">
              <Media
                style={{ width: "100%" }}
                className="img-fluid m-r-20 p-2"
                src={perspectivePreview.thumbnail}
                alt="Event Main Grafic"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PerspectiveData;
