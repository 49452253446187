// Dashboard
import Default from '../components/dashboard/Default'
import Hospital from '../components/dashboard/Hospital'
import Sass from '../components/dashboard/Sass'
import Crm from '../components/dashboard/Crm'
import Crypto from '../components/dashboard/Crypto'

// Base
import GeneralWidget from '../components/widgets/General'
import ChartWidget from '../components/widgets/Charts'
import StateColor from '../components/base/Statecolor'
import Typography from '../components/base/Typography'
import Avatars from '../components/base/Avatars'
import HelperClasses from '../components/base/HelperClasses'
import Grid from '../components/base/Grid'
import TagAndPills from '../components/base/TagAndPills'
import Progress from '../components/base/Progress'
import Modal from '../components/base/Modal'
import Alert from '../components/base/Alert/Alert'
import Popover from '../components/base/Popover/Popover'
import Tooltip from '../components/base/Tooltip/Tooltip'
import Spinner from '../components/base/Spinner'
import Dropdown from '../components/base/DropDown/Dropdown'
import TabBootstrap from '../components/base/Tabs/TabBootstrap'
import TabLine from '../components/base/Tabs/TabLine'
import Accordian from '../components/base/Accordian/Accordian'
import Navs from '../components/base/Nav/Navs'
import Shadow from '../components/base/Shadow'
import List from '../components/base/List'

// Advance 
import Scrolling from '../components/advance/Scrolling'
import BootstrapNotify from '../components/advance/BootstrapNotify'
import Rating from '../components/advance/Rating'
import Dropzone from '../components/advance/Dropzone'
import SweetAlert from '../components/advance/SweetAlert'
import Tour from '../components/advance/Tour'
import Sticky from '../components/advance/Sticky'
import Breadcrumb from '../components/advance/Breadcrumb'
import Imagecrop from '../components/advance/Imagecrop'
import RangeSlider from '../components/advance/RangeSlider'
import Carousel from '../components/advance/carousel/Carousel'
import Pagination from '../components/advance/Pagination'
import Steps from '../components/advance/Steps/Steps'
import DragAndDrop from '../components/advance/DragAndDrop'
import UploadImage from '../components/advance/uploadImage'

// todo-app
 import Todo from '../components/application/todo-app/todo'

 // todo-firebase-app
 import TodoFirebase from '../components/application/todo-firebase-app/todo-firebase'

 // E-commerce-app 
 import Product from '../components/application/ecommerce-app/product'
 import ProductDetail from '../components/application/ecommerce-app/productpage'
 import Cart from '../components/application/ecommerce-app/Cart'
 import Wishlist from '../components/application/ecommerce-app/Wishlist'
 import Productlist from '../components/application/ecommerce-app/productlist'
 import Paymentdetails from '../components/application/ecommerce-app/paymentdetails'
 import OrderHistory from '../components/application/ecommerce-app/OrderHistory'
 import Checkout from '../components/application/ecommerce-app/Checkout'
 import Invoice from '../components/application/ecommerce-app/Invoice'

 // Chat 
 import Chat from '../components/general/chat-app/chat'

// Social App 
import SocialApp from '../components/social-app/SocialApp'

// Sponsors
import Sponsor2D from '../components/Pages/Sponsors/Sponsor2D'
import SponsorsList from '../components/Pages/Sponsors/SponsorsList'

// Experiences
import ExperiencesListView from '../components/Pages/Experiences/ExperiencesListView/ExperiencesListView'
import ExperienceView from '../components/Pages/Experiences/ExperienceView/ExperienceView'

// Job Search 
import CardView from '../components/jobSearch/CardView'
import JobList from '../components/jobSearch/Job-list'
import JobDetail from '../components/jobSearch/Job-detail'
import JobApply from '../components/jobSearch/Job-apply'

 // Email
 import Email from '../components/application/email-app/emailDefault'

 // Contact app
 import ContactApp from '../components/application/contact-app/contactApp'
 import NewUser from '../components/application/contact-app/new-user' 
 import EditUser from '../components/application/contact-app/edit-user'

 // Forms
import FormValidation from '../components/forms/form-control/form-validation'
import BaseInput from '../components/forms/form-control/baseInput'
import InputGroup from '../components/forms/form-control/InputGroup'
import MegaOption from '../components/forms/form-control/MegaOption'
import CheckboxandRadio from '../components/forms/form-control/CheckboxandRadio'

// Form Layout
import FormDefault from '../components/forms/form-layout/formDefault'
import FormWizard1 from '../components/forms/form-layout/form-wizard-1/Formwizard1'

 // Forms widgets
import Datepicker from '../components/forms/form-widget/datepicker'
import Timepicker from '../components/forms/form-widget/timepickerComponent/timepicker'
import Typeahead from '../components/forms/form-widget/typeaheadComponent/typeahead'

// Tabels
import BasicTabels from '../components/tables/BasicTable'
import BorderTable from '../components/tables/BorderTable'
import SizingTable from '../components/tables/SizingTable'
import StylingTable from '../components/tables/StylingTable'
import DataTable from '../components/tables/DataTable'

// Cards
import BasicCards from '../components/cards/BasicCards'
import CreativeCards from '../components/cards/CreativeCards'
import TabCard from '../components/cards/TabCard'
import DraggingCards from '../components/cards/DraggingCards'

// Users
import UserProfile from '../components/users/UserProfile'
import UserEdit from '../components/users/UserEdit'
import UserCards from '../components/users/UserCards'

// Calendar
import BasicCalender from '../components/calendar/BasicCalendar'
import DraggableCalendar from '../components/calendar/DraggableCalendar'

// Icons
import FlagIcons from '../components/icons/FlagIcons'
import FontAwsomeIcon from '../components/icons/FontAwsomeIcon'
import IcoIcons from '../components/icons/IcoIcons'
import ThemifyIcons from '../components/icons/ThemifyIcons'
import FeatherIcons from '../components/icons/FeatherIcons'
import WeatherIcons from '../components/icons/WeatherIcons'

import Materialdesignicon from '../components/icons/Materialdesignicon'
import Pe7icons from '../components/icons/Pe7icons'
import Typicon from '../components/icons/Typicon'
import Simplelineicon from '../components/icons/Simplelineicon'
import Ionicicon from '../components/icons/Ionic-icon'

// Buttons 
import DefaultButton from '../components/buttons/Default-Button'
import EdgeButton from '../components/buttons/EdgeButton'
import FlatButton from '../components/buttons/FlatButton'
import GroupButton from '../components/buttons/GroupButton'
import RaisedButton from '../components/buttons/RaisedButton'

// Gallary
import ImageGallery from '../components/gallery/ImageGallery'
import ImageWithDesc from '../components/gallery/ImageWithDesc'
import MesonryGallery from '../components/gallery/MesonryGallery'
import MesonryDesc from '../components/gallery/MesonryDesc'
import ImageHover from '../components/gallery/ImageHover'

// Timelines
import Timeline1 from '../components/timelines/Timeline'
import Timeline2 from '../components/timelines/Timeline2'

// Charts 
import Apexcharts from "../components/charts/ApexCharts/Apexcharts";
import GoogleCharts from "../components/charts/GoogleCharts/GoogleCharts";
import KnobChart from "../components/charts/KnobChart/KnobChart";
import Chartsjs from "../components/charts/Chartsjs/Chartsjs";
import Chartist from "../components/charts/ChartistCharts/Chartist";

// Maps
import GoogleMap from '../components/map/GoogleMap'
import LeafletMap from '../components/map/LeafletMap'

// Editor
import CkEditor from '../components/editor/CkEditor'
import MdeEditor from '../components/editor/MdeEditor'

// Blog
import BlogDetail from '../components/blog/BlogDetail'
import BlogSingle from '../components/blog/BlogSingle'
import BlogPost from '../components/blog/BlogPost'

// Learning
import Learninglist from '../components/learning/Learning-list'
import LearningDeatil from '../components/learning/Learning-deatil'

// Pricing
import Pricing from '../components/price/Pricing'

// Sample page 
import Sample from "../components/sample/Sample"

// Search page 
import Search from "../components/search/Search"

// FAQ page 
import FaqComponent from "../components/faq/FaqComponent"

// knowledgebase page 
import KnowledgebaseComponent from "../components/knowledgebase/KnowledgebaseComponent"

// Support Ticket
import SupportTicket from "../components/support-ticket/supportTicket"





// Event Admin 
import PlatformDashBoard from '../platforms/Admin/platforms/PlatformDashBoard'

import ControlPanel from '../platforms/Admin/ControlPanel'

import PlatformForm from '../platforms/Admin/platforms/PlatformForm'
import EventForm from '../platforms/Admin/events/EventForm'
import LocationForm from '../platforms/Admin/locations/LocationForm'
import PerspectiveForm from '../platforms/Admin/perspectives/PerspectiveForm'


// Platform Types
import LogInRouter from '../auth/LogInRouter'
import VR360 from '../platforms/vr360/VR360'
import Loader from '../platforms/Loader'








export const routes = [
  { path: "/Admin/Platform/:platformID/DashBoard" , Component: PlatformDashBoard , type:"Admin"},

  { path: "/Admin/Platform/:platformID/Form" , Component: PlatformForm, type:"Admin"},

  { path: "/Admin/ControlPanel" , Component: ControlPanel},
  { path: "/Admin/ControlPanel/Platforms/List" , Component: ControlPanel},
  { path: "/Admin/ControlPanel/Platform/:platformID/Events/List" , Component: ControlPanel},
  { path: "/Admin/ControlPanel/Platform/:platformID/Event/:eventID/Locations/List" , Component: ControlPanel},
  { path: "/Admin/ControlPanel/Platform/:platformID/Event/:eventID/Location/:locationID/Perspectives/List" , Component: ControlPanel},

  { path: "/Admin/ControlPanel/Platform/:platformID/Form" , Component: PlatformForm},
  { path: "/Admin/ControlPanel/Platform/:platformID/Event/:eventID/Form" , Component: EventForm},
  { path: "/Admin/ControlPanel/Platform/:platformID/Event/:eventID/Location/:locationID/Form" , Component: LocationForm},
  { path: "/Admin/ControlPanel/Platform/:platformID/Event/:eventID/Location/:locationID/Perspective/:perspectiveID/Form" , Component: PerspectiveForm},

  { path: "/Admin/ControlPanel/Platform/Form" , Component: PlatformForm},
  { path: "/Admin/ControlPanel/Platform/:platformID/Event/Form" , Component: EventForm},
  { path: "/Admin/ControlPanel/Platform/:platformID/Event/:eventID/Location/Form" , Component: LocationForm},
  { path: "/Admin/ControlPanel/Platform/:platformID/Event/:eventID/Location/:locationID/Perspective/Form" , Component: PerspectiveForm},

  { path: "/LogInRouter/:platformID/:eventID" , Component: LogInRouter},
  { path: "/VR360/:platformID/:eventID" , Component: VR360},

  
]
