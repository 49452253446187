import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody} from 'reactstrap'
import { Fragment } from 'react'
import { Media } from 'reactstrap'
import { useDispatch } from 'react-redux';
import CloseCard from './CloseWindow'
import {ChangePerspective} from '../../../../redux/common/EventActions'

export const Iframe = (props) => {
    const dispatch = useDispatch()
    const [windowData, setWindowData]=useState(props.windowData)
    useEffect(()=>{
        setWindowData(props.windowData)
    },[props.windowData])

    return(
        <Fragment>
            <Card className={"evnt-card"}>
                <div onClick={()=> props.checkIfIsOpen({name:props.windowName})}><CloseCard/></div>
                <CardBody style={{color:"white"}}>
                    <iframe width="100%" height="380" src={windowData.src} title="3D Experience"></iframe>  
                </CardBody>
            </Card>
        </Fragment>
    )
    
}

export default Iframe