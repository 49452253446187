import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { Label, Row, Col, Media, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import LabeledInput from "../../../../../components/general/LabeledInput";
import LabeledDropDown from "../../../../../components/general/LabeledDropDown";
import { PlatformLogo, PlatformRedirect } from "../../../../../constant";
import '../../../../../assets/css/evnt_admin.css'
export const SettingsPlatformLogo = (props) => {
    

  const [platformRedirect, setPlatformRedirect] = useState("")
  const [platformLogo, setPlatformLogo] = useState("")

  useEffect(()=>{
    if(props.platform != undefined){
      const platformData = props.platform.platformData
      setPlatformRedirect(platformData.preview.redirect)
      setPlatformLogo(platformData.preview.thumbnail)
    }
  },[props.platform])

  return (
    <Fragment>
      <h1>{PlatformLogo}</h1>
      <hr />
      <Row>
        <Col sm="4" xs="12">
          <Media className="platform-form-logo-preview p-b-5" src={platformLogo}/>
          <LabeledInput label={PlatformRedirect} placeholder={PlatformRedirect} type={'text'} value={platformRedirect}/>
        </Col>
      </Row>
    </Fragment>
  )
}