import React, { Fragment } from "react"
import errorImg from "../../../assets/images/search-not-found.png"
import { NotFoundData } from "../../../constant"

const ItemsNotFound = (props) => {
  return (
    <Fragment>
      <div className="search-not-found text-center">
        <div>
          <img className="img-fluid second-search" src={errorImg} alt="" />
          <p>{NotFoundData}</p>
        </div>
      </div>
    </Fragment>
  )
}

export default ItemsNotFound
