import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "../../../../assets/css/evnt_admin.css";

import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

import {
  EventDetails,
  GeneralInformation,
  Settings,
  EventCasters,
  EventLogIn,
  HotSpotsProfiles,
  LoaderLogo,
  NadirLogo,
  SpecialPerspectives,
  Locationss,
  AddLocation,
} from "../../../../constant";

import { useSelector, useDispatch } from "react-redux";

import EventData from "./eventDetails/EventData";
import {
  SettingsEventCasters,
  SettingsHotSpotsProfiles,
  SettingsLoaderLogo,
  SettingsLogIn,
  SettingsNadirLogo,
  SettingsSpecialPerspectives,
} from "./eventDetails/Settings";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const EventDetailsTab = (props) => {
  const [VerticleTab, setVerticleTab] = useState("1");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { platformID } = useParams();
  const [event, setEvent] = useState(props.event);

  return (
    <Fragment>
      <h1>{EventDetails}</h1>
      <hr />
      <Row>
        <Col sm="2" xs="12">
          <Nav className="nav flex-column nav-pills">
            <NavItem>
              <NavLink
                className={`cursor-pointer ${
                  VerticleTab === "1" ? "active" : ""
                }`}
                onClick={() => setVerticleTab("1")}
              >
                {GeneralInformation}
              </NavLink>
            </NavItem>
            {event != undefined ? 
            <Dropdown
              className="nav-item"
              isOpen={dropdownOpen}
              toggle={toggle}
            >
              <NavItem>
                <DropdownToggle
                  className={`nav-link cursor-pointer ${
                    VerticleTab != "1" ? "active" : ""
                  }`}
                  tag="a"
                  color="default"
                  caret
                >
                  {Settings}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => setVerticleTab("2")}
                    className={`cursor-pointer ${
                      VerticleTab === "2" ? "active" : ""
                    }`}
                  >
                    {EventLogIn}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setVerticleTab("3")}
                    className={`cursor-pointer ${
                      VerticleTab === "3" ? "active" : ""
                    }`}
                  >
                    {LoaderLogo}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setVerticleTab("4")}
                    className={`cursor-pointer ${
                      VerticleTab === "4" ? "active" : ""
                    }`}
                  >
                    {NadirLogo}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setVerticleTab("5")}
                    className={`cursor-pointer ${
                      VerticleTab === "5" ? "active" : ""
                    }`}
                  >
                    {HotSpotsProfiles}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setVerticleTab("6")}
                    className={`cursor-pointer ${
                      VerticleTab === "6" ? "active" : ""
                    }`}
                  >
                    {SpecialPerspectives}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setVerticleTab("7")}
                    className={`cursor-pointer ${
                      VerticleTab === "7" ? "active" : ""
                    }`}
                  >
                    {EventCasters}
                  </DropdownItem>
                </DropdownMenu>
              </NavItem>
            </Dropdown>:""}
          </Nav>
          {event != undefined ? (
            <Fragment>
              <hr />
              {event.collections.map((collection) => {
                return <h5>{collection}</h5>;
              })}
              <hr />
              <Nav className="nav flex-column nav-pills">
                <NavItem>
                  <NavLink>
                    <Button color="light">
                      <Link
                        to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${event.id}/Location/Form`}
                      >
                        <i class="fa fa-plus-circle"></i>&nbsp;{AddLocation}
                      </Link>
                    </Button>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Button color="light">
                      <Link
                        to={`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${event.id}/Locations/List`}
                      >
                        {Locationss}&nbsp;<i className="fa fa-level-down"></i>
                      </Link>
                    </Button>
                  </NavLink>
                </NavItem>
              </Nav>
            </Fragment>
          ) : (
            ""
          )}
        </Col>
        <Col sm="10" xs="12">
          <TabContent activeTab={VerticleTab}>
            <TabPane className="fade show" tabId="1">
              <EventData event={event} />
            </TabPane>
            <TabPane tabId="2">
              {/* <SettingsLogIn  event={event} eventLogIn={event.settings.eventLogIn} /> */}
            </TabPane>
            <TabPane tabId="3">
              {/* <SettingsLoaderLogo event={event} eventLoaderLogo={event.settings.loaderLogo} /> */}
            </TabPane>
            <TabPane tabId="4">
              {/* <SettingsNadirLogo event={event} eventNadirLogo={event.settings.nadirLogo} /> */}
            </TabPane>
            <TabPane tabId="5">
              {/* <SettingsHotSpotsProfiles event={event}
                eventHotSpotsProfiles={event.settings.hotSpotsProfiles}
              /> */}
            </TabPane>
            <TabPane tabId="6">
              {/* <SettingsSpecialPerspectives event={event}
                eventSpecialPerspectives={event.settings.specialPerspectives}
              /> */}
            </TabPane>
            <TabPane tabId="7">
              {/* <SettingsEventCasters event={event}
                eventCasters={event.settings.eventCasters}
              /> */}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Fragment>
  );
};

export default EventDetailsTab;
