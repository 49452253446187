import React, { useState, useEffect } from "react";
import { Media } from "reactstrap";
import LoaderGif from "../assets/images/loaderEvnt.gif";
const Loader = (props) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (props.wait == undefined) {
      const timeout = setTimeout(() => {
        setShow(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props,show]);
  return (
    <div className={`loader-wrapper ${show ? "" : "loderhide"}`} style={{marginLeft: "-15px"}}>
      <Media width="300" src={LoaderGif} />
      {/* <div className="loader loader-7">
                <div className="line line1"></div>
                <div className="line line2"></div>
                <div className="line line3"></div>
            </div> */}
    </div>
  );
};

export default Loader;
