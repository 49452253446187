import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Media } from "reactstrap";
import { LogIn_Card, LogInCatalog_Card } from "./LogIn_page";

import VenueLocations from "./VenueLocations";
import ChatTab from "./ChatTab";
import UsersList360 from "./UsersList360";
import Notifications360 from "./Notifications360";
import Settings360 from "./Settings360";
import RemoteControl from "./RemoteControl";
import UserStories from "./UserStories";
import {LocationPurchase, PerspectivePurchase} from "./UpgradeExpereince"
import TeamStatistics from "./TeamStatisticsCard";
import VideoCall from "./VideoCall";
import SocialSharing from "./SocialSharing";
import AdMediaSrc from "./AdMediaSrc";
import AudioSettings from "./AudioSettings";
import TwitterFeed from "./TwitterFeed";
import { UserProfileCard } from "./ProfileCard";
import PaymentCard from "./PaymentCard";
import MoreMobile from "./MoreMobile";
import Iframe from "./Iframe";

import {
  SetLeftSideTabs,
  CheckIfIsOpen,
  ChangePerspective,
} from "../../../../redux/common/EventActions";
import { LoadingAsset } from "../WindowLoaders"

export const Layer_2D = (props) => {
  const Cards2D = [
    { name: "UserProfileCard", Component: UserProfileCard },
    { name: "venueLocations", Component: VenueLocations },
    { name: "AudioSettings", Component: AudioSettings },
    { name: "Notifications360", Component: Notifications360 },
    { name: "UsersList360", Component: UsersList360 },
    { name: "Settings360", Component: Settings360 },
    { name: "MoreMobile", Component: MoreMobile },
    { name: "teamStatistics", Component: TeamStatistics },
    { name: "LocationPurchase", Component: LocationPurchase },
    { name: "PerspectivePurchase", Component: PerspectivePurchase },
    { name: "paymentCard", Component: PaymentCard },
    { name: "ChatTab", Component: ChatTab },
    { name: "UserStories", Component: UserStories },
    { name: "AdMediaSrc", Component: AdMediaSrc },
    { name: "Iframe", Component: Iframe },
    // { name: 'RemoteControl', Component: RemoteControl },
    // { name: 'socialSharing', Component: SocialSharing },
    // { name: 'videoCall', Component: VideoCall },
  ];

  const currentAd = props.currentAd;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const vrMode = useSelector((state) => state.EventCommon.eventType);
  const event = useSelector((state) => state.Common.currentEvent);

  const [openCards, setOpenCards] = useState(props.openCards);

  useEffect(() => {
    setOpenCards(props.openCards);
  }, [props.openCards]);

  if (openCards.length <= 0) {
    return (<Fragment></Fragment>)
  }

  if (!vrMode) {
    return (
      <Fragment>
        <div className={"layer-2d"}>
          {Cards2D.filter((card) => card.name == openCards[0].name).map(
            ({ name, Component }) => {
              return (
                <Component
                  windowName={name}
                  windowData={openCards[0].data}
                  windowWidth={props.windowWidth}
                  windowHeight={props.windowHeight}
                  checkIfIsOpen={props.checkIfIsOpen}
                  changePerspective={(hotSpot) =>
                    dispatch(ChangePerspective(hotSpot))
                  }
                  setTourInstructions={props.setopentour} // Settings360 , MoreMobile
                />
              );
            }
          )}
        </div>
      </Fragment>
    );
  }
};

export default Layer_2D;
