import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import {
  Email,
  ForgotPassword,
  Login,
  Password,
  SignUp,
  SocialMediaLogIn,
} from "../constant";
import { toast } from "react-toastify";
import LabeledInput from "../components/general/LabeledInput";
import "../assets/css/evnt_cards.css";
import {
  googleProvider,
  facebookProvider,
  twitterProvider,
  githubProvider,
} from "../data/config";
import man from "../assets/images/dashboard/user.png";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { getAuth, signInWithPopup,signInWithEmailAndPassword } from "firebase/auth";
import { useSelector } from "react-redux";

export const LogInCard = (props) => {
  const history = useHistory();
  const auth = getAuth();
  const { platformID, eventID } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const platformLogo = useSelector(
    (state) => state.PlatformCommon.platformLogo
  );
  useEffect(() => {
    console.log(platformLogo);
  }, [platformLogo]);

  const [profilePicture, setProfilePicture] = useState(
    localStorage.getItem("profileURL" || man)
  );
  const [name, setName] = useState(localStorage.getItem("Name"));
  const [logedInUser, setLogedInUser] = useState(false);
  useEffect(() => {
    localStorage.setItem("profileURL", profilePicture);
    localStorage.setItem("Name", name);
    localStorage.setItem("loged_in_user", logedInUser);
  }, [profilePicture, name, logedInUser]);

  const loginAuth = async (event) => {
    event.preventDefault();
    console.log(email, password)
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password)
      .then((credentials) => {
        setProfilePicture(credentials.user.photoURL);
        setName(credentials.user.displayName);
        setLogedInUser(true);
        history.push(
          `${process.env.PUBLIC_URL}/VR360/${platformID}/${eventID}`
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        toast.error(`${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 15000,
        });
      });
  };
  const googleAuth = async () => {
    try {
      signInWithPopup(auth, googleProvider).then(function (result) {
        setName(result.user.displayName);
        setProfilePicture(result.user.photoURL);
        setLogedInUser(true);

        setTimeout(() => {
          history.push(
            `${process.env.PUBLIC_URL}/VR360/${platformID}/${eventID}`
          );
          window.location.reload();
        }, 200);
      });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };
  const facebookAuth = async () => {
    try {
      signInWithPopup(auth, facebookProvider).then(function (result) {
        setProfilePicture(result.user.photoURL);
        setName(result.user.displayName);
        setLogedInUser(true);

        setTimeout(() => {
          history.push(
            `${process.env.PUBLIC_URL}/VR360/${platformID}/${eventID}`
          );
          window.location.reload();
        }, 200);
      });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };
  const twitterAuth = async () => {
    try {
      signInWithPopup(auth, twitterProvider).then(function (result) {
        setProfilePicture(result.user.photoURL);
        setName(result.user.displayName);
        setLogedInUser(true);

        setTimeout(() => {
          history.push(
            `${process.env.PUBLIC_URL}/VR360/${platformID}/${eventID}`
          );
          window.location.reload();
        }, 200);
      });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };
  const githubAuth = async () => {
    try {
      signInWithPopup(auth, githubProvider).then(function (result) {
        setProfilePicture(result.user.photoURL);
        setName("Hardik Parmar");
        setLogedInUser(true);

        setTimeout(() => {
          history.push(
            `${process.env.PUBLIC_URL}/VR360/${platformID}/${eventID}`
          );
          window.location.reload();
        }, 200);
      });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };

  return (
    <Fragment>
      <Card className={`evnt-card log-in-card evnt-card-md`}>
        <CardBody style={{ color: "white" }}>
          <div className="log-in-form">
            <div>
              <a href={platformLogo.redirect} target="_blanck">
                <img
                  className={`lightlogo log-in-logo`}
                  src={platformLogo.main}
                  alt=""
                />
              </a>
              <p className="pull-right">
                <b>{"Powered by EVNT"}</b>
              </p>
            </div>

            <Fragment>
              <div>
                <h2 className="evnt-card-title pull-right m-t-5">
                  <b>{Login}</b>
                </h2>
              </div>
              <div>
                <LabeledInput
                  label={Email}
                  placeholder={Email}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  type={"email"}
                />
                <br />
                <LabeledInput
                  label={Password}
                  placeholder={Password}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  type={"password"}
                />
              </div>
              <div className={"m-t-15"}>
                <div>
                  <Button
                    className={"pull-left"}
                    color="primary"
                    onClick={(event) => loginAuth(event)}
                  >
                    {Login}
                  </Button>
                </div>
                <div className={"pull-right"}>
                  <Row>
                    <Col lg="12">
                      <p className={"pull-right"}>{ForgotPassword}</p>
                    </Col>
                    <Col lg="12">
                      <a
                        href="#"
                        className={"pull-right"}
                        onClick={() => {
                          props.setSignUp(true);
                        }}
                      >
                        <b>{SignUp}</b>
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className={"m-t-15"}>
                <Row>
                  <Col sm="12">
                    <p>{SocialMediaLogIn}</p>
                  </Col>
                  <Col sm="3">
                    <Button color="primary" outline onClick={facebookAuth}>
                      <i className="fa fa-facebook-square fa-lg"></i>
                    </Button>
                  </Col>
                  <Col sm="3">
                    <Button color="primary" outline>
                      <i className="fa fa-twitter-square fa-lg"></i>
                    </Button>
                  </Col>
                  <Col sm="3">
                    <Button color="primary" outline onClick={googleAuth}>
                      <i className="fa fa-google fa-lg"></i>
                    </Button>
                  </Col>
                  <Col sm="3">
                    <Button color="primary" outline>
                      <i className="fa fa-apple fa-lg"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Fragment>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
