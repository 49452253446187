import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";

import Layer_2D from "./layout2D/Layer_2D";
import Loader from "../Loader";
import Perspective from "./layoutVR/Perspective";
import {
  SetEventSettings,
  SetLoaderLogo,
  ChangePerspective,
  SetEventAttendance,
  SetEventComments,
  SetPlatformSettings,
} from "../../redux/common/EventActions";
import { EventSettings, PlatformSettings } from "../../constant";

const VR360 = (props) => {
  const dispatch = useDispatch();
  const { platformID, eventID } = useParams();
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";

  const [isLoadingPlatformSettings, setIsLoadingPlatformSettings] =
    useState(true);
  useEffect(() => {
    if (platformID != undefined) {
      const params = {
        platformID: platformID,
      };
      axios
        .get(`${ApiURL}/api/user/platform/settings/`, {
          params: params,
        })
        .then((res) => {
          SetPlatformSettingsData(res.data).then(() => {
            setIsLoadingPlatformSettings(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [platformID]);
  const SetPlatformSettingsData = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(SetPlatformSettings(data.settings));
      resolve(true);
    });
  };

  const [isLoadingEventSettings, setIsLoadingEventSettings] = useState(true);
  useEffect(() => {
    if (platformID != undefined && eventID != undefined) {
      const params = {
        eventID: eventID,
        platformID: platformID,
      };
      axios
        .get(`${ApiURL}/api/user/platform/event/settings`, {
          params: params,
        })
        .then((res) => {
          setEventSettingsData(res.data).then(() => {
            setIsLoadingEventSettings(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [eventID]);
  const setEventSettingsData = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(SetEventSettings(data));
      const firstPerspective =
        data.settings.specialPerspectives.firstPerspective;
      dispatch(
        ChangePerspective({
          toLocationId: firstPerspective.toLocationId,
          toPerspectiveId: firstPerspective.toPerspectiveId,
          type: undefined,
        })
      );
      resolve(true);
    });
  };

  const windowWidth = useWindowWidthSize();
  function useWindowWidthSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const windowHeight = useWindowHeightSize();
  function useWindowHeightSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerHeight);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const [VrAd, setVRAd] = useState({});

  if (isLoadingPlatformSettings) {
    return <Loader loading={"LogInRouter.js ( isLoadingPlatformSettings ) "} />;
  }

  if (isLoadingEventSettings) {
    return <Loader loading={"LogInRouter.js ( isLoadingEventSettings ) "} />;
  }

  return (
    <Fragment>
      {/* <Loader /> */}
      <div className="page-wrapper compact-page">
        <div className="page-body-wrapper">
          <Layer_2D
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            VrAd={VrAd}
          />
          <Perspective setVRAd={(ad) => setVRAd(ad)} />
        </div>
      </div>
    </Fragment>
  );
};
export default VR360;
