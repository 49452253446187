import React, { Fragment, useEffect, useState} from 'react'
import { Button, Media, Card, CardBody } from 'reactstrap'
import { ExploreYourSurroundings, Immersive360Experience, Loading } from '../../../constant';
import '../../../assets/css/evnt_cards.css'
import { useSelector } from 'react-redux'
export const PerspectiveLoader = (props) => {

    const loaderLogoURL = useSelector(state => state.EventCommon.loaderLogoURL)
    const loaderLogo = useSelector(state => state.EventCommon.settings.loaderLogo)

    // const [ loaderLogo_Item, setLoagerLogoItem] = useState("")
    const [ loaderOpacity , setLoaderOpacity ] = useState(0)
    const [ loadingPercentage, setLoadingPercentage] = useState(0)
    const [ loader360 , setLoader360 ] = useState(true)
    
    const changeAlphaOfLoader = (status) => {
      if(status){
        var alpha = 0
        var alphaIntervalUp = setInterval(function () {
          if(alpha <= 100){
            alpha = alpha + 50
            setLoaderOpacity(alpha)
          }else{
              clearInterval(alphaIntervalUp)
              const loaderWaitingTime = 5000 // IN MILISECONDS

              // if(loader360){
                var loadingCounter = 0;
                var loadingPercentageInterval = setInterval(()=>{
                  if(loadingCounter < 100){
                    loadingCounter ++
                    setLoadingPercentage(loadingCounter)
                  }else{
                    setLoadingPercentage(0)
                    clearInterval(loadingPercentageInterval)
                  }
                },loaderWaitingTime/100)
              // }

              setTimeout(()=>{changeAlphaOfLoader(false)},loaderWaitingTime)
          }
        }, 10)
      }else{
        var alpha = 100
        var alphaIntervaDown = setInterval(function () {
          if( alpha >= 0){
            setLoaderOpacity(alpha--)
          }else{
            clearInterval(alphaIntervaDown)
            if(loader360){setLoader360(false)}
          }
        }, 10)
      }  
    }
    useEffect(()=>{
      if(props.lastLoading){
        changeAlphaOfLoader(props.alphaLoader)
      }else{
        setLoaderOpacity(100)
        setLoadingPercentage(100)
      }
    },[props.lastLoading,props.alphaLoader])

  return (
    <Fragment>
        <div className={"perspective-loader"} >
            <div className={`page-main-header open evnt-loader-data`}
              style={{opacity:`${loaderOpacity}%`}} >
                <Media className="evnt-loader-360-img" src={loaderLogo.logoIMG}/>
                
                <h3 className="evnt-loader-text"><b>{Immersive360Experience}</b></h3>
                <h5 className="evnt-loader-text">{ExploreYourSurroundings}</h5>
                {props.lastLoading ? 
                <Button color="light" outline disabled style={{marginLeft:"auto", marginRight:"auto",color:"white"}}><b>{Loading+": "+ loadingPercentage +" %"}</b></Button>
                :<h6 className="evnt-loader-text">{`${Loading} ${props.loading}...`}</h6>
                }
            </div>
            <Media 
              className={"perspective-loader-main-img"}
              style={{opacity:`${loaderOpacity}%`}} 
              src={loaderLogo.backGroundIMG}/>
        </div>
    </Fragment>  
  );
}
export default PerspectiveLoader