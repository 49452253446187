import React, { Fragment, useEffect } from "react"
import UserMenu from "./header/UserMenu"
import "../../../assets/css/evnt_admin.css"
import MenuOptions from './header/MenuOptions'
import { SearchBarToggle } from "../../../redux/common/actions"
import Search from './header/Search'
import EvntLogo from '../../../assets/images/evntLogo_black.png'
import { useDispatch, useSelector } from "react-redux"
export const AdminHeaderBar = (props) => {

  return (
    <div className={`page-main-header ${true ? "open" : ""}`} >
      <div className="main-header-right">
        <div className="p-r-20 logo-block">
          <img
            className="log-in-logo"
            src={EvntLogo}
            alt="Platform Logo"
          />
        </div>
        {/* <Search/> */}
        <MenuOptions />
        <UserMenu checkIfIsOpen={props.checkIfIsOpen}/>
      </div>
    </div>
  )
}

export default AdminHeaderBar;
