import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { LoadingAsset } from "../../WindowLoaders";
import { getAuth } from "firebase/auth";
import {
  Button,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselItem,
  Media,
} from "reactstrap";
import "../../../../../assets/css/evnt_cards.css";
import LabeledDropdown from "../../../../Admin/layout/FilterDropdown";
import { ChooseSize } from "../../../../../constant";
import Rating from "react-rating";

export const MerchItems = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [clothingSize, setClothingSize]=useState([])
  const [foothSize, setFoothSize]=useState([])
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (props.sellerID != undefined) {
      const params = {
        userID: getAuth().currentUser.uid,
        sellerID: props.sellerID,
      };
      axios
        .get(`${ApiURL}/api/admin/controlPanel/products`, {
          params: params,
        })
        .then((res) => {
          setMerchItemsData(res.data).then(() => {
            setIsLoading(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [props]);

  const setMerchItemsData = (merch) => {
    return new Promise((resolve, reject) => {
      setProducts(merch);
      resolve(true);
    });
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === products.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? products.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
    if (animating) return;
  };

  if (isLoading) {
    return <LoadingAsset loading={"MerchItems.js"} />;
  }

  return (
    <Fragment>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        {/* <CarouselIndicators items={perspectives} activeIndex={activeIndex} onClickHandler={goToIndex} /> */}
        {products.map((item, i) => {
          return (
            <CarouselItem
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
              key={`Merch ${i}`}
            >
              <div style={{ position: "relative" }}>
                <div
                  className={"cursor-default text-color"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "stretch",
                    width: "100%",
                    position: "relative",
                    marginRight: "50%",
                  }}
                >
                  <Media
                    className={"img-fluid p-15"}
                    src={item.thumbnail}
                    style={{ width: "200px" }}
                  />
                  <div className="m-t-15">
                    <h6>
                      <b>{item.name}</b>
                    </h6>
                    <Rating
                      initialRating={item.ratings.stars}
                      emptySymbol="fa fa-star-o"
                      fullSymbol="fa fa-star"
                      onChange={(rate) => console.log(rate)}
                    ></Rating>
                    <LabeledDropdown
                      array={item.characteristics.clothingSize}
                      placeHolder={ChooseSize}
                      onChange={(option) => console.log(option.value)}
                    />
                    <Button color="warning m-t-10" size="sm">
                      <b>{`$ ${item.price.amount} ${item.price.currency}`}</b>
                    </Button>
                  </div>
                </div>
              </div>
            </CarouselItem>
          );
        })}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </Fragment>
  );
};

export default MerchItems;
