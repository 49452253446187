import React,{useState,Fragment, useEffect} from 'react'
import {FormGroup,Label,Input} from 'reactstrap'

const LabeledInput = (props) => {
  const [LabeledInput, setLabeledInput] = useState(props)
  useEffect(()=>{
    setLabeledInput(props)
  },[props])
  return (
    <Fragment>
        <FormGroup className="mb-0">
          <Label><b>{LabeledInput.label}</b></Label>
          {LabeledInput.value == "" ?
          <Input className="form-control digits" type={LabeledInput.type} innerRef={props.innerRef} placeholder={LabeledInput.placeholder} defaultValue={LabeledInput.default} disabled={LabeledInput.disabled} rows={LabeledInput.rows} onChange={LabeledInput.onChange}/> :
          <Input className="form-control digits" type={LabeledInput.type} innerRef={props.innerRef} placeholder={LabeledInput.placeholder} value={LabeledInput.value} disabled={LabeledInput.disabled} rows={LabeledInput.rows} onChange={LabeledInput.onChange}/> }
        </FormGroup>
    </Fragment>
  );
};

export default LabeledInput;