import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import '../assets/css/evnt_admin.css'
import LabeledDropDown from '../components/general/LabeledDropDown'
import { useHistory, useParams } from "react-router"
import { SetPlatformType } from '../redux/evnt/EvntActions'
import { Link } from 'react-router-dom';

const PlatformSelector = (props) => {
  
  const dispatch = useDispatch()
  const platformType = useSelector(state => state.EvntState.type)
  const history = useHistory()
  const {platformID, eventID}=useParams()
  const setPlatformLink = (type) => {
    dispatch(SetPlatformType(type))
    switch(type){
      case "Admin":
        console.log("Admin/ControlPanel")
        history.push(`${process.env.PUBLIC_URL}/Admin/ControlPanel`)
        break
      case "VR360":
        history.push(`${process.env.PUBLIC_URL}/VR360/${platformID}/${eventID}`)
        break
    }
  }

  return(
    <LabeledDropDown default={"VR360"} options={["Admin","VR360"]}  type={"btn-primary"} setSelectedOption={(option) => setPlatformLink(option)} />
  )
}


export default PlatformSelector