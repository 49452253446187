import {
  Event_Settings,
  Event_Loader_Logo,
  Event_Type,
  Perspective_Zoom,
  Change_Perspective,
  Set_Perspective_Data,
  Set_EventCaster,
  Check_If_Is_Open,
  Event_Attendance,
  Set_Event_Comments,
  Set_Vr_Mode,
  Set_Platform_Settings,
  Set_Teams_Data,
} from "./EventActionTypes";

export const SetPlatformSettings = (val) => {
  return {
    type: Set_Platform_Settings,
    data: val,
  };
};

export const SetEventSettings = (val) => {
  return {
    type: Event_Settings,
    data: val,
  };
};

export const SetEventAttendance = (val) => {
  return {
    type: Event_Attendance,
    data: val,
  };
};

export const SetEventComments = (val) => {
  return {
    type: Set_Event_Comments,
    data: val,
  };
};

export const SetVrMode = (bool) => {
  return {
    type: Set_Vr_Mode,
    data: bool,
  };
};

/***  OJO */
export const SetEventSeType = (val) => {
  return {
    type: Event_Type,
    data: val,
  };
};
// OJO

export const SetEventCaster = (profile) => {
  return {
    type: Set_EventCaster,
    data: profile,
  };
};

export const SetLoaderLogo = (val) => {
  return {
    type: Event_Loader_Logo,
    data: val,
  };
};

export const ChangePerspective = (hotSpot) => {
  return {
    type: Change_Perspective,
    data: hotSpot,
  };
};

export const SetPerspectiveData = (perspectiveData) => {
  return {
    type: Set_Perspective_Data,
    data: perspectiveData,
  }
}

export const PerspectiveZoom = (val) => {
  return {
    type: Perspective_Zoom,
    data: val,
  };
};

export const SetTeamsData = (val) => {
  return {
    type: Set_Teams_Data,
    data: val,
  };
};
