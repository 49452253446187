import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import BreadCrumb from "./layout/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  SEARCH_BY,
  SORT_BY,
  ADD_TO_CART,
  ADD_TO_WISHLIST,
} from "../../redux/actionTypes";
import { Grid, List } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import ItemsNotFound from "./layout/ItemsNotFound";
import { watchfetchProducts } from "../../redux/ecommerce/Product/action";
import {
  Filters,
  ShowingEvents,
  Oldest,
  NotFoundData,
  ProductDetails,
  Quantity,
  AddToCart,
  ViewDetails,
  ProductSizeArray,
  Newest,
  PlatformsListt,
  Platforms,
  Items,
  Showing,
  ControlPanell,
} from "../../constant";
import { LoadingAsset } from "../../components/common/WindowLoaders";
import axios from "axios";
import FilterGenerator from "./layout/FilterGenerator";
import AdminHeaderBar from "./layout/AdminHeaderBar";

const ControlPanel = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [layoutColumns, setLayoutColumns] = useState(3);
  const symbol = useSelector((content) => content.data.symbol);
  const [open, setOpen] = useState(false);
  const [sidebaron, setSidebaron] = useState(true);
  const [singleProduct, setSingleProduct] = useState([]);
  // eslint-disable-next-line
  const [stock, setStock] = useState("");
  const [filterSidebar, setFilterSidebar] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const filters = useSelector((content) => content.filters);
  // const products = getVisibleproducts(data, filters)

  useEffect(() => {
    dispatch(watchfetchProducts());
  }, [dispatch]);

  const filterSortFunc = (event) => {
    dispatch({ type: SORT_BY, sort_by: event });
  };

  const gridLayout = () => {
    document
      .querySelector(".product-wrapper-grid")
      .classList.remove("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-xl-3");
      el.classList.add("col-sm-6");
      el.classList.add("xl-4");
    });
  };

  const listLayout = () => {
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-xl-12");
    });
  };

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false);
      document.querySelector(".product-wrapper").classList.add("sidebaron");
    } else {
      setSidebaron(true);
      document.querySelector(".product-wrapper").classList.remove("sidebaron");
    }
  };

  const onOpenModal = (productId) => {
    setOpen(true);
    listItems.forEach((product, i) => {
      if (product.id === productId) {
        setSingleProduct(product);
      }
    });
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const minusQty = () => {
    if (quantity > 1) {
      setStock("InStock");
      setQuantity(quantity - 1);
    }
  };

  const changeQty = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  const plusQty = () => {
    if (quantity >= 1) {
      setQuantity(quantity + 1);
    } else {
      setStock("Out of Stock !");
    }
  };

  const addcart = (product, qty) => {
    dispatch({ type: ADD_TO_CART, payload: { product, qty } });
    history.push(`${process.env.PUBLIC_URL}/ecommerce/cart`);
  };

  const addWishList = (product) => {
    dispatch({ type: ADD_TO_WISHLIST, payload: { product } });
    history.push(`${process.env.PUBLIC_URL}/ecommerce/wishlist`);
  };

  const onClickDetailPage = (product) => {
    const id = product.id;
    history.push(`${process.env.PUBLIC_URL}/ecommerce/product-detail/${id}`);
  };

  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  const [breadCrumbTitle, setBreadCrumbTitle]=useState(ControlPanell)
  const [createDocument, setCreateDocument]=useState({})
  const [itemsArray, setItemsArray] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  useEffect(() => {
    if (searchKeyword != "") {
      setListItems(
        itemsArray.filter((item) =>
          item.name.toLowerCase().includes(searchKeyword.toLowerCase())
        )
      );
    } else {
      setListItems(itemsArray);
    }
  }, [itemsArray, searchKeyword]);

  const { platformID, eventID, locationID, perspectiveID } = useParams();
  useEffect(() => {
    if (
      platformID != undefined ||
      eventID != undefined ||
      locationID != undefined ||
      perspectiveID != undefined
    ) {
      setSidebaron(true);
      document.querySelector(".product-wrapper").classList.remove("sidebaron");
      if (perspectiveID != undefined) {
        // getPerspectives()
      } else if (locationID != undefined) {
        getPerspectives();
      } else if (eventID != undefined) {
        getLocations();
      } else if (platformID != undefined) {
        getEvents();
      }
    } else {
      getPlatforms();
    }
  }, [platformID, eventID, locationID, perspectiveID]);

  const getPerspectives = () => {
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
      platformID: platformID,
      eventID: eventID,
      locationID: locationID,
    };
    axios
      .get(
        `${ApiURL}/api/admin/controlPanel/platform/event/locations/perspectives`,
        {
          params: params,
        }
      )
      .then((res) => {
        setPerspectivesList(res.data);
      })
      .catch((error) => console.error(error));

    const setPerspectivesList = (perspectives) => {
      return new Promise((resolve, reject) => {
        const reducePerspectives = perspectives.reduce(
          (perspectives, perspective) => {
            return perspectives.concat({
              ...perspective.perspectiveData,
              id: perspective.id,
              editPath: `Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Location/${locationID}/Perspective/${perspective.id}/Form`,
            });
          },
          []
        );
        setItemsArray(reducePerspectives);
        resolve(true);
      });
    };
  };

  const getLocations = () => {
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
      platformID: platformID,
      eventID: eventID,
    };
    axios
      .get(`${ApiURL}/api/admin/controlPanel/platform/event/locations`, {
        params: params,
      })
      .then((res) => {
        setLocationsList(res.data);
      })
      .catch((error) => console.error(error));

    const setLocationsList = (locations) => {
      return new Promise((resolve, reject) => {
        const reduceLocations = locations.reduce((locations, location) => {
          return locations.concat({
            ...location.locationData,
            id: location.id,
            editPath: `Admin/ControlPanel/Platform/${platformID}/Event/${eventID}/Location/${location.id}/Form`,
          });
        }, []);
        setItemsArray(reduceLocations);
        resolve(true);
      });
    };
  };

  const getEvents = () => {
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
      platformID: platformID,
    };
    axios
      .get(`${ApiURL}/api/admin/controlPanel/platform/events`, {
        params: params,
      })
      .then((res) => {
        SetEventsList(res.data);
      })
      .catch((error) => console.error(error));
    const SetEventsList = (events) => {
      return new Promise((resolve, reject) => {
        const reducedEvents = events.reduce((events, event) => {
          return events.concat({
            ...event.eventData,
            id: event.id,
            editPath: `Admin/ControlPanel/Platform/${platformID}/Event/${event.id}/Form`,
          });
        }, []);
        setItemsArray(reducedEvents);
        resolve(true);
      });
    };
  };

  const getPlatforms = () => {
    const params = {
      userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
    };
    axios
      .get(`${ApiURL}/api/admin/controlPanel/platforms`, {
        params: params,
      })
      .then((res) => {
        SetPlatformList(res.data);
      })
      .catch((error) => console.error(error));

    const SetPlatformList = (platforms) => {
      return new Promise((resolve, reject) => {
        const reducePlatforms = platforms.reduce((platforms, platform) => {
          return platforms.concat({
            ...platform.platformData,
            id: platform.id,
            editPath: `Admin/ControlPanel/Platform/${platform.id}/Form`,
          });
        }, []);
        setItemsArray(reducePlatforms);
        resolve(true);
      });
    };
  };

  if (listItems.lenth == 0) {
    return <LoadingAsset loading={ControlPanell} />;
  }

  return (
    <Fragment>
      <AdminHeaderBar />
      <BreadCrumb title={breadCrumbTitle} addBTN={createDocument} />
      <Container fluid={true} className="product-wrapper sidebaron">
        <div className="product-grid">
          <div className="feature-products">
            <Row>
              <Col md="4" className="products-total">
                <div className="square-product-setting d-inline-block">
                  <a
                    className="icon-grid grid-layout-view"
                    onClick={gridLayout}
                    href="#javascript"
                  >
                    <Grid />
                  </a>
                </div>
                <div className="square-product-setting d-inline-block">
                  <a
                    className="icon-grid m-0 list-layout-view"
                    onClick={listLayout}
                    href="#javascript"
                  >
                    <List />
                  </a>
                </div>
                <span
                  className="d-none-productlist filter-toggle"
                  onClick={() => setFilterSidebar(!filterSidebar)}
                >
                  <h6 className="mb-0">
                    {Filters}
                    <span className="ml-2">
                      <i className="toggle-data fa fa-chevron-down"></i>
                    </span>
                  </h6>
                </span>
              </Col>
              <Col md="8" className="text-right">
                <span className="f-w-600 m-r-5">{`${Showing} ${listItems.length} ${Items}`}</span>
                <div
                  className="select2-drpdwn-product select-options d-inline-block"
                  onChange={(e) => filterSortFunc(e.target.value)}
                >
                  <select className="form-control btn-square" name="select">
                    <option value="Newest">{Newest}</option>
                    <option value="Oldest">{Oldest}</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="3">
                <div
                  className={`product-sidebar ${filterSidebar ? "" : "open"}`}
                >
                  <div className="filter-section">
                    <Card>
                      <CardHeader>
                        <h6 className="mb-0 f-w-600">
                          {Filters}
                          <span className="pull-right">
                            <i
                              className={`fa fa-chevron-down  toggle-data`}
                              onClick={onClickFilter}
                            ></i>
                          </span>
                        </h6>
                      </CardHeader>
                      <div className="left-filter">
                        <CardBody className="filter-cards-view animate-chk">
                          <FilterGenerator
                            breadCrumbTitle={(title)=>{
                              setBreadCrumbTitle(title)
                            }}
                            filteredItems={(items) => {
                              setItemsArray(items);
                            }}
                            createDocument={(obj)=>{
                              setCreateDocument(obj)
                            }}
                          />
                        </CardBody>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
              <Col md="9" sm="12">
                <Form>
                  <FormGroup className="m-0">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="search"
                      defaultValue={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                    />
                    <i className="fa fa-search"></i>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>

          <div className="product-wrapper-grid">
            {listItems.length == 0 ? (
              <ItemsNotFound />
            ) : (
              <Row className="gridRow">
                {listItems.map((item, i) => (
                  <div
                    className={`${
                      layoutColumns === 3
                        ? "col-xl-3 col-sm-6 xl-4 col-grid-box"
                        : "col-xl-" + layoutColumns
                    }`}
                    key={i}
                  >
                    <Card>
                      <div className="product-box">
                        <div className="product-img">
                          {item.status === "sale" ? (
                            <span className="ribbon ribbon-danger">
                              {item.status}
                            </span>
                          ) : (
                            ""
                          )}
                          {item.status === "50%" ? (
                            <span className="ribbon ribbon-success ribbon-right">
                              {item.status}
                            </span>
                          ) : (
                            ""
                          )}
                          {item.status === "gift" ? (
                            <span className="ribbon ribbon-secondary ribbon-vertical-left">
                              <i className="icon-gift">{item.status}</i>
                            </span>
                          ) : (
                            ""
                          )}
                          {item.status === "love" ? (
                            <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
                              <i className="icon-heart">{item.status}</i>
                            </span>
                          ) : (
                            ""
                          )}
                          {item.status === "Hot" ? (
                            <span className="ribbon ribbon ribbon-clip ribbon-warning">
                              {item.status}
                            </span>
                          ) : (
                            ""
                          )}
                          <img
                            className="img-fluid"
                            src={item.preview.thumbnail}
                            alt=""
                          />
                          <div className="product-hover">
                            <ul>
                              <li>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/${item.editPath}`}
                                >
                                  <Button color="default">
                                    <i className="icon-pencil-alt"></i>
                                  </Button>
                                </Link>
                              </li>
                              <li>
                                <Button
                                  color="default"
                                  data-toggle="modal"
                                  onClick={() => onOpenModal(item.id)}
                                >
                                  <i className="icon-eye"></i>
                                </Button>
                              </li>
                              {/* <li>
                              <Link to={`${process.env.PUBLIC_URL}/ecommerce/wishlist`}>
                                <Button color="default"  onClick={() => addWishList(event)} >
                                  <i className="icon-heart"></i>
                                </Button>
                              </Link>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                        <div className="product-details">
                          <h5 onClick={() => onClickDetailPage(item)}>
                            <b>{item.name}</b>
                          </h5>
                          <p>{item.description}</p>
                          {/* <div className="product-price">
                            <del>
                              {symbol} {`${event.price.amount} ${event.price.currency}`} </del>
                            {symbol} {`${event.price.amount} ${event.price.currency}`}
                          </div> */}
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
                <Modal
                  className="modal-lg modal-dialog-centered product-modal"
                  isOpen={open}
                >
                  <ModalBody>
                    <ModalHeader toggle={onCloseModal}>
                      <div className="product-box row">
                        <Col lg="6" className="product-img">
                          {/* <Media
                            className="img-fluid"
                            src={
                              singleProduct.img
                                ? require("../../../assets/images/" +
                                    singleProduct.img)
                                : ""
                            }
                            alt=""
                          /> */}
                        </Col>
                        <Col lg="6" className="product-details  text-left">
                          <h4>{singleProduct.category}</h4>
                          <div className="product-price">
                            <del>
                              {symbol}
                              {singleProduct.discountPrice}
                            </del>
                            {symbol}
                            {singleProduct.price}
                          </div>
                          <div className="product-view">
                            <h6 className="f-w-600">{ProductDetails}</h6>
                            <p className="mb-0">{singleProduct.discription}</p>
                          </div>
                          <div className="product-size">
                            <ul>
                              {ProductSizeArray.map((items, i) => (
                                <li key={i}>
                                  <Button color="outline-light">{items}</Button>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="product-qnty">
                            <h6 className="f-w-600">{Quantity}</h6>
                            <fieldset>
                              <InputGroup className="bootstrap-touchspin">
                                <InputGroupAddon addonType="prepend">
                                  <Button
                                    color="primary btn-square"
                                    className="bootstrap-touchspin-down"
                                    onClick={minusQty}
                                  >
                                    <i className="fa fa-minus"></i>
                                  </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    className="bootstrap-touchspin-prefix"
                                    style={{ display: "none" }}
                                  ></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  className="touchspin text-center"
                                  type="text"
                                  name="quantity"
                                  value={quantity}
                                  onChange={(e) => changeQty(e)}
                                  style={{ display: "block" }}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText
                                    className="bootstrap-touchspin-postfix"
                                    style={{ display: "none" }}
                                  ></InputGroupText>
                                </InputGroupAddon>
                                <InputGroupAddon
                                  addonType="append"
                                  className="ml-0"
                                >
                                  <Button
                                    color="primary btn-square"
                                    className="bootstrap-touchspin-up"
                                    onClick={plusQty}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </fieldset>
                            <div className="addcart-btn">
                              <Link
                                to={`${process.env.PUBLIC_URL}/ecommerce/cart`}
                              >
                                <Button
                                  color="primary"
                                  className="mr-2 mt-2"
                                  onClick={() =>
                                    addcart(singleProduct, quantity)
                                  }
                                >
                                  {AddToCart}
                                </Button>
                              </Link>
                              <Button
                                onClick={() => onClickDetailPage(singleProduct)}
                                color="primary"
                                className="mr-2 mt-2"
                              >
                                {ViewDetails}
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </ModalHeader>
                  </ModalBody>
                </Modal>
              </Row>
            )}
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default ControlPanel;
