import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { GoogleAuthProvider, FacebookAuthProvider, 
    TwitterAuthProvider, GithubAuthProvider } from "firebase/auth"

const config = require("./app_config.json")

// Firebase 
export const firebase_app = initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain:config.firebase.authDomain,
    databaseURL: config.firebase.databaseURL,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
    appId:config.firebase.appId
});

export const googleProvider = new GoogleAuthProvider()
export const facebookProvider = new FacebookAuthProvider()
export const twitterProvider = new  TwitterAuthProvider()
export const githubProvider = new  GithubAuthProvider()

export const db =  getFirestore()

    
