import React, {useState,useEffect} from 'react'
import axios from 'axios';
import '../../../../assets/css/evnt_cards.css'
import {HeaderEventCaster,HeaderTalent} from './TalentHeaders'

const TalentHeader = (props) => {
    switch(props.type){
        default:
        case "music festival":
            return(
               <HeaderTalent checkIfIsOpen={props.checkIfIsOpen}/> 
            )

        case "soccer":
            return(
                <HeaderEventCaster checkIfIsOpen={props.checkIfIsOpen}/>
            )
    }
}
export default TalentHeader