import React,{Fragment, useEffect, useState} from 'react'
import '../../../../assets/css/evnt_cards.css'
import {Soccer,MusicFestival} from './EventHeaders'
const EventHeader = (props) => {
const [headerType, setHeaderType]=useState(props.type)
useEffect(()=>{
    setHeaderType(props.type)
},[props])

switch(headerType){
    default:
    case"music festival":
    return(
        <MusicFestival/>
    )
    case"soccer":
    return(
        <Soccer/>
    )
}
}

export default EventHeader

