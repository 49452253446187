import React, { Fragment, useEffect, useState } from "react";
import LogIn360 from "../pages/authentication/Login360";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../platforms/Loader";
import {
  ChangePerspective,
  SetEventSettings,
  SetPlatformSettings,
} from "../redux/common/EventActions";

export const LogInRouter = () => {
  const [loginData, setLogInData] = useState("");

  const dispatch = useDispatch();
  const { platformID, eventID } = useParams();
  const [logInType, setLogInType] = useState(undefined);
  const [isLoadingPlatformSettings, setIsLoadingPlatformSettings] =
    useState(true);
  const [isLoadingEventSettings, setIsLoadingEventSettings] = useState(true);

  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (platformID != undefined) {
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
      };
      axios
        .get(`${ApiURL}/api/user/platform/settings/`, {
          params: params,
        })
        .then((res) => {
          SetPlatformSettingsData(res.data).then(() => {
            setIsLoadingPlatformSettings(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [platformID]);

  const SetPlatformSettingsData = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(SetPlatformSettings(data.settings));
      resolve(true);
    });
  };

  useEffect(() => {
    if ((platformID != undefined) & (eventID != undefined)) {
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
      };
      axios
        .get(`${ApiURL}/api/user/platform/event/settings`, {
          params: params,
        })
        .then((res) => {
          setEventSettingsData(res.data).then(() => {
            setIsLoadingEventSettings(false);
          });
        })
        .catch((error) => console.error(error));
    }
  }, [platformID, eventID]);
  const setEventSettingsData = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(SetEventSettings(data));
      const perspectiveLogIn =
        data.settings.specialPerspectives.logInPerspective;
      dispatch(
        ChangePerspective({
          toLocationId: perspectiveLogIn.toLocationId,
          toPerspectiveId: perspectiveLogIn.toPerspectiveId,
          type: undefined,
        })
      );
      setLogInType(data.settings.eventLogIn.type);
      resolve(true);
    });
  };

  if (isLoadingPlatformSettings) {
    return <Loader loading={"LogInRouter.js ( isLoadingPlatformSettings ) "} />;
  }

  if (isLoadingEventSettings) {
    return <Loader loading={"LogInRouter.js ( isLoadingEventSettings ) "} />;
  }

  switch (logInType) {
    default:
    case "360":
      return <LogIn360 brandLogo={loginData.brandLogo} />;
  }
};

export default LogInRouter;
