import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import BreadCrumb from "../../Admin/layout/Breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  CreateNewLocation,
  Platform,
  LocationDetails,
  Events,
  BrandableAreas,
  Administrators,
  Location,
} from "../../../constant";
import { LoadingAsset } from "../../../components/common/WindowLoaders";
import { useParams } from "react-router";
import LocationDetailsTab from "./Tabs/LocationDetails";
import { useDispatch } from "react-redux";
import AdminHeaderBar from '../layout/AdminHeaderBar'

const LocationForm = () => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [location, setLocation] = useState(undefined);
  const [locationName, setLocationName] = useState(CreateNewLocation);
  const [statisticsBTN, setStatisitcsBTN] = useState(false);
  const { platformID, eventID, locationID } = useParams();
  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";
  useEffect(() => {
    if (
      platformID != undefined &&
      eventID != undefined &&
      locationID != undefined
    ) {
      const params = {
        userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
        platformID: platformID,
        eventID: eventID,
        locationID: locationID,
      };
      axios
        .get(`${ApiURL}/api/admin/controlPanel/platform/event/location`, {
          params: params,
        })
        .then((res) => {
          setLocationData(res.data).then(() => {
            setStatisitcsBTN(true);
            setIsLoading(false);
          });
        })
        .catch((error) => console.error(error));
    } else {
      setIsLoading(false);
    }
  }, []);

  const setLocationData = (data) => {
    return new Promise((resolve, reject) => {
      setLocation(data);
      setLocationName(data.locationData.name);
      resolve(true);
    });
  };

  if (isLoading) {
    return <LoadingAsset loading={LocationDetails} />;
  }

  return (
    <Fragment>
      <AdminHeaderBar/>
      <BreadCrumb
        title={locationName}
        statisticsBTN={statisticsBTN}
        createBTN={Location}
      />
      <Container fluid={true}>
        <Nav tabs className="pull-right  border-tab">
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => setActiveTab("1")}
            >
              {LocationDetails}
            </NavLink>
          </NavItem>
          {location != undefined ? (
            <Fragment>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={() => setActiveTab("2")}
                >
                  {BrandableAreas}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={() => setActiveTab("3")}
                >
                  {Administrators}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={() => setActiveTab("4")}
                >
                  {"UsersList"}
                </NavLink>
              </NavItem>
            </Fragment>
          ) : (
            ""
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <LocationDetailsTab location={location} />
          </TabPane>
          <TabPane tabId="2">
            {/* <BrandableAreasData eventData={event} /> */}
          </TabPane>
          <TabPane tabId="3">
            {/* <AdministratorsData administratorsList={event.administrators} /> */}
          </TabPane>
          <TabPane tabId="4">
            {/* <UsersListData guestsList={event.guestsList} bannedList={event.guestsList}/> */}
          </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  );
};

export default LocationForm;
