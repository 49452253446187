import React from "react";
import { Fragment } from "react";
import {
  Card
} from "reactstrap";
import CloseCard from "./CloseWindow";
import "../../../../assets/css/evnt_cards.css";
import { ProfileCardData } from "./ProfileCardData";

export const UserProfileCard = (props) => {

  return (
    <Fragment>
      <Card className="evnt-card evnt-card-md">
        <div onClick={() => props.checkIfIsOpen({ name: props.windowName })}>
          <CloseCard />
        </div>
        <ProfileCardData userID={props.windowData.userID} />
      </Card>
    </Fragment>
  );
};
