import React, { useState, Fragment, useEffect } from "react";
import { MoreHorizontal } from "react-feather";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  Homes,
  Sponsors,
  Shows,
  Experiences,
  Venues,
  Store,
  ControlPanel,
  Events,Perspectives,Locationss
} from "../../../../constant";
import { useSelector, useDispatch } from "react-redux";

const MenuOptions = () => {
  const [LeftBar, setLeftBar] = useState(false);
  function ToggleLeftBar() {
    setLeftBar(!LeftBar);
  }

  const dispatch = useDispatch();
  const et = useSelector((state) => state.Common.eventType);
  const { platformID, eventID } = useParams();

  return (
    <Fragment>
      <div className="vertical-mobile-sidebar">
        <i className="fa fa-bars sidebar-bar"></i>
      </div>
      <div className="nav-right col left-menu-header">
        <ul className={`nav-menus-left ${LeftBar ? "open" : ""}`}>
          <li>
            <Link to={`${process.env.PUBLIC_URL}/Admin/ControlPanel`}>
              <h6>
                <b>{ControlPanel}</b>
              </h6>
            </Link>
          </li>
          {/* <li>
            <Link
              to={`${process.env.PUBLIC_URL}/Admin/Platform/Venues`}
            >
              <h6>
                <b>{Venues}</b>
              </h6>
            </Link>
          </li> */}
          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/Admin/Platform/Events`}
            >
              <h6>
                <b>{Events}</b>
              </h6>
            </Link>
          </li>
          
          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/Admin/Platform/Event/Locations`}
            >
              <h6>
                <b>{Locationss}</b>
              </h6>
            </Link>
          </li>

          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/Admin/Platform/Event/Location/Perspectives`}
            >
              <h6>
                <b>{Perspectives}</b>
              </h6>
            </Link>
          </li>
          {/* <li>
            <Link
              to={`${process.env.PUBLIC_URL}/Pages/Shows/ShowsListViews/ShowsListView`}
            >
              <h6>
                <b>{Shows[et]}</b>
              </h6>
            </Link>
          </li> */}
          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}
            >
              <h6>
                <b>{Experiences}</b>
              </h6>
            </Link>
          </li>
          <li>
            <Link to={`${process.env.PUBLIC_URL}/Pages/Sponsors/SponsorsList`}>
              <h6>
                <b>{Sponsors}</b>
              </h6>
            </Link>
          </li>
          <li>
            <Link to={`${process.env.PUBLIC_URL}/ecommerce/product`}>
              <h6>
                <b>{Store}</b>
              </h6>
            </Link>
          </li>
        </ul>
        <div
          onClick={ToggleLeftBar}
          className="d-xl-none mobile-toggle-left pull-right"
        >
          <MoreHorizontal />
        </div>
      </div>
    </Fragment>
  );
};

export default MenuOptions;
