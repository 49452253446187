import { Event_Settings, Event_Loader_Logo,
    Perspective_Zoom, Change_Perspective, Set_Perspective_Data,
    Event_Type, Set_EventCaster, Event_Attendance, Set_Event_Comments,
    Set_Vr_Mode,Set_Platform_Settings,Set_Teams_Data
} from './EventActionTypes'
    
const Platform_state = {
    platformLogo:{}
}

export const PlatformCommon = (state=Platform_state, action) => {
    switch (action.type) {
        case Set_Platform_Settings:
            state.platformLogo = action.data.platformLogo
            return state
        default:
        return state
    }
}

const Event_state = {
    settings:undefined,
    loaderLogoURL:"",
    eventData:undefined,

    eventType:false,
    eventCasters:undefined,
    attendance:[],
    comments:[],
    
    vrMode:false
}

export const EventCommon = (state=Event_state, action) => {
    switch (action.type) {

        case Event_Settings:
            state.settings = action.data.settings
            state.loaderLogoURL = action.data.loaderLogoURL
            state.eventData = action.data.eventData

            state.attendance = action.data.attendance
            state.comments = action.data.comments
            state.eventCasters = action.data.eventCasters

            return state

        case Event_Type:
            state.eventType = action.data
            return state

        case Event_Loader_Logo:
            state.loaderLogo = action.data
            return state;

        case Event_Attendance:
            return state

        case Set_Vr_Mode:
            state.vrMode = action.data
            return state

        case Set_EventCaster:
            state.settings.eventCasters.default = action.data
            return state
            
        case Set_Event_Comments:
            return state

        case Set_Vr_Mode:
            state.vrMode = action.data
            return state

        default:
        return state
    }
}

   
const Perspective_state = {
    locationID:null,
    perspectiveID:null,
    
    perspectiveData:{
        name:"Perspective Name"
    },
    perspectiveZoom:1
}

export const PerspectiveCommon = (state=Perspective_state, action) => {
    switch (action.type) {

        case Change_Perspective:
            state.locationID = action.data.toLocationId
            state.perspectiveID = action.data.toPerspectiveId
            state.perspectiveData.type = action.data.type
            return state

        case Set_Perspective_Data:
            state.perspectiveData = action.data
            return state

        case Perspective_Zoom:
            state.perspectiveZoom = action.data
            return state

        default:
        return state
    }
}


const Teams_state = {
    teams:{}
}
export const TeamsCommon = (state=Perspective_state, action) => {
    switch (action.type) {

        case Set_Teams_Data:
            state.teams = action.data
            return state

        default:
        return state
    }
}
