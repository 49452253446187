import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Row, Col, Media, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import LabeledInput from "../../../../../components/general/LabeledInput";
import LabeledDropDown from "../../../../../components/general/LabeledDropDown";
import axios from "axios";
import {
  EventName,
  MediaLink,
  MediaInputOptions,
  Save,
  Cancel,
  EventDescription,
  FacebookLink,
  InstagramLink,
  TwitterLink,
  LinkedinLink,
  EventGraphic,
  GraphicSoruce,
  EventVenue,
  EventCountry,
  EventState,
  EventCity,
  FromDate,
  Today,
  ToDate,
  ShowEventCounters,
  MainEventType,
  PlatformTypes,
  EventPricePasswordTerms,
  EventPrice,
  Currency,
  CurrencyList,
  EventTermsConditions,
  CatalogAccess,
  Administrators,
  GuestsList,
  Sponsors,
  EventTypeOptions,
  EventType,
  GeneralInformation,
  EventCreatedSuccessfully,
} from "../../../../../constant";
import { CreateDocument } from "../../../../../redux/Administrator/Actions";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";

const EventDataForm = (props) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const createDocument = useSelector(
    (state) => state.AdminState.createDocument
  );
  const { platformID } = useParams();
  
  const [selectedOption, setDropdownOption] = useState("");

  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventVenue, setEventVenue] = useState("");
  const [eventFromDate, setEventFromDate] = useState();
  const [eventToDate, setEventToDate] = useState();
  const [eventDescription, setEventDescription] = useState("");
  const [eventFB, setEventFB] = useState("");
  const [eventIG, setEventIG] = useState("");
  const [eventTW, setEventTW] = useState("");
  const [eventLinKedin, setEventLinKedin] = useState("");
  const [eventPrice, setEventPrice] = useState(0);
  const [eventCurrency, setEventCurrency] = useState("");
  const [eventTermsConditions, setEventTermsConditions] = useState("");
  const [eventMediaIMG, setEventMediaImg] = useState("");

  const ApiURL = "https://us-central1-evnt-app-24ef3.cloudfunctions.net/userApp";

  useEffect(() => {
    if (createDocument) {
      dispatch(CreateDocument(false));
      axios
        .post(`${ApiURL}/api/admin/controlPanel/platform/event`, {
          userID: "XEq1JpKYvRa9iTFZ9Jva3nYTK1q2",
          platformID: platformID,
          event: {
            eventData:{
              description: eventDescription,
              fromDate: eventFromDate,
              location: {
                venue: eventVenue,
              },
              name: eventName,  
              preview: {
                thumbnail: eventMediaIMG,
              },
              price: {
                amount: eventPrice,
                currency: eventCurrency,
              },
              socialMedia: {
                facebook: eventFB,
                instagram: eventIG,
                twitter: eventTW,
                linkedin: eventLinKedin,
              },
              speacialTermsOrConditions: eventTermsConditions,
              toDate: eventToDate,
              type: eventType,
              venue: eventVenue
            },
          },
        })
        .then((res) => {
          toast.success(EventCreatedSuccessfully, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          });
          setTimeout(
            history.push(`${process.env.PUBLIC_URL}/Admin/ControlPanel/Platform/${platformID}/Event/${res.data}/Form`), 
            5000
          )
        })
        .catch((error) => {
          console.error(error);
          const errorDetails = error.response.data.error.details;
          errorDetails.map((error) => {
            toast.error(`${error.context.key} ${error.type}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        });
    }
  }
  , [createDocument]);

  useEffect(() => {
    if (props.event != undefined) {
      const eventData = props.event.eventData;
      setEventName(eventData.name);
      setEventType(eventData.type);
      setEventVenue(eventData.location.venue);
      setEventFromDate(eventData.fromDate);
      setEventToDate(eventData.toDate);
      setEventDescription(eventData.description);
      setEventFB(eventData.socialMedia.facebook);
      setEventIG(eventData.socialMedia.instagram);
      setEventTW(eventData.socialMedia.twitter);
      setEventLinKedin(eventData.socialMedia.linkedin);
      setEventPrice(eventData.price.amount);
      setEventCurrency(eventData.price.currency);
      setEventTermsConditions(eventData.speacialTermsOrConditions);
      setEventMediaImg(eventData.preview.thumbnail);
    }
  }, [props.event]);

  return (
    <Fragment>
      <h1>{GeneralInformation}</h1>
      <hr />
      <Row>
        <Col lg="6">
          <Row>
            <Col lg="8">
              <LabeledInput
                label={EventName}
                placeholder={EventName}
                type={"text"}
                onChange={(event)=>setEventName(event.target.value)}
                value={eventName}
              />
            </Col>
            <Col lg="4">
              <LabeledInput
                label={EventType}
                placeholder={EventType}
                type={"text"}
                onChange={(event)=>setEventType(event.target.value)}
                value={eventType}
              />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={FromDate}
                placeholder={FromDate}
                type={"datetime-local"}
                onChange={(event)=>setEventFromDate(event.target.value)}
                value={eventFromDate} />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={ToDate}
                placeholder={ToDate}
                type={"datetime-local"}
                onChange={(event)=>setEventToDate(event.target.value)}
                value={eventToDate} />
            </Col>
            <Col lg="12">
              <LabeledInput
                label={EventVenue}
                placeholder={EventVenue}
                type={"text"}
                value={eventVenue}
                onChange={(event)=>setEventVenue(event.target.value)}
              />
            </Col>
            <Col lg="12">
              <LabeledInput
                label={EventDescription}
                placeholder={EventDescription}
                type={"textarea"}
                rows={4}
                value={eventDescription}
                onChange={(event)=>setEventDescription(event.target.value)}
              />
              <br />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={FacebookLink}
                placeholder={FacebookLink}
                type={"text"}
                value={eventFB}
                onChange={(event)=>setEventFB(event.target.value)}
              />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={InstagramLink}
                placeholder={InstagramLink}
                type={"text"}
                value={eventIG}
                onChange={(event)=>setEventIG(event.target.value)}
              />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={TwitterLink}
                placeholder={TwitterLink}
                type={"text"}
                value={eventTW}
                onChange={(event)=>setEventTW(event.target.value)}
              />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={LinkedinLink}
                placeholder={LinkedinLink}
                type={"text"}
                value={eventLinKedin}
                onChange={(event)=>setEventLinKedin(event.target.value)}
              />
            </Col>
            <Col lg="6">
              <LabeledInput
                label={EventPrice}
                placeholder={EventPrice}
                type={"number"}
                value={eventPrice}
                onChange={(event)=>setEventPrice(event.target.value)}
              />
            </Col>
            <Col lg="6">
              <LabeledDropDown label={Currency} options={CurrencyList} 
                setSelectedOption={(option)=>setEventCurrency(option)}/>
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <Col lg="6">
              <Row>
                <Col lg="12">
                  <LabeledInput
                    label={EventTermsConditions}
                    placeholder={EventTermsConditions + " Link"}
                    type={"text"}
                    value={eventTermsConditions}
                    onChange={(event)=>setEventTermsConditions(event.target.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <LabeledDropDown
                label={GraphicSoruce}
                options={MediaInputOptions}
                setSelectedOption={(option)=>setEventTermsConditions(option)}
              />
            </Col>
            <Col lg="6">
              {selectedOption != "Image" ? (
                <LabeledInput
                  label={MediaLink}
                  placeholder={MediaLink}
                  type={"file"}
                  value={selectedOption}
                  onChange={(event)=>setEventMediaImg(event.target.value)}
                />
              ) : (
                <LabeledInput
                  label={MediaLink}
                  placeholder={MediaLink}
                  type={"text"}
                  value={selectedOption}
                  onChange={(event)=>setEventMediaImg(event.target.value)}
                />
              )}
            </Col>
            <Col lg="12">
              <Media
                style={{ width: "100%" }}
                className="img-fluid m-r-20 p-2"
                src={eventMediaIMG}
                alt="Event Main Grafic"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default EventDataForm;
