import {Create_Document} from './Types'
    
const Admin_state = {
    createDocument:false
}

export const AdminState = (state=Admin_state, action) => {
    switch (action.type) {
        case Create_Document:
            state.createDocument = action.data

            return state
        default:
        return state
    }
}